import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '~src/store/index'

import NotFound from '~src/views/NotFound'

import Login from '~src/views/Login'
import ForgotPassword from '~src/views/ForgotPassword'
import ResetPassword from '~src/views/ResetPassword'

import Home from '~src/views/Home'

import AdminGeneralHome from '~src/components/admin/GeneralHomePage'
import ModeratorGeneralHome from '~src/components/moderator/GeneralHomePage'
import SponsorGeneralHome from '~src/components/sponsor/GeneralHomePage'
import SpeakerGeneralHome from '~src/components/speaker/GeneralHomePage'

// admin
import AdminHome from '~src/components/admin/HomePage'
import AdminLocation from '~src/components/admin/LocationPage'
import AdminWorkshop from '~src/components/admin/WorkshopPage'
import AdminEvent from '~src/components/admin/EventPage'
import RealTimeEventPage from '~src/components/admin/RealTimeEventPage'
import UserProfile from '~src/components/admin/users/UserProfile'
import SponsorPage from '~src/components/admin/sponsors/SponsorPage'

// General
import Profile from '~src/components/GeneralPages/Profile'
import Blog from '~src/components/GeneralPages/Blog'
import BlogPosts from '~src/components/GeneralPages/blog/BlogList'
import SingleBlogPost from '~src/components/GeneralPages/blog/BlogPost'
import VideoArchive from '~src/components/GeneralPages/video/VideoArchive'
import Video from '~src/components/GeneralPages/video/Video'

// moderator
import ModeratorHome from '~src/components/moderator/HomePage'

// sponsor
import SponsorHome from '~src/components/sponsor/HomePage'
import SponsorEvent from '~src/components/sponsor/Event'
import SponsorProfile from '~src/components/sponsor/Profile'
import SponsorUpcomingEventsList from '~src/components/sponsor/ListUpcomingEvents'
import SponsorUpcomingEventsPage from '~src/components/sponsor/upcomingEventsList/UpcomingEventsPage'
import SponsorUpcomingEventShort from '~src/components/sponsor/upcomingEventsList/UpcomingEventShort'
import SponsorEventsArchive from '~src/components/sponsor/Archive'

// speaker
import SpeakerHome from '~src/components/speaker/HomePage'
import Bios from '~src/components/speaker/Bios'
import Talks from '~src/components/speaker/Talks'
import SpeakerEventsWrapper from '~src/components/speaker/SpeakerEventsWrapper'
import SpeakerEvents from '~src/components/speaker/Events'
import SpeakerEvent from '~src/components/speaker/event/EventPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/forgot',
    name: 'ForgotPassword',
    component: ForgotPassword
  },
  {
    path: '/reset',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: '/video',
        name: 'Video',
        component: Video
      },
      {
        path: '/videoarchive',
        name: 'VideoArchive',
        component: VideoArchive
      },
      {
        path: '/profile',
        name: 'UserProfile',
        component: Profile
      },
      {
        path: 'blog',
        component: Blog,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name: 'BlogPostsList',
            component: BlogPosts,
            meta: { requiresAuth: true }
          },
          {
            path: ':id',
            name: 'BlogPost',
            component: SingleBlogPost,
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'admin',
        component: AdminGeneralHome,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name: 'AdminGeneral',
            component: AdminHome,
            meta: { requiresAuth: true }
          },
          {
            path: 'events/:id',
            name: 'Event',
            component: AdminEvent,
            meta: { requiresAuth: true }
          },
          {
            path: 'events/:id/sponsors/:sponsorId',
            name: 'Sponsor',
            component: SponsorPage,
            meta: { requiresAuth: true }
          },
          {
            path: 'locations/:id',
            name: 'Location',
            component: AdminLocation,
            meta: { requiresAuth: true }
          },
          {
            path: 'workshops/:id',
            name: 'Workshop',
            component: AdminWorkshop,
            meta: { requiresAuth: true }
          },
          {
            path: 'realtime/:id',
            name: 'Realtime',
            component: RealTimeEventPage,
            meta: { requiresAuth: true }
          },
          // {
          //   path: 'users',
          //   name: 'users',
          //   component: UsersPage,
          //   meta: { requiresAuth: true }
          // },
          {
            path: 'users/:id',
            name: 'User',
            component: UserProfile,
            meta: { requiresAuth: true }
          }

          // {
          //   path: 'moderator',
          //   name: 'ModeratorHome',
          //   component: ModeratorHome,
          //   meta: { requiresAuth: true }
          // }
        ]
      },
      {
        path: 'moderator',
        component: ModeratorGeneralHome,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name: 'ModeratorGeneral',
            component: ModeratorHome,
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'sponsor',
        component: SponsorGeneralHome,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name: 'SponsorHomePage',
            component: SponsorHome,
            meta: { requiresAuth: true }
          },
          {
            path: 'profile',
            name: 'CompanyProfile',
            component: SponsorProfile,
            meta: { requiresAuth: true }
          },
          {
            path: 'events',
            component: SponsorUpcomingEventsList,
            meta: { requiresAuth: true },
            children: [
              {
                path: '',
                name: 'UpcomingEventsPage',
                component: SponsorUpcomingEventsPage,
                meta: { requiresAuth: true }
              },
              {
                path: ':id',
                name: 'UpcomingEventShort',
                component: SponsorUpcomingEventShort,
                meta: { requiresAuth: true }
              }
            ]
          },
          {
            path: 'archive',
            name: 'EventsArchive',
            component: SponsorEventsArchive,
            meta: { requiresAuth: true }
          },
          {
            path: 'upcoming/:id',
            name: 'UpcomingEvent',
            component: SponsorEvent,
            meta: { requiresAuth: true }
          },
          {
            path: 'past/:id',
            name: 'PastEvent',
            component: SponsorEvent,
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: 'speaker',
        component: SpeakerGeneralHome,
        meta: { requiresAuth: true },
        children: [
          {
            path: '',
            name: 'SpeakerHomePage',
            component: SpeakerHome,
            meta: { requiresAuth: true }
          },
          {
            path: 'talks',
            name: 'Talks',
            component: Talks,
            meta: { requiresAuth: true }
          },
          {
            path: 'bios',
            name: 'Bios',
            component: Bios,
            meta: { requiresAuth: true }
          },
          {
            path: 'events',
            component: SpeakerEventsWrapper,
            meta: { requiresAuth: true },
            children: [
              {
                path: '',
                name: 'SpeakerEvents',
                component: SpeakerEvents,
                meta: { requiresAuth: true }
              },
              {
                path: ':id',
                name: 'SpeakerEvent',
                component: SpeakerEvent,
                meta: { requiresAuth: true }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    if (!store.getters.getCognitoRoles.length) {
      await store.dispatch('getCurrentUserGroups')
        .then(result => store.commit('setCognitoGroups', result))
        .catch(() => { router.push('/login') })
    }

    store.dispatch('getCurrentToken')
      .then(() => {
        if (to.path === '/') {
          if (store.getters.getAdmin) router.replace('/admin')
          else if (store.getters.getModerator) router.replace('/moderator')
          else if (store.getters.getSponsor) router.replace('/sponsor')
          else if (store.getters.getSpeaker) router.replace('/speaker')
        } else if (
          (to.path === '/admin' && !store.getters.getAdmin) ||
          (to.path === '/moderator' && !store.getters.getModerator) ||
          (to.path === '/sponsor' && !store.getters.getSponsor) ||
          (to.path === '/speaker' && !store.getters.getSpeaker)
        ) {
          router.push('/')
        }

        next()
      })
      .catch(() => {
        router.push('/login')
      })
  } else {
    next()
  }
})

export default router

<script>
import { Bar } from 'vue-chartjs'

export default {
  extends: Bar,
  props: {
    chartdata: {
      type: Object,
      default: () => ({})
    }

    // chartdata example for bar chart
    // chartdata: {
    //   labels: ['Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum'],
    //   datasets: [
    //    {
    //      backgroundColor: ['#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB'],
    //      data: [100, 80, 60, 40, 20]
    //    }
    //  ]
    // },
  },
  data: () => ({
    options: {
      responsive: true,
      maintainAspectRatio: false,
      legend: {
        display: false
      },
      scales: {
        xAxes: [{
          ticks: {
            display: false
          }
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true
          }
        }]
      }
    }
  }),

  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

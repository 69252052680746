<template>
  <div>
    <v-img
      v-if="url"
      :src="url"
      :lazy-src="url"
      aspect-ratio="1"
      class="grey lighten-2"
      style="width: 100%; height: 100%"
      alt="Image"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-avatar
      v-else
      tile
      width="100%"
      height="100%"
      color="grey">
<!--      size="150"-->

      <v-icon dark x-large>
        mdi-image
      </v-icon>
    </v-avatar>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'ImageView',
  props: ['url'],
  components: {},
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      baseUrl: 'currentBaseUrl'
    })
  }
}
</script>

<style scoped>

</style>

<template>
  <div>
    <PageTitle title="Upcoming Events"/>
    <v-container>
      <UpcomingEventsTable/>
    </v-container>
  </div>
</template>

<script>
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import UpcomingEventsTable from '~src/components/common/upkomingEventsTable/UpcomingEventsTable'

export default {
  name: 'UpcomingEventsPage',
  props: {},
  components: { UpcomingEventsTable, PageTitle },
  data: () => ({})
}
</script>

<style lang="scss">
</style>

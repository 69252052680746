<template>
  <v-speed-dial
    v-model="fab"
    :direction="direction"
  >
    <template v-slot:activator>
      <v-btn
        v-model="fab"
        color="white"
        class="blue--text"
        fab
      >
        <v-icon v-if="fab">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-menu
        </v-icon>
      </v-btn>
    </template>
    <v-btn
      fab
      class="blue--text"
      small
      color="white"
    >
      <v-icon>mdi-format-text</v-icon>
    </v-btn>
    <v-btn
      fab
      class="blue--text"
      small
      color="white"
    >
      <v-icon>mdi-alert-outline</v-icon>
    </v-btn>
    <v-btn
      fab
      class="blue--text"
      small
      color="white"
    >
      <v-icon>mdi-stop</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>

export default {
  name: 'actionButtonsGroup',
  props: ['direction'],
  data () {
    return {
      fab: false
    }
  }

}
</script>

<style lang="scss">

</style>

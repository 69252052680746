<template>
  <Table
    :loading="loading"
    objectName="workshops"
    :data="workshops"
    :tableHeader="headers"
    :fields="workshopsFields"
    url='/workshop'
    :rowClick="rowClick"
    :refresh="getWorkshops"
    :allowCreation=true
    note="Before you go! Please add tag with event id for event related Workshops"
  />
</template>

<script>
import { mapActions } from 'vuex'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import Table from '~src/components/common/table/Table'

export default {
  name: 'WorkshopsTable',
  components: { Table },
  props: ['rowClick'],
  data () {
    return {
      loading: false,
      headers: jsonHeaders.Workshops,
      workshops: undefined,
      workshopsFields: null
    }
  },
  created () {
    this.getWorkshops()
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getWorkshops: function () {
      this.loading = true
      this.GET({ route: '/workshop' })
        .then(({ data }) => {
          this.workshops = data.data
          this.getWorkshopsFields()
        })
        .catch(err => {
          console.log(err)
        })
    },
    getWorkshopsFields: function () {
      this.GET({ route: '/fields/workshop' })
        .then(({ data }) => {
          this.workshopsFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<template>
  <router-view />
</template>

<script>
export default {
  name: '',
  props: {},
  components: {},
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
// @import 'path/_variables';
</style>

<template>
  <div v-if="metadata && metadata.dict" class="dictionary" :key="rerenderMarker">
    <v-btn
      class="dictionary__add-btn"
      @click="addTopic"
      text
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <dl
      v-for="([topic, list], index) in Object.entries(dictionary)"
      :key="index + Date.now() + ((Math.random() * 100000).toFixed())"
    >
      <v-card class="pa-2 mb-2">
        <dt class="d-flex">
          <v-text-field
            :value="Object.keys(dictionary)[index]"
            placeholder="Insert topic here"
            @change="(e)=>renameTopic(e, Object.keys(dictionary)[index])"
          />
          <v-btn text @click="deleteTopic(Object.keys(dictionary)[index])" class="mt-2 ml-2">
            <v-icon> mdi-delete-outline </v-icon>
          </v-btn>
        </dt>

        <div class="dictionary__topic">
          List that will be shown inside of topic:
          <v-btn
            class="dictionary__topic-btn"
            @click="addNested(Object.keys(dictionary)[index])"
            text
            color="grey"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <dd
            v-for="(item, counter) in list"
            :key="item.slice(5) + counter"
          >
            <v-text-field
              :value="dictionary[topic][counter]"
              placeholder="Text here"
              @change="(e)=>renameItem(e, topic, counter)"
            />
            <v-btn text @click="deleteNested( Object.keys(dictionary)[index],counter)" color="grey">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </dd>
        </div>

      </v-card>
    </dl>

  </div>
  <div v-else>
    {{field}}
  </div>
</template>

<script>
import { makeFormFields } from '~src/assets/js/utils'

export default {
  name: 'dictionary-field',
  props: ['field', 'metadata', 'schema'],
  components: {},
  data () {
    return {
      dictionary: {},
      rerenderMarker: 0
    }
  },
  created () {
    if (this.field) {
      this.dictionary = this.field
    } else {
      this.dictionary = { '': ['', ''] }
    }
  },
  watch: {
    dictionary: {
      handler (dictionary) {
        this.$emit('nestedDict', dictionary)
      },
      deep: true
    },
    field: {
      handler (field) {
        this.dictionary = field
      },
      deep: true
    }
  },
  methods: {
    makeFormFields,
    addTopic () {
      this.dictionary = { ...this.dictionary, '': ['', ''] }
      this.rerenderMarker = this.rerenderMarker++
    },
    deleteTopic (key) {
      delete this.dictionary[key]
      this.rerenderMarker = this.rerenderMarker--
      this.$emit('nestedDict', this.dictionary)
    },
    renameTopic (newKey, oldKey) {
      if (oldKey !== newKey) {
        Object.defineProperty(this.dictionary, newKey,
          Object.getOwnPropertyDescriptor(this.dictionary, oldKey))
        delete this.dictionary[oldKey]
        this.rerenderMarker = this.rerenderMarker++
        this.$emit('nestedDict', this.dictionary)
      }
    },
    renameItem (e, topic, counter) {
      this.dictionary[topic][counter] = e
    },
    addNested (key) {
      if (this.dictionary[key].length < 10) {
        this.dictionary[key].push('')
      }
    },
    deleteNested (key, counter) {
      if (this.dictionary[key].length > 1) this.dictionary[key].splice(counter, 1)
    }
  }
}
</script>

<style scoped lang="scss">
  .dictionary {
    position: relative;
    margin-top: 20px;

    &__add-btn {
      position: absolute;
      top: -51px;
      right: 0;
    }

    &__topic {
      position: relative;

      dd {
        width: 80%;
        margin-left: 20%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      &-btn {
        position: absolute;
        top: -7px;
        right: 0;
      }
    }
  }
</style>

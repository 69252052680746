<template>
  <v-card width="500px">
    <v-card-title>
      <span class="headline mb-6">The information that will be stored in spot</span>
    </v-card-title>
    <v-card-text>

<!--      v-model="form.image" @videoUrl="(data)=>{form.image = data}"-->
      <div class="my-3">Please select the type</div>
      <v-select
        style="width: 100%"
        outlined
        label="Select type"
        :items="types"
        v-model="form.resource_type"
        item-text="name"
        item-value="name"
        class="mb-3"
      />
      <VideoUpload v-show="form.resource_type === 'video' " :url="form.url" @videoUrl="(data)=>{form.url = data}"/>
      <v-text-field
        v-show="form.resource_type === 'link'"
        outlined
        label="Link"
        v-model="form.url"
      />
<!--      <v-select
        v-show="form.resource_type === 'drawing' || form.resource_type === 'resource' "
        style="width: 100%"
        outlined
        label="Select"
        :items="items"
        class="mb-3"
      ></v-select>-->
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>

import VideoUpload from '../files/videoUpload'
export default {
  name: 'add-edit-hotspot-modal',
  components: {
    VideoUpload
    // Video, Resources
  },
  props: ['spot', 'close'],
  data () {
    return {
      form: {
        url: '',
        resource_type: ''
      },
      types: ['video', 'link']
      // types: ['video', 'link', 'drawing', 'resource'],
    }
  },
  created () {
    if (this.spot.resource_type) {
      this.form.resource_type = this.spot.resource_type
      this.form.url = this.spot.url
    }
  },
  methods: {
    save: function () {
      this.$emit('resource', this.form)
      this.close()
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <v-container>
    <v-row>
      <v-col sm="12" md="6" lg="4">
        <Preloader
          v-if="areUpcomingEventsLoading"
          :size="30"
          :width="2"
        />
        <CardStats
          v-else
          title="total upcoming events"
          :quantity="upcomingEventsCount || '0'"
        />
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <Preloader
          v-if="arePastEventsLoading"
          :size="30"
          :width="2"
        />
        <CardStats
          v-else
          title="total past events"
          :quantity="pastEventsCount || '0'"
        />
      </v-col>
      <v-col sm="12" md="6" lg="4">
        <Preloader
          v-if="isNextEventLoading"
          :size="30"
          :width="2"
        />
        <CardText
          v-else
          linkTitle="Next event"
          :link="`/sponsor/upcoming/${nextEventInfo.id}`"
          :title="nextEventInfo.name"
          :text="nextEventInfo.description"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="isLastEventLoading" sm="12" md="6">
        <Preloader
          :size="30"
          :width="2"
          class="mb-2"
        />
      </v-col>
      <v-col
        v-else-if="!isLastEventLoading && !isEmpty(lastEventInfo)"
        sm="12"
        md="6"
      >
        <CardList
          :title="lastEventInfo.name"
          :image="lastEventInfo.background"
          :list="lastEventBreef"
          :is-upcoming="false"
        >
          <v-btn
            text
            color="primary"
            :loading="isListLoading"
            @click="downloadAttendeeList(lastEventInfo.id)"
          >
            download attendee list
          </v-btn>
        </CardList>
      </v-col>
      <v-col v-if="isNextEventLoading" sm="12" md="6">
        <Preloader
          :size="30"
          :width="2"
          class="mb-2"
        />
      </v-col>
      <v-col
        v-else-if="!isNextEventLoading && !isEmpty(nextNotAppliedEventInfo)"
        sm="12"
        md="6"
      >
        <CardList
          :title="nextNotAppliedEventInfo.name"
          :image="nextNotAppliedEventInfo.background"
          :list="nextNotAppliedEventBreef"
          :is-upcoming="true"
          :label="`${attendeesTodayCount ? `+${attendeesTodayCount}` : ''}`"
        >
          <ApplyForm
            :is-apply-popup-shown="isApplyPopupShown"
            :event="{
              id: nextNotAppliedEventInfo.id,
              name: nextNotAppliedEventInfo.name
            }"
          />
        </CardList>
      </v-col>
    </v-row>
    <UpcomingEventsTable/>
    <BlogPostsList/>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapActions } from 'vuex'
import { isEmpty } from '~src/assets/js/utils'
import CardStats from '../../common/Cards/CardStats'
import CardText from '../../common/Cards/CardText'
import CardList from './CardList'
import Preloader from '~src/components/common/preloader/Preloader'
import ApplyForm from '../common/applyForm'
import BlogPostsList from '../../common/dashboardBlogPostsList/BlogPostsList'
import UpcomingEventsTable from '../../common/upkomingEventsTable/UpcomingEventsTable'

export default {
  name: 'Details',
  props: {},
  components: {
    UpcomingEventsTable,
    CardStats,
    CardText,
    CardList,
    BlogPostsList,
    Preloader,
    ApplyForm
  },
  created () {
    this.isNextEventLoading = true
    this.areUpcomingEventsLoading = true
    this.arePostsLoading = true
    this.arePastEventsLoading = true
    this.isLastEventLoading = true

    this.getNextEventInfo().then(() => {
      this.getUpcomingEvents()
    })

    this.getPastEvents()
    this.getLastEventInfo()
  },
  data: () => ({
    upcomingEventsCount: null,
    areUpcomingEventsLoading: false,
    pastEventsCount: null,
    arePastEventsLoading: false,
    nextEventInfo: {},
    isNextEventLoading: false,
    attendeesTodayCount: null,
    isLastEventLoading: false,
    lastEventInfo: {},
    lastEventBreef: {},
    nextNotAppliedEventInfo: {},
    nextNotAppliedEventBreef: {},
    isApplyPopupShown: false,
    isListLoading: false
  }),
  computed: {},
  methods: {
    ...mapActions([
      'GET'
    ]),
    isEmpty,
    getUpcomingEvents () {
      return this.GET({ route: 'admin/event/past_and_upcoming' })
        .then(({ data }) => {
          this.upcomingEventsCount = data.data.upcoming_events.length
          console.log('1', data.data.upcoming_events)
          this.nextNotAppliedEventInfo = data.data.upcoming_events
            .filter(({ id }) => id !== this.nextEventInfo.id)[0] || {}
          this.nextNotAppliedEventBreef = {
            // pricing: this.nextNotAppliedEventInfo.pricing || '',
            type: this.nextNotAppliedEventInfo.is_virtual ? 'Virtual' : 'Hybrid',
            attendee: this.nextNotAppliedEventInfo.expected_atendees || '',
            location: this.nextNotAppliedEventInfo.location || ''
          }
        })
        .finally(() => {
          this.areUpcomingEventsLoading = false
        })
    },
    getPastEvents () {
      return this.GET({ route: '/public/event/past_events' })
        .then(({ data }) => {
          this.pastEventsCount = data.data.past_events && data.data.past_events.length
        })
        .finally(() => {
          this.arePastEventsLoading = false
        })
    },
    getNextEventInfo () {
      return this.GET({ route: '/admin/sponsor/upcoming_event' })
        .then(({ data }) => {
          this.getAttendeesToday(data.data.id)
          this.nextEventInfo = data.data
        })
        .finally(() => {
          this.isNextEventLoading = false
        })
    },
    getLastEventInfo () {
      return this.GET({ route: '/admin/sponsor/past_event' })
        .then(({ data }) => {
          this.lastEventInfo = data.data
          this.lastEventBreef = {
            // pricing: data.data.pricing || '',
            type: data.data.is_virtual ? 'Virtual' : 'Hybrid',
            attendee: data.data.expected_atendees || '',
            location: data.data.location || ''
          }
        })
        .finally(() => {
          this.isLastEventLoading = false
        })
    },
    getAttendeesToday (id) {
      return this.GET({ route: `admin/event/${id}/attendees_today` })
        .then(({ data }) => {
          this.attendeesTodayCount = data.data.count
        })
    },
    downloadAttendeeList (id) {
      this.isListLoading = true

      let listDownloadingLink = ''
      this.GET({ route: `admin/event/${id}/attendee_list` })
        .then(({ data }) => {
          listDownloadingLink = data.download_url
          axios.get(listDownloadingLink, { responseType: 'blob' }).then(({ data }) => {
            this.downloadExcel(data, id)
            this.isListLoading = false
          })
        })
    },
    downloadExcel (data, eventId) {
      const blob = new Blob([data], { type: 'application/octet-stream' })
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, `attendee-list-${eventId}.xlsx`)
        return
      }
      const blobURL = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      tempLink.setAttribute('download', `attendee-list-${eventId}.xlsx`)
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      setTimeout(() => {
        window.URL.revokeObjectURL(blobURL)
      }, 100)
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
</style>

<template>
  <div>
    <div v-if="videoUrl" class="mt-2">
      File name: {{videoUrl}}
      <v-btn v-show="videoUrl && !isUploading" icon @click="removeVideoUrl" class="close">
        <v-icon>
          mdi-close
        </v-icon>
      </v-btn>
    </div>
    <v-file-input
      accept="video/mp4,video/x-m4v,video/*"
      placeholder="Choose video file"
      :loading="isUploading"
      :disabled="isUploading"
      @change="uploadVideo($event)"
      @click:clear="removeVideoUrl"
    />
    <!--    <Video v-show="videoUrl" :video="videoUrl"/>-->
  </div>
</template>

<script>

import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
// import Video from '../Environment/video'

export default {
  name: 'videoUpload',
  props: ['url', 'width'],
  // components: { Video },
  data () {
    return {
      isUploading: false,
      uploadingData: null,
      videoUrl: null,
      result: null,
      autogeneratedFileName: null,
      error: null
    }
  },
  created () {
    // if (this.url) this.videoUrl = this.url.slice(this.url.lastIndexOf('/') + 1)
    if (this.url) this.videoUrl = this.url
  },
  watch: {
    result: {
      handler (result) {
        this.$emit('videoUrl', result)
      },
      deep: true
    },
    url: {
      handler (result) {
        if (result) this.videoUrl = result
      }
    }
  },
  computed: {
    ...mapGetters({
      baseUrl: 'currentBaseUrl'
    })
  },
  methods: {
    ...mapActions([
      'POST'
    ]),
    uploadVideo: function (event) {
      if (event) {
        this.removeVideoUrl()
        this.isUploading = true
        this.upload(event)
      }
    },
    removeVideoUrl: function () {
      this.error = null
      this.result = null
      this.videoUrl = null
    },
    upload (file) {
      this.uploadingData = new FormData()
      this.POST({
        route: '/file/',
        data: {
          file_name: file.name,
          content_type: 'application/json'
        }
      }).then(({ data }) => {
        this.result = process.env.VUE_APP_FILE_UPLOAD_BASE_URL + '/' + data.data.fields.key
        Object.keys(data.data.fields).forEach(key => {
          this.uploadingData.append(key, data.data.fields[key])
        })
        this.uploadingData.append('file', file)
        axios({
          method: 'post',
          url: data.data.url,
          data: this.uploadingData
        }).then((data) => {
          if (data.status !== 204) this.result = null
          this.isUploading = false
        })
      })
    }
  }
}
</script>

<style scoped>
</style>

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

import axiosInst from './plugins/axios.js'

import VueSocialSharing from 'vue-social-sharing'
// import { EmojiPickerPlugin } from 'vue-emoji-picker'
import { Datetime } from 'vue-datetime'
import 'vue-datetime/dist/vue-datetime.css'
import VuePlyr from 'vue-plyr'
import 'vue-plyr/dist/vue-plyr.css'
import VueExcelXlsx from 'vue-excel-xlsx'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.use(axiosInst)
Vue.use(VueSocialSharing)
Vue.use(VueExcelXlsx)
// Vue.use(EmojiPickerPlugin)
Vue.component('datetime', Datetime)
Vue.use(VuePlyr, { plyr: {} })

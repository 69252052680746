<template>
  <div>
  <v-container class="pa-8 pr-10 blog-post">
    <v-row>
      <v-row
        v-if="!post.id"
        justify="center"
        class="pt-3"
      >
        <v-progress-circular indeterminate color="primary"/>
      </v-row>
      <template v-else>
        <v-col sm="12" lg="8">
          <ImageView :url="post.head_picture" class="blog-post__image"/>
          <h3 class="my-6 blog-post__title">
            {{ post.title }}
          </h3>
          <div v-html="post.content"/>
          <CommentBlock :entityId="post.id"/>
        </v-col>
      </template>
      <v-col sm="12" lg="4">
        <v-row
          v-if="!randomPosts.length"
          justify="center"
        >
          <v-progress-circular indeterminate color="primary"/>
        </v-row>
        <template v-else>
          <CardBlogPost
            v-for="post in randomPosts"
            :key="post.id"
            :data="post"
            class="mb-6"
            @read-more="goToPost"
          />
        </template>
      </v-col>
    </v-row>
  </v-container>
<!--  <v-container class="pa-8 pr-10">-->
<!--    <CommentBlock :entityId="post.id"/>-->
<!--  </v-container>-->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CardBlogPost from './CardBlogPost'
import ImageView from '~src/components/common/files/Image'
import CommentBlock from '../comments/CommentBlock'

export default {
  name: 'BlogPost',
  props: {
    blogPosts: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CommentBlock,
    ImageView,
    CardBlogPost
  },
  async created () {
    await this.getPost(this.postId)
    if (this.blogPosts.length) this.getRandomPosts()
  },
  data: () => ({
    post: {},
    randomPosts: []
  }),
  computed: {
    postId () {
      return this.$route.params.id
    }
  },
  watch: {
    blogPosts: {
      handler (value) {
        if (value.length && !this.getRandomPosts.length) {
          this.getRandomPosts()
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getPost (id) {
      return this.GET({ route: `/blogpost/${id}` })
        .then(({ data }) => {
          this.post = data.data
        })
    },
    getRandomPosts () {
      const max = this.blogPosts.length - 1
      const currentIndex = this.blogPosts.findIndex(post => post.id === this.post.id)
      const randomIndexes = [this.getRandomNum(max), this.getRandomNum(max)]
      const indexesChecked = this.checkRandomNums(randomIndexes, currentIndex, max)

      this.randomPosts = [...this.blogPosts]
        .filter((cur, index) => indexesChecked
          .some(item => item === index))
    },
    getRandomNum (max) {
      return Math.round(Math.random() * max)
    },
    checkRandomNums (numsArr, curIndex, max) {
      let newNumsArr = []

      if ((numsArr[0] === numsArr[1]) ||
          numsArr.some(num => num === curIndex)) {
        newNumsArr = this.alterRandomNums(numsArr, max, curIndex)

        this.checkRandomNums(newNumsArr, curIndex, max)
      } else {
        newNumsArr = numsArr
      }

      return newNumsArr
    },
    alterRandomNums (numsArr, max, curIndex) {
      return numsArr.reduce((acc, cur, index) => {
        if (acc.some(item => item === cur) || cur === curIndex) {
          return [...acc, this.getRandomNum(max)]
        }

        return [...acc, cur]
      }, [])
    },
    async goToPost (id) {
      this.post = {}
      this.randomPosts = []
      this.$router.replace(`/blog/${id}`)

      await this.getPost(id)
      this.getRandomPosts()
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .blog-post {

    &__image {
      width: 100%;
      height: 500px;
      /*TODO check images*/
      /*height: auto;*/
      /*max-height: 500px;*/
      object-fit: fill;
    }

    &__title {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.02em;
    }
  }
</style>

<template>
  <v-container class="event-info">
    <v-alert
      :value="isSuccessful || hasError"
      text
      :type="isSuccessful ? 'success' : 'error'"
    >
      {{ isSuccessful ? successMessage : errorMessage }}
    </v-alert>
    <v-row>
      <v-col sm="12" lg="7">
        <CardTextarea
          v-model="summary"
          title="Summary"
          description="In a couple of sentences, describe your company.
            This is quick summary that attendees will see see accessing your page"
          class="mb-6"
          textarea-label="Summary"
          :is-readonly="isPastEvent"
          :has-button="!isPastEvent"
          :is-btn-loading="summarySaving"
          :is-textarea-loading="isCompanyInfoLoading"
          @save="saveTextarea('summary')"
        />
        <CardTextarea
          v-model="description"
          title="Description"
          description="In a couple of sentences, describe your company.
            This is quick summary that attendees will see see accessing your page"
          textarea-label="Description"
          :is-readonly="isPastEvent"
          :has-button="!isPastEvent"
          :is-btn-loading="descriptionSaving"
          :is-textarea-loading="isCompanyInfoLoading"
          @save="saveTextarea('description')"
        />
      </v-col>
      <v-col sm="12" lg="5">
        <CardTemplate title="Drawings">
          <Preloader
            v-if="areDrawingsLoading"
            :size="30"
            :width="2"
            class="mb-4"
          />
          <template v-else-if="drawings.length">
            <Drawing
              v-for="drawing in drawings"
              :key="drawing.id"
              :data="drawing"
              class="mb-6"
              :editable=true
              @edit="editDarwing(drawing)"
              @delete="deleteDrawing(drawing)"
            />
            </template>
            <v-row v-else justify="center">
              <v-col>
                No drawings yet
              </v-col>
            </v-row>
            <v-btn
              v-if="!isPastEvent"
              outlined
              block
              color="primary"
              @click="addDrawing"
            >
              add new drawing
            </v-btn>
        </CardTemplate>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <CardTemplate title="environment">
          <v-tabs>
            <v-tab>
              3D
            </v-tab>
            <v-tab>
              2D
            </v-tab>
            <v-tab>
              livestream
            </v-tab>

            <v-tab-item>
              <v-row>
                <v-col sm="12" md="8">
                  <v-img :src="links3d[links3d.length - 1]" class="event-info__img" />
                </v-col>
                <v-col sm="12" md="4">
                  <v-text-field
                    v-for="(link, index) in links3dReady"
                    :key="link"
                    v-model="links3d[index]"
                    label="Link"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col sm="12" md="8">
                  <v-img :src="links3d[links2d.length - 1]" class="event-info__img" />
                </v-col>
                <v-col sm="12" md="4">
                  <v-text-field
                    v-for="(link, index) in links2dReady"
                    :key="link"
                    v-model="links2d[index]"
                    label="Link"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col sm="12">
                  <v-text-field
                    v-model="livestreamLink"
                    label="Link"
                    outlined
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </CardTemplate>
      </v-col>
    </v-row>
    <v-dialog
      v-model="showCreateEditModal"
      persistent
      max-width="700px"
    >
        <CreateEditModal
          v-if="drawingFields"
          :fields="drawingFields"
          :formTitle="`${drawingCrudAction} drawing`"
          :url="`admin/p/event/pid/${currentEvent}/e/drawing`"
          :editedItem="editedDrawing"
          :close="closeModal"
          @tableUpdated="closeModal"
          @modalError="createEditError"
        />
    </v-dialog>
    <v-dialog
      v-model="showDeleteModal"
      persistent
      max-width="500px"
    >
      <DeleteModal
        :close="closeModal"
        :url="`admin/p/event/pid/${currentEvent}/e/drawing`"
        :item="editedDrawing"
        @tableUpdated="closeModal"
        @modalError="createEditError"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import config from '~public/config/config.json'
import CardTextarea from '~src/components/common/Cards/CardTextarea'
import CardTemplate from '~src/components/common/Cards/CardTemplate'
import Preloader from '~src/components/common/preloader/Preloader'
import CreateEditModal from '~src/components/common/createEditModal/create-edit-modal'
import DeleteModal from '~src/components/common/deleteModal/delete-modal'
import Drawing from '../../common/drawing/Drawing'

export default {
  name: 'InfoForEvent',
  props: {
    eventType: {
      type: String,
      default: ''
    }
  },
  components: {
    Drawing,
    CardTextarea,
    CardTemplate,
    Preloader,
    CreateEditModal,
    DeleteModal
  },
  data: () => ({
    config,
    companyInfo: {},
    summary: '',
    description: '',
    summarySaving: false,
    descriptionSaving: false,
    links3d: ['https://picsum.photos/700/900'],
    links2d: ['https://picsum.photos/400/500'],
    drawings: [],
    areDrawingsLoading: false,
    isSuccessful: false,
    successMessage: '',
    hasError: false,
    errorMessage: '',
    clearTimeout: null,
    showCreateEditModal: false,
    drawingFields: [],
    drawingCrudAction: '',
    editedDrawing: null,
    isCompanyInfoLoading: false,
    showDeleteModal: false,
    livestreamLink: ''
  }),
  computed: {
    ...mapGetters({
      sponsorInfo: 'getSponsorInfo'
    }),
    links3dReady () {
      return this.isPastEvent
        ? [...this.links3d]
        : this.links3d.filter(cur => cur).push('')
    },
    links2dReady () {
      return this.isPastEvent
        ? [...this.links2d]
        : this.links2d.filter(cur => cur).push('')
    },
    isPastEvent () {
      return this.eventType === 'past'
    },
    currentEvent () {
      // return this.$route.params.id

      // faking, because test event dosn't gave my sponsor as sponsor
      return 'spo2020'
    },
    sponsorId () {
      return this.sponsorInfo.id
    }
  },
  created () {
    this.getCompanyInfo()
    this.getDrawings()
    this.getDarwingFields()
  },
  beforeDestroy () {
    clearTimeout(this.clearTimeout)
  },
  watch: {
    isSuccessful (value) {
      if (value) {
        clearTimeout(this.clearTimeout)
        this.clearMessages()
      }
    },
    hasError (value) {
      if (value) {
        clearTimeout(this.clearTimeout)
        this.clearMessages()
      }
    }
  },
  methods: {
    ...mapActions([
      'GET',
      'PUT'
    ]),
    getCompanyInfo () {
      this.isCompanyInfoLoading = true

      this.GET({
        route: `event/${this.currentEvent}/sponsorship/${this.sponsorId}`
        // route: `admin/p/event/pid/${this.currentEvent}/e/sponsor/eid/${this.sponsorId}`
      }).then((data) => {
        console.log('sponsorship', data)
        this.companyInfo = data.data.data
        this.summary = data.data.data.summary
        this.description = data.data.data.description
      }).finally(() => { this.isCompanyInfoLoading = false })
    },
    getDrawings () {
      this.areDrawingsLoading = true
      this.GET({
        route: `/admin/p/event/pid/${this.currentEvent}/e/drawing`
      })
        .then(({ data }) => {
          console.log(data.data)
          this.drawings = data.data || []
        })
        .finally(() => { this.areDrawingsLoading = false })
    },
    getDarwingFields () {
      this.GET({ route: 'admin/fields/drawing' })
        .then(({ data }) => { this.drawingFields = data.data })
    },
    addDrawing () {
      this.showCreateEditModal = true
      this.drawingCrudAction = 'Create'
    },
    editDarwing (drawing) {
      this.showCreateEditModal = true
      this.drawingCrudAction = 'Edit'
      this.editedDrawing = drawing
    },
    deleteDrawing (drawing) {
      this.showDeleteModal = true
      this.editedDrawing = drawing
    },
    closeModal (action) {
      this.showCreateEditModal = false
      this.showDeleteModal = false
      this.drawingCrudAction = ''
      this.editedDrawing = null

      if (action) {
        this.isSuccessful = true
        this.successMessage = `Drawing was ${action}${action === 'edit' ? 'ed' : 'd'} successfully`
      }
    },
    createEditError (msg) {
      this.hasError = true
      this.errorMessage = msg ||
        'An error occurred. Please try again later or contact our support at ' + this.config.helpEmail
    },
    saveTextarea (type) {
      this[`${type}Saving`] = true

      const sponsorInfoEdited = {
        ...this.companyInfo,
        [type]: this[type]
      }

      this.PUT({
        route: `admin/p/event/pid/${this.currentEvent}/e/sponsor/eid/${this.sponsorId}`,
        data: sponsorInfoEdited
      })
        .then((resp) => {
          this.companyInfo = sponsorInfoEdited
          this.isSuccessful = true
          this.successMessage = `Your company's ${type} edited successfully`
        })
        .catch(err => {
          const beErr = Object.entries(err.response.data.errors).map(([key, value]) => `${value.join(' ').split('.')[0]} - ${key}`)
          this.hasError = true
          this.errorMessage = beErr.join(' ') ||
            `Error occurred while editing ${type}. Try again later or connect support at ` + this.config.helpEmail
          this[type] = this.companyInfo[type]
        })
        .finally(() => { this[`${type}Saving`] = false })
    },
    clearMessages () {
      this.clearTimeout = setTimeout(() => {
        this.isSuccessful = false
        this.hasError = false
        this.errorMessage = ''
        this.successMessage = ''
      }, 5000)
    }
  }
}
</script>

<style lang="scss">
// @import 'path/_variables';
.event-info {
  &__img {
    height: 460px;
    width: 100%;
    object-fit: fill;
  }
}
</style>

<template>
  <ShareNetwork
    tag="button"
    :network="network"
    :url="baseUrl"
    :title="title"
    :description="description"
    quote="Join us!"
    hashtags="event"
  >
    <v-btn target="_blank" rel="noopener noreferrer" class="px-0 py-4 mr-5" dark x-small color="blue">
      <v-icon>
        {{socialMediaBtn}}
      </v-icon>
    </v-btn>
  </ShareNetwork>
</template>

<script>
import socialIcon from '~src/assets/js/getSocialIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'SocialSharing',
  props: ['network', 'title', 'description'],
  components: {},
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      baseUrl: 'currentBaseUrl'
    }),
    socialMediaBtn () {
      return socialIcon(this.network)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-card>
    <v-form
      ref="cfpForm"
      @submit.prevent="submit"
    >
      <v-card-title>
        <span class="headline mb-5">Call for papers</span>
      </v-card-title>

      <v-card-text>
        <v-select
          v-if="talksList"
          outlined
          label="Select Talk"
          class="mb-4"
          v-model="chosenTalk"
          :items="talksList"
          item-text="title"
          item-value="id"
          :rules="rules.required"
          :loading="areTalksLoading"
          @change="selectTalk"
        ></v-select>

        <div class="mb-5">{{newTalksMsg}} you can create it now. Click to
          <span class="blue--text expand-control" @click="openTalkForm">create a talk</span>
        </div>

        <!--      block of talk expand-->
        <v-expand-transition>
          <div v-show="expandTalk">
            <div class="my-3">Talk Info</div>
            <v-text-field
              v-model="createTalkForm.title"
              label="Talk Title"
              outlined
              :rules="rules.required"
            />
            <v-select
              v-if="biosList"
              outlined
              label="Please select a bio"
              class="mb-4"
              v-model="createTalkForm.bio_id"
              :items="biosList"
              item-text="bio"
              item-value="id"
              :rules="rules.required"
              :loading="areBiosLoading"
              :disabled="expandBio"
              @change="errorNoBio=false"
            ></v-select>
            <v-textarea
              v-model="createTalkForm.description"
              outlined
              label="Talk Description"
              :rules="rules.required"
            ></v-textarea>
            <div class="mb-5">{{newBiosMsg}} you can create it now. Click to
              <span class="blue--text expand-control" @click="expandBio = !expandBio"> create a bio</span>
            </div>
            <p v-show="errorNoBio" class="red--text">The bio must be chosen.</p>

            <!--      block of bio creation-->
            <v-expand-transition>
              <div v-show="expandBio">
                <v-form
                  ref="bioForm"
                  @submit.prevent="createBio">
                  <div class="my-3">Speaker Info</div>
                  <v-text-field
                    v-model="createBioForm.first_name"
                    label="First name"
                    outlined
                    :rules="rules.required"
                  />
                  <v-text-field
                    v-model="createBioForm.last_name"
                    label="Last name"
                    outlined
                    :rules="rules.required"
                  />
                  <v-text-field
                    v-model="createBioForm.company"
                    label="Organization"
                    outlined
                    :rules="rules.required"
                  />
                  <v-text-field
                    v-model="createBioForm.profession"
                    label="Job Title"
                    outlined
                    :rules="rules.required"
                  />
                  <v-textarea
                    v-model="createBioForm.bio"
                    label="Description"
                    outlined
                    :rules="rules.required"
                  ></v-textarea>
                  <v-btn
                    type="submit"
                    outlined
                    color="blue darken-1"
                    class="mb-5"
                    :loading="areBiosLoading"
                  >
                    Create new bio
                  </v-btn>
                </v-form>
              </div>
            </v-expand-transition>
            <!--      block of bio creation end-->

            <v-text-field
              v-show="!userInfo.first_name"
              outlined
              label="First Name"
              v-model="userInfoForm.first_name"
              :rules="rules.required"
            />
            <v-text-field
              v-show="!userInfo.last_name"
              outlined
              label="Last Name"
              v-model="userInfoForm.last_name"
              :rules="rules.required"
            />
            <v-text-field
              v-show="!userInfo.email"
              outlined
              label="Email"
              v-model="userInfoForm.email"
              :rules="[rules.required, rules.email]"
            />
            <v-text-field
              v-show="!userInfo.organization"
              outlined
              label="Organization"
              v-model="userInfoForm.organization"
              :rules="rules.required"
            />
            <!--      block of talk -->
          </div>
        </v-expand-transition>

        <div class="my-3">Please choose the track for the talk:</div>
        <p v-show="errorTrack" class="red--text">The track must be chosen.</p>
        <div class="d-flex flex-column flex-md-row justify-space-between">
          <Preloader v-if="areTracksLoading"/>
          <div v-for="track in tracks"
               :key="track.id"
               @click="chooseTrack(track.id)"
               class="track-box pa-4"
               v-bind:class="{ frame : chosenTrack === track.id}">
            <div class="text-center black--text pb-3">{{track.title}}</div>
            <p>{{track.description}}</p>
          </div>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="updating"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          type="submit"
          text
          color="blue darken-1"
          :loading="updating"
          :disabled="!expandTalk"
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rules from '~src/assets/js/validationRules'
import Preloader from '../../common/preloader/Preloader'

export default {
  name: 'call-for-papers-modal',
  components: { Preloader },
  props: ['close', 'event'],
  data () {
    return {
      expandTalk: false,
      expandBio: false,
      updating: false,
      talksList: null,
      areTalksLoading: false,
      chosenTalk: null,
      biosList: null,
      areBiosLoading: false,
      createTalkForm: {
        title: '',
        talk_type: '',
        bio_id: null,
        description: ''
      },
      userInfoForm: {
        first_name: '',
        last_name: '',
        email: '',
        organization: ''
      },
      errorNoBio: false,
      createBioForm: {
        first_name: '',
        last_name: '',
        profession: '',
        company: '',
        bio: ''
      },
      areTracksLoading: false,
      tracks: null,
      chosenTrack: null,
      errorTrack: null,
      // tracks: [
      //   {
      //     name: 'Break It',
      //     value: 'break_it',
      //     text: 'This track focuses on teaching each attendees about new threats to users. Some topics to be covered in this track include ransomware attacks, phishing attempts, physical break-ins, and more.'
      //   },
      //   {
      //     name: 'Build It',
      //     value: 'build_it',
      //     text: 'In this track, speakers will cover topics such as best practices for data encryption, building threat intelligence programs to better protect networks, construction of a secure network, and many more.'
      //   },
      //   {
      //     name: 'Show It',
      //     value: 'show_it',
      //     text: 'Are you an ExploitCon Sponsor? Have a demo of your product you want to show off? Apply for the Show It! track. This 15 to 30 minute track is for ExploitCon Sponsors, letting them show off their product to attendees.'
      //   },
      //   {
      //     name: 'Firetalks',
      //     value: 'firetalks',
      //     text: 'Are you an ExploitCon Sponsor? Have a demo of your product you want to show off? Apply for the Show It! track. This 15 to 30 minute track is for ExploitCon Sponsors, letting them show off their product to attendees.'
      //   }
      // ],
      rules
    }
  },
  created () {
    this.getTracks()
    this.getTalks()
    this.getBios()
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    }),
    newBiosMsg () {
      return this.biosList ? 'If you want new bio' : 'You don’t have bios -'
    },
    newTalksMsg () {
      return this.talksList ? 'If you want new talk' : 'You don’t have talks -'
    }
  },
  methods: {
    ...mapActions([
      'GET', 'PUT', 'POST'
    ]),
    getTracks () {
      this.areTracksLoading = true
      this.GET({ route: `/admin/p/event/pid/${this.event}/e/track` })
        .then(({ data }) => {
          console.log('getTracks', data)
          this.tracks = data.data || []
          this.areTracksLoading = false
        })
        .catch(err => {
          console.log(err)
          this.areTracksLoading = false
        })
    },
    chooseTrack: function (value) {
      this.chosenTrack = value
      this.createTalkForm.talk_type = value
      this.errorTrack = null
    },
    openTalkForm: function () {
      if (this.chosenTalk) {
        this.expandTalk = true
        this.chosenTalk = null
        this.$refs.cfpForm.reset()
      } else {
        this.expandTalk = !this.expandTalk
      }
    },
    selectTalk: function () {
      this.expandTalk = true
      const talkObj = this.talksList.find(el => el.id === this.chosenTalk)
      this.createTalkForm = {
        title: talkObj.title,
        bio_id: talkObj.bio_id,
        talk_type: this.chosenTrack,
        description: talkObj.description
      }
      this.userInfoForm = {
        first_name: talkObj.first_name,
        last_name: talkObj.last_name,
        email: talkObj.email,
        organization: talkObj.organization
      }
    },
    getTalks () {
      this.areTalksLoading = true
      this.GET({ route: '/admin/talk/my' }) // user/{cognito_username}/talk
        .then(({ data }) => {
          console.log('/admin/talk/my', data.data)
          this.talksList = data.data || null
        })
        .finally(() => {
          this.areTalksLoading = false
        })
    },
    getBios () {
      this.areBiosLoading = true
      this.GET({ route: `/admin/p/profile/pid/${this.userInfo.id}/e/bio` })
        .then(({ data }) => {
          this.biosList = data.data || null
          this.createTalkForm.bio_id = null
        })
        .finally(() => {
          this.areBiosLoading = false
        })
    },
    createBio: function () {
      if (this.$refs.bioForm.validate()) {
        this.areBiosLoading = true
        this.POST({
          route: `/admin/p/profile/pid/${this.userInfo.id}/e/bio`,
          data: this.createBioForm
        })
          .then(({ data }) => {
            console.log('create bio result', data)
            this.getBios()
            this.expandBio = false
            this.$refs.bioForm.reset()
          })
          .catch(err => {
            console.log(err)
            this.areBiosLoading = false
          })
      }
    },
    submit: function () {
      this.errorNoBio = !this.createTalkForm.bio_id
      if (!this.createTalkForm.talk_type) {
        this.errorTrack = true
      }
      if (this.$refs.cfpForm.validate()) {
        if (!this.errorNoBio && !this.errorTrack) {
          this.updating = true
          this.POST({
            route: `/admin/p/event/pid/${this.event}/e/talk`,
            data: {
              ...this.createTalkForm,
              bio_profile: this.userInfo.id,
              first_name: this.userInfo.first_name || this.userInfoForm.first_name,
              last_name: this.userInfo.last_name || this.userInfoForm.last_name,
              email: this.userInfo.email || this.userInfoForm.email,
              organization: this.userInfo.organization || this.userInfoForm.organization
            }
          })
            .then(({ data }) => {
              console.log('create talk result', data)
              this.$refs.cfpForm.reset()
              this.$emit('apply')
              this.close()
            })
            .catch(err => {
              console.log(err)
              this.updating = false
            })
        }
      }
    }
  }
}
</script>

<style scoped>

  .track-box {
    min-width: 172px;
    overflow: auto;
    border: 1px solid rgba(0, 0, 0, 0.6);
    border-radius: 3px;
    cursor: pointer;
    margin-right: 15px;
  }

  .track-box:last-of-type {
    margin-right: 0;
  }

  .expand-control {
    cursor: pointer;
  }

  .frame {
    box-shadow: 5px 5px 5px 0 #1976d2, inset 2px 2px 5px 0 #1976d2, inset 5px 5px 15px 5px rgba(80, 85, 255, 0);
  }

</style>

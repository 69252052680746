<template>
  <v-card width="500">
    <Preloader v-if="loading"/>
    <v-card-title v-else class="headline text-center">Are you sure you want to {{action}} {{user}}?</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close" :disabled="updating">Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="submit" :loading="updating">{{action}}</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import Preloader from '../../common/preloader/Preloader'

export default {
  name: 'block-user-modal',
  components: { Preloader },
  props: ['close', 'user'],
  data () {
    return {
      updating: false,
      loading: false,
      enabled: false
    }
  },
  computed: {
    action () {
      return this.enabled ? 'disable' : 'enable'
    }
  },
  created () {
    this.getUser()
  },
  watch: {
    user () {
      this.getUser()
    }
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getUser: function () {
      this.loading = true
      this.GET({ route: `/admin/p/user/pid/${this.user}/e/useracl/eid/${this.user}` })
        .then(({ data }) => {
          this.enabled = data.data.enabled
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    submit: function () {
      this.updating = true
      this.GET({
        route: `/admin/user/${this.user}/${this.action}`,
        data: { enabled: !this.user.enabled }
      })
        .then(({ data }) => {
          this.updating = false
          this.$emit('tableUpdated')
          this.close()
        })
        .catch(err => {
          this.updating = false
          console.log(err)
        })
    }
  }
}
</script>

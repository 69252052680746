<template>
  <div>
    <v-btn
      class="nested__add-btn"
      @click="addNested"
      text
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <div
      v-for="(item, counter) in arrayItems"
      v-bind:key="item + counter"
      class="nested__item"
      :style="metadata && metadata.image || metadata.nested && !checkIfContainsEditor() ? 'width: 50%;' : 'width: 100%;'"
    >
      <VideoUpload
        v-if="metadata && metadata.video"
        :url="item.value"
        @videoUrl="(data)=>{addValue(data,counter)}"
      />
      <ImageUpload
        v-else-if="metadata && metadata.image"
        :url="item.value"
        @imageUrl="(data)=>{addValue(data,counter)}"
        class="nested__item-img"
      />
      <NestedFields
        v-else-if="metadata && metadata.nested"
        :schema="schema"
        :field="item.value"
        @nestedForm="(data)=>{addValue(data,counter)}"
        class="nested__item-card"
      />
      <v-text-field
        v-else
        solo
        v-model="item.value"
        placeholder="Text here"
      />
      <!--      :id="counter + Date.now() + ((Math.random() * 100000).toFixed())"-->
      <v-btn text @click="deleteNested(counter)" color="grey" class="delete-action">
        <v-icon>
          mdi-delete-outline
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { makeFormFields } from '~src/assets/js/utils'
import VideoUpload from '../files/videoUpload'
import ImageUpload from '../files/imageUpload'
import NestedFields from './nested-fields'

export default {
  name: 'nested-blocks-fields',
  props: ['field', 'metadata', 'schema'],
  components: {
    NestedFields,
    ImageUpload,
    VideoUpload
  },
  data () {
    return {
      arrayItems: []
    }
  },
  created () {
    if (this.field && this.field.length) {
      const editedItems = []
      this.field.map(item => {
        editedItems.push({ value: item })
      })
      this.arrayItems = editedItems
    } else {
      this.schema ? this.arrayItems = [{ value: this.makeFormFields(this.schema) }] : this.arrayItems = [{ value: '' }]
    }
  },
  watch: {
    arrayItems: {
      handler (arrayItems) {
        let arr = []
        arrayItems.map(i => {
          arr = [...arr, i.value]
        })
        this.$emit('nested', arr.filter(Boolean))
      },
      deep: true
    }
  },
  methods: {
    makeFormFields,
    addNested () {
      if (this.arrayItems.length < 10) {
        // if (this.schema) {
        //   const obj = {}
        //   this.schema.map(i => {
        //     if (i.type === 'bool') {
        //       obj[i.f_name] = false
        //     } else {
        //       obj[i.f_name] = ''
        //     }
        //   })
        //   console.log('look heeeereee schema', obj)
        //   this.arrayItems.push({ value: obj })
        // } else {
        this.arrayItems.push({ value: '' })
        // }
      }
    },
    deleteNested (counter) {
      if (this.arrayItems.length > 1) this.arrayItems.splice(counter, 1)
    },
    addValue (query, idx) {
      // console.log(query, idx)
      // console.log(this.arrayItems)
      this.arrayItems = [
        ...this.arrayItems.slice(0, idx),
        { value: query },
        ...this.arrayItems.slice(idx + 1)]
      // console.log(this.arrayItems)
    },
    checkIfContainsEditor () {
      return Boolean(this.schema.find(i => i.metadata.editor))
    }
  }
}
</script>

<style scoped lang="scss">
  .nested {
    &__item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &-img {
        width: 150px;
      }

      &-card + .delete-action {
        position: absolute;
        top: 4%;
        right: 0;
      }
    }

    &__add-btn {
      position: absolute;
      top: -51px;
      right: 0;
    }
  }
</style>

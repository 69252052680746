<template>
  <v-container>
    <Table
      objectName="resources"
      :data="resources"
      :tableHeader="tableHeaders"
    />
  </v-container>
</template>

<script>
import Table from '~src/components/common/table/Table'
import jsonHeaders from '~src/components/common/table/TableHeaders'
export default {
  name: 'Resources',
  props: {},
  components: {
    Table
  },
  data: () => ({
    jsonHeaders,
    resources: []
  }),
  computed: {
    tableHeaders () {
      return this.jsonHeaders.Resources
    }
  },
  methods: {}
}
</script>

<style lang="scss">
// @import 'path/_variables';
</style>

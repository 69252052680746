export default function (site) {
  let socialIcon = ''
  switch (site) {
    case 'twitter':
      socialIcon = 'mdi-twitter'
      break
    case 'instagram':
      socialIcon = 'mdi-instagram'
      break
    case 'reddit':
      socialIcon = 'mdi-reddit'
      break
    case 'spotify':
      socialIcon = 'mdi-spotify'
      break
    case 'stackoverflow':
      socialIcon = 'mdi-stack-overflow'
      break
    case 'github':
      socialIcon = 'mdi-github'
      break
    case 'linkedin_company':
      socialIcon = 'mdi-linkedin'
      break
    case 'linkedin':
      socialIcon = 'mdi-linkedin'
      break
    case 'facebook':
      socialIcon = 'mdi-facebook'
      break
    default:
      socialIcon = 'mdi-web'
  }
  return socialIcon
}

<template>

    <v-card v-if="data.available" class="slot slot__empty">
      Drag new talk here
    </v-card>

    <v-card v-else :class="Object.keys(data.submission).length ? 'slot drag-item' : 'slot slot__technical'">
      <div v-if="data.submission && Object.keys(data.submission).length" class="pa-3">
<!--        draggable="true"-->
<!--        @dragstart='startDrag($event, slot)'-->
        <v-btn
          icon
          class="slot__close"
          @click="$emit('clearing', { slot: data.id, talk: data.submission.id, track: data.track })"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-icon v-show="data.submission.is_keynote" color="primary" class="slot__keynote">
          mdi-key
        </v-icon>
          <div class="card-talk__title mb-3 mr-3">{{ shortenText(data.title, 18)}}</div>
          <div class="d-flex">
            <v-avatar
              class="mr-3"
              color="grey"
              size="48"
            >
              <img
                v-if="data.submission.bio.image"
                :src="data.submission.bio.image"
                :alt="data.submission.bio.first_name + 'avatar'">
              <span v-else-if="data.submission.bio.first_name" class="white--text headline">{{data.submission.bio.first_name.charAt(0)}}{{data.submission.bio.last_name.charAt(0)}}</span>
              <v-icon v-else dark>
                mdi-account-circle
              </v-icon>
            </v-avatar>
            <div>
              <div style="font-size: 14px">{{data.submission.bio.first_name}} {{data.submission.bio.last_name}}</div>
              <div class="grey--text" style="font-size: 14px"> {{data.submission.bio.job_title}}</div>
            </div>
          </div>

      </div>
      <div v-else>
        {{data.title}}
      </div>
    </v-card>

  <!--    <v-card-actions>-->
  <!--      <v-btn-->
  <!--        text-->
  <!--        @click="reveal = true"-->
  <!--      >-->
  <!--        Learn More-->
  <!--      </v-btn>-->
  <!--    </v-card-actions>-->

  <!--    <v-expand-transition>
        <v-card
          v-if="reveal"
          class="transition-fast-in-fast-out v-card&#45;&#45;reveal"
          style="height: 100%;"
        >
          <v-card-text class="pb-0 mainText&#45;&#45;text card-talk__text">
            <p>late 16th century (as a noun denoting a place where alms were distributed): from medieval Latin
              eleemosynarius, from late Latin eleemosyna ‘alms’, from Greek eleēmosunē ‘compassion’ </p>
          </v-card-text>
          <v-card-actions class="pt-0">
            <v-btn
              text
              color="teal accent-4"
              @click="reveal = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-expand-transition>-->

</template>

<script>
import { shortenText } from '~src/assets/js/utils'
export default {
  name: 'SlotCard',
  props: ['data'],
  components: {},
  data: () => ({
    // reveal: false
  }),
  computed: {},
  methods: { shortenText }
}
</script>

<style scoped lang="scss">
  @import '~src/assets/scss/_variables';

  .slot {
    width: 200px;
    height: 108px;
    overflow: hidden;
    margin-bottom: 30px;

    &__empty {
      text-transform: uppercase;
      padding: 17% 0;
      text-align: center;
      background: rgba(33, 150, 243, 0.1);
      border: 1px solid #2196F3;
      color: #2196F3;
    }
    &__technical {
      text-transform: uppercase;
      padding: 17% 0;
      text-align: center;
    }
    &__close {
      position: absolute;
      right: 0;
      top: 0;
    }
    &__keynote {
      position: absolute;
      right: 2%;
      bottom: 2%;
    }
  }
  @media (min-width: $mob-lg) {
    .slot {
      width: 250px;
    }
  }
  /*.v-card--reveal {*/
  /*  bottom: 0;*/
  /*  opacity: 1 !important;*/
  /*  position: absolute;*/
  /*  min-width: 100%;*/
  /*}*/

</style>

<template>
  <v-card class="card pa-5  d-flex align-content-center justify-space-between">
    <div class="card__body d-flex flex-column justify-space-between ">
      <h3 v-show="title" class="card__title">
        {{ title }}
      </h3>
      <div v-show="description" class="card__description">
        <p class="mb-0">{{ description[0] }} - {{data[0]}}</p>
        <p class="mb-0">{{ description[1] }} - {{data[1]}}</p>
      </div>
      <div>
        <div v-show="extension" class="card__box">
          {{ extension }}
        </div>
      </div>
    </div>
    <div class="card__wrapper">
      <div class="card__sum">
        {{ dataInPercentage }}
      </div>
      <DoughnutChart
        :width="247"
        :height="247"
        :chartdata="{
            labels: description,
            datasets: [
             {
             borderWidth: 0,
             backgroundColor: chartBackgrounds,
             data
             }
           ]
       }"
      />
    </div>
  </v-card>
</template>

<script>
import DoughnutChart from '~src/components/common/charts/DoughnutChart'

export default {
  name: 'CardCircleChart',
  props: [
    'title',
    'extension',
    'data',
    'description',
    'chartBackgrounds'
  ],
  components: { DoughnutChart },
  data () {
    return {}
  },
  computed: {
    dataInPercentage () {
      let result = 100 * this.data[1] / this.data[0]
      if (Math.round(result) === 0) result = 1
      return `${Math.round(result)}%` || ''
    }
  },
  methods: {}
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .card {
    height: 295px;
    min-width: 450px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &__body {
      height: 100%;
    }

    &__wrapper {
      position: relative;
    }

    &__sum {
      font-size: 36px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &__title {
      color: rgba(0, 0, 0, 0.87);
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: rgba(0, 0, 0, 0.6);
    }

    &__box {
      display: inline-block;
      padding: 9px 12px;
      background: rgba(100, 181, 246, 0.3);
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: rgba(0, 0, 0, 0.87);
    }
  }
</style>

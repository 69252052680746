<template>
  <div v-if="video">
    <vue-plyr
      :options="options"
      ref="plyr"
      :emit="['ended', 'resetOnEnd']"
      @ended="videoEnded">
      <video v-if="playerProvider === 'mp4'" :src="video" style="display: block; width: 100%;"/>
      <div v-else :data-plyr-provider="playerProvider" :data-plyr-embed-id="video"/>
    </vue-plyr>
  </div>
  <div v-else class="text-center pt-4 pl-4">
    <h3>No videos currently at this time.</h3>
    <h4>Please check back later!</h4>
  </div>
</template>

<script>
import VuePlyr from 'vue-plyr'

export default {
  name: 'Video',
  props: ['video'],
  components: { VuePlyr },
  data () {
    return {
      options: { autoplay: false }
    }
  },
  computed: {
    playerProvider () {
      if (this.video.includes('vimeo')) {
        return 'vimeo'
      }
      if (this.video.includes('youtube')) {
        return 'youtube'
      }
      if (this.video.includes('.mp4')) {
        return 'mp4'
      }
      return ''
    }
  },
  methods: {
    videoEnded: function () {
      this.$emit('videoEnded')
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-7 my-6"},[_c('v-data-table',{attrs:{"headers":_vm.tableHeader,"items":_vm.data,"search":_vm.search,"loading":_vm.loading,"fixed-header":"","items-per-page":5,"footer-props":{showFirstLastPage: true}},on:{"click:row":function (row){ return _vm.rowAction(row); }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-icon',{staticClass:"mr-2",on:{"click":function($event){$event.stopPropagation();return _vm.refresh($event)}}},[_vm._v(" mdi-refresh ")]),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.tableTitle))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-dialog',{attrs:{"overlay-opacity":"0.7"},on:{"click:outside":function($event){_vm.editedItem = null}},scopedSlots:_vm._u([(_vm.allowCreation)?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2 ml-5",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New "+_vm._s(_vm.newBtn)+" ")])]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_vm._t("default",[(_vm.fields)?_c('CreateEditModal',{staticStyle:{"width":"600px"},attrs:{"formTitle":_vm.formTitle,"url":_vm.url,"fields":_vm.fields,"editedItem":_vm.editedItem,"close":_vm.close},on:{"tableUpdated":_vm.refresh,"modalError":_vm.errorHandling}}):_vm._e()],{"editedItem":_vm.editedItem,"close":_vm.close})],2),_c('v-dialog',{attrs:{"max-width":"500px"},on:{"click:outside":function($event){_vm.editedItem = null}},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('delete-modal',{attrs:{"close":_vm.close,"url":_vm.url,"noIdMode":_vm.noIdMode,"item":_vm.editedItem},on:{"tableUpdated":_vm.refresh}})],1)],1)]},proxy:true},{key:"item.rowClick",fn:function(){return [_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v("mdi-cursor-default-click-outline")])]},proxy:true},{key:"item.start",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.start)))]}},{key:"item.end",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.end)))]}},{key:"item.created",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.created)))]}},{key:"item.updated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.updated)))]}},{key:"item.modified",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.modified)))]}},{key:"item.website",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.openLink(item.website)}}},[_vm._v(_vm._s(item.website.length ? "mdi-open-in-new" : "mdi-minus"))])]}},{key:"item.full_name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name))]}},{key:"item.full_coordinates",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.latitude)+", "+_vm._s(item.longitude))]}},{key:"item.is_virtual",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item ? 'Virtual' : 'Hybrid'))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-4",on:{"click":function($event){$event.stopPropagation();return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.deleteItem(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":"","color":item.enabled ? 'green' : 'red'},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('block', item)}}},[_vm._v(" "+_vm._s(item.enabled ? 'mdi-block-helper' : 'mdi-checkbox-multiple-marked-circle-outline')+" ")])]}}],null,true)}),_c('i',{directives:[{name:"show",rawName:"v-show",value:(_vm.note),expression:"note"}],staticClass:"ml-3 grey--text"},[_c('sup',[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.note)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <CardTemplate title="Codes Management" class="my-6">

    <v-alert
      v-for="(ord, index) in ordersSuccess"
      :key="index"
      border="right"
      colored-border
      type="success"
      elevation="2"
    >
      Your order created successfully:
      <div v-for="(details, code) in ord" :key="code">
        {{details.qty + ' ' + details.level}} code{{details.qty > 1 ? 's' : ''}}
        <v-tooltip right color="primary" close-delay="500">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              @click="copyToClipboard(code)"
              v-bind="attrs"
              v-on="on"
              class="mx-3 my-2"
            >
              {{code}}
            </v-btn>
          </template>
          <span>{{copied ? 'Code copied to clipboard' : ' Click to copy code'}}</span>
        </v-tooltip>
        <a :href="joinLink(code)" target="_blank" rel="noopener noreferrer">
          Open join link
        </a>
      </div>
    </v-alert>

    <v-form
      ref="orderForm"
      @submit.prevent="createOrder"
    >
      <v-text-field
        outlined
        clearable
        label="Email of the person requesting codes"
        v-model="email"
        :rules="rules.required"
      />
      <v-text-field
        outlined
        clearable
        label="Organization"
        hint="First chars will be used in code, can be empty"
        persistent-hint
        v-model="organization"
      />
      <h3 v-show="Object.keys(codes).length" class="pt-6 pb-3">Codes:</h3>
      <div class="d-flex flex-wrap">
        <div v-for="[code, value] in Object.entries(codes)" :key="code">
          <v-chip
            large
            close
            label
            outlined
            color="primary"
            @click:close="removeCode(code)"
            class="mr-2 mb-2"
          >
            <h3 class="mr-2">{{code}} / </h3>
            <div class="mr-2"> Level: {{value.level}} / </div>
            <div class="mr-2">Quantity: {{value.qty}}</div>
          </v-chip>
        </div>
      </div>
      <h3 class="pt-6 pb-3">Add {{Object.keys(codes).length ? 'one more' : ''}} code:</h3>
      <v-form
        ref="codeForm"
        @submit.prevent="verifyCode"
        class="d-flex align-center"
      >
        <v-text-field
          clearable
          label="Code"
          v-model="code"
          :rules="rules.required"
          class="mr-3"
        />
        <v-select
          dense
          label="Select Level *"
          :loading="isTicketTypesLoading"
          :items="ticketLevels"
          v-model="level"
          :rules="rules.required"
          class="mr-3"
        />
        <v-text-field
          type="number"
          label="Quantity"
          hint="Quantity of tickets available in the code"
          v-model="quantity"
          :rules="rules.required"
          maxlength="100"
          class="mr-3"
          style="max-width: 230px"
        />
        <v-btn :loading="generating" @click="generateCode" class="mr-3">
          <v-icon class="mr-2">mdi-draw</v-icon>
          Generate code
        </v-btn>
        <v-btn outlined type="submit" color="primary" :loading="verifying">
          Create
        </v-btn>
      </v-form>
      <v-progress-linear v-if="generating" indeterminate class="my-4"/>
      <h3 v-show="errorMsg" class="red--text">{{errorMsg}}</h3>
      <h3 v-show="codeExists" class="red--text">
        Looks like such code already exists, try entering a different name or word
        combination to generate a new code
      </h3>
      <v-btn type="submit" :loading="creatingOrder" color="primary" class="mt-3">
        Submit
      </v-btn>
    </v-form>
  </CardTemplate>
</template>

<script>
import { mapActions } from 'vuex'
import CardTemplate from '~src/components/common/Cards/CardTemplate'
import rules from '~src/assets/js/validationRules'

export default {
  name: 'CodesManagement',
  props: [],
  components: { CardTemplate },
  data: () => ({
    rules,
    isTicketTypesLoading: false,
    ticketLevels: undefined,
    organization: '',
    email: '',
    codes: {},
    code: null,
    level: '',
    quantity: '',
    generating: false,
    verifying: false,
    creatingOrder: false,
    ordersSuccess: [],
    copied: false,
    codeExists: false,
    errorMsg: null
  }),
  created () {
    this.getTicketTypes()
  },
  methods: {
    ...mapActions(['GET', 'POST']),
    getTicketTypes () {
      this.isTicketTypesLoading = true
      this.GET({ route: `/ticketing/event/${this.$route.params.id}/ticket_type` })
        .then(({ data }) => {
          this.ticketLevels = data.data.map(ticket => ticket.id)
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isTicketTypesLoading = false
        })
    },
    generateCode () {
      this.errorMsg = null
      this.generating = true
      this.POST({
        route: `admin/event/${this.$route.params.id}/generate_code`,
        data: {
          level: this.level,
          org_name: this.organization
        }
      })
        .then(({ data }) => {
          this.code = data.data.code
        })
        .catch((err) => {
          this.errorMsg = err.response.data.msg || 'Sorry, an error occurred.'
        })
        .finally(() => {
          this.generating = false
        })
    },
    verifyCode () {
      this.errorMsg = null
      if (this.$refs.codeForm.validate()) {
        this.codeExists = false
        this.verifying = true
        this.GET({
          route: `ticketing/protected/event/${this.$route.params.id}/code/${this.code}`
        })
          .then((res) => {
            if (res.status === 200) this.codeExists = true
          })
          .catch(err => {
            // must return 404
            if (err.response && err.response.status === 404) {
              this.codes[this.code] = {
                level: this.level,
                qty: this.quantity
              }
            }
          })
          .finally(() => {
            this.$refs.codeForm.reset()
            this.verifying = false
          })
      }
    },
    removeCode (code) {
      this.$delete(this.codes, code)
    },
    createOrder () {
      if (!Object.keys(this.codes).length) {
        this.errorMsg = 'You need to add at least one code to create an order'
        return
      }
      if (this.$refs.orderForm.validate()) {
        this.creatingOrder = true
        this.POST({
          route: `ticketing/event/${this.$route.params.id}/custom_order`,
          data: {
            order_metadata: {
              event: this.$route.params.id,
              organization: this.organization,
              receipt_email: this.email,
              codes: this.codes
            }
          }
        })
          .then(({ data }) => {
            this.ordersSuccess = [...this.ordersSuccess, data.data.order_metadata.codes]
            this.$refs.orderForm.reset()
            this.codes = {}
          })
          .catch((err) => {
            this.errorMsg = err.response.data.msg || ''
          })
          .finally(() => {
            this.creatingOrder = false
          })
      }
    },
    joinLink (code) {
      return `${process.env.VUE_APP_BASE_URL.split('/api/')[0]}/#/${this.$route.params.id}/join/${code}`
    },
    async copyToClipboard (code) {
      try {
        await navigator.clipboard.writeText(code)
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 2000)
      } catch ($e) {
        console.log('Cannot copy')
      }
    }
  }
}
</script>

<style lang="scss"></style>

<template>
  <v-form
    ref="editForm"
    @submit.prevent="save"
  >
    <v-card-text>
      <v-container v-if="editedItem" class="d-flex flex-wrap px-5">
        <div
          v-for="field in editableFields"
          v-bind:key="field.f_name"
          class="fields-wrapper"
          v-bind:class="[field.type === 'datetime' ? halfWidth : '']"
          :style='additionalImgStyles(field.metadata.image)'
        >
          <div v-if="field.type === 'str'">
            <div v-if="field.metadata.image">
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <ImageUpload
                :id="field.f_name"
                v-model="editedItem[field.f_name]"
                :url="editedItem[field.f_name]"
                @imageUrl="(data)=>{editedItem[field.f_name]=data}"
              />
            </div>
            <div v-else-if="field.metadata.video">
              <!--              video we could check : blogpost+event-->
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <VideoUpload :id="field.f_name" :url="editedItem[field.f_name]"
                           @videoUrl="(data)=>{editedItem[field.f_name]=data}"/>
            </div>
            <div v-else-if="field.metadata.editor" class="editor-item pt-5">
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <QuillEitor class="pt-2" :id="field.f_name" v-model="editedItem[field.f_name]"/>
            </div>
            <div v-else-if="field.metadata.paragraph" class="pt-5">
              <v-textarea
                outlined
                v-model="editedItem[field.f_name]"
                :label="makeFieldName(field.f_name)"
                :rules="field.required ? [rules.required] : []"
              ></v-textarea>
            </div>
            <div v-else-if="field.metadata.email">
              <v-text-field
                v-model="editedItem[field.f_name]"
                :label="makeFieldName(field.f_name)"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </div>
            <div v-else-if="field.metadata.foreign_key">
              <SelectField
                v-model="editedItem[field.f_name]"
                :value="editedItem[field.f_name]"
                :field="field"
                @select="(data)=>{editedItem[field.f_name]=data}"
              />
            </div>
            <div v-else>
              <v-text-field
                v-model="editedItem[field.f_name]"
                :label="makeFieldName(field.f_name)"
                :rules="field.required ? [rules.required] : []"
              ></v-text-field>
            </div>
          </div>

          <div v-else-if="field.type === 'bool'">
            <v-checkbox
              v-model="editedItem[field.f_name]"
              :label="makeFieldName(field.f_name)"
            ></v-checkbox>
          </div>

          <!--          TODO to check how label looks-->
          <div v-else-if="field.type === 'float'">
            <v-text-field
              v-model.number="editedItem[field.f_name]"
              :label="makeFieldName(field.f_name)"
              type="number"
              maxlength="10"
              :rules="field.required ? rules.required : []"
            ></v-text-field>
          </div>

          <div v-else-if="field.type === 'int'">
            <v-text-field
              v-model.number="editedItem[field.f_name]"
              :label="makeFieldName(field.f_name)"
              type="number"
              maxlength="10"
              :rules="field.required ? [rules.required, rules.noFloat] : [rules.noFloat]"
            ></v-text-field>
          </div>

          <!-- type date for future - if it exist -->
          <!--          <div v-else-if="field.type === 'date'">-->
          <!--            <v-date-picker-->
          <!--              v-model="editedItem[field.f_name]"/>-->
          <!--          </div>-->

          <!--        TODO: required validation !!!!!!!!!!!!!!  -->
          <div v-else-if="field.type === 'datetime'">
            <div class="my-5 v-input__control">
              <label class="v-label theme--light my-3" :for="field.f_name">
                <v-icon class="mr-3">mdi-calendar-clock</v-icon>
                {{makeFieldName(field.f_name)}}
              </label>
              <datetime
                :id="field.f_name"
                v-model="editedItem[field.f_name]"
                type="datetime"
                input-style="border: 1px solid #9e9e9e; padding: 10px 5px 10px 8px"
                :minute-step="15"
                use12-hour
                value-zone="local"
                zone="local"
              >
              </datetime>
            </div>
          </div>

          <!-- type "nested" means nested items inside of inner field-->
          <!--        TODO:  check  schema + style -->
          <div v-else-if="field.type === 'nested'" class="mb-5">
            <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
            <NestedFields
              :id="field.f_name"
              :schema="field.inner"
              :field="editedItem[field.f_name]"
              style="max-width: 100%"
              @nestedForm="(data)=>{editedItem[field.f_name]=data}"
            />
          </div>

          <!-- type "list" means list of smth, ['', '']. Separate variants for: social media(parser), video, image lists-->
          <div v-else-if="field.type === 'list'" class="pt-5">
            <div v-if="field.f_name === 'img_info'"></div>
            <div v-else-if="field.f_name === 'social_media'">
              <SocialMedia
                :value="editedItem[field.f_name]"
                :v-model="editedItem[field.f_name]"
                @social="(data)=>{editedItem[field.f_name]=data}"
              />
            </div>
            <div v-else>
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <NestedBlocksFields
                :id="field.f_name"
                class="nested-items-wrapper"
                v-model="editedItem[field.f_name]"
                :field="editedItem[field.f_name]"
                :metadata="field.inner.metadata"
                :schema="field.inner.schema"
                @nested="(data)=>{editedItem[field.f_name]=data}"
              />
            </div>

          </div>

          <div v-else-if="field.type === 'dict'">
            <!--                TODO: remove -->
<!--            <div v-if="field.f_name === 'img_info'" style="width: 390px">
              <Upload/>
            </div>
            <div v-else-if="field.f_name === 'threed_info'" style="width: 390px">
              <v-file-input
                accept="image/*"
                label="3D model upload"
              ></v-file-input>
            </div>-->
            <div v-if="!field.metadata.hide">
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <DictionaryField
                :id="field.f_name"
                v-model="editedItem[field.f_name]"
                :field="editedItem[field.f_name]"
                :metadata="field.metadata"
                @nestedDict="(data)=>{editedItem[field.f_name]=data}"
              />
            </div>
          </div>

          <div v-else>
            {{field}}
          </div>

        </div>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :disabled="loading"
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        type="submit"
        text
        color="blue darken-1"
        :loading="loading"
      >
        Edit
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
// import Upload from '~src/components/admin/2DUpload'
import NestedFields from './nested-fields'
import NestedBlocksFields from './nested-blocks-field'
import SelectField from './select-field'
import DictionaryField from './dictionary-field'
import QuillEitor from '~src/components/common/quillEditor/quillEditor'
import SocialMedia from '../socialMedia/social-media'
import ImageUpload from '../files/imageUpload'
import VideoUpload from '../files/videoUpload'

import { makeFieldName } from '~src/assets/js/utils'
import rules from '~src/assets/js/validationRules'
import { mapActions } from 'vuex'

export default {
  name: 'edit-fields',
  components: {
    NestedFields,
    NestedBlocksFields,
    DictionaryField,
    SelectField,
    ImageUpload,
    VideoUpload,
    SocialMedia,
    QuillEitor
    // Upload
  },
  props: ['close', 'editableFields', 'url', 'item'],
  data () {
    return {
      loading: false,
      halfWidth: 'half-width-item',
      editedItem: this.item,
      rules
    }
  },
  methods: {
    ...mapActions([
      'GET', 'PUT'
    ]),
    makeFieldName,
    additionalImgStyles: function (img) {
      if (img) return 'order: -1;'
    },
    save: function () {
      if (this.$refs.editForm.validate()) {
        console.log('edit', this.editedItem, `${this.url}/${this.editedItem.id}`)
        this.loading = true
        this.PUT({
          route: `${this.url}/${this.editedItem.id}`,
          data: this.editedItem
        })
          .then(({ data }) => {
            console.log('edit result', data)
            this.loading = false
            // this.$refs.editForm.reset()
            this.$emit('tableUpdated', 'edit')
            this.close()
          })
          .catch(err => {
            console.log(err)
            this.loading = false
            this.$emit('modalError', err.response.data.msg)
            this.close()
          })
      }
    }
  }
}
</script>

<style lang="scss">
  .fields-wrapper {
    width: 100%;
  }

  .half-width-item {
    width: 50%;
  }

  .editor-item {
    min-height: 300px;
  }

  .nested-items-wrapper {
    position: relative;
    margin: 15px 0;
    display: flex;
    flex-wrap: wrap;
  }
</style>

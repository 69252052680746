<template>
  <CardTemplate title="Event Attendees" class="my-6">
    <p>Download attendee report for the event.</p>
    <p>Check column 'Attended' to check whether participant attended live or virtual event or did not attend.</p>
    <vue-excel-xlsx
      :loading="isAttendeesLoading"
      :data="attendees"
      :columns="columns"
      :file-name="attendeeFileName"
      :sheet-name="attendeeFileName"
      class="mb-2 mt-2 ml-5 v-btn v-btn--contained theme--dark v-size--default primary"
    >
      Download Attendee Report
    </vue-excel-xlsx>
  </CardTemplate>
</template>

<script>
import { mapActions } from 'vuex'
import CardTemplate from '~src/components/common/Cards/CardTemplate'

export default {
  name: 'AttendeesDownload',
  components: { CardTemplate },
  props: [''],
  data () {
    return {
      isAttendeesLoading: false,
      loading: false,
      attendees: undefined,
      columns: [
        {
          label: 'Name',
          field: 'first_name'
        },
        {
          label: 'Last Name',
          field: 'last_name'
        },
        {
          label: 'Organization',
          field: 'organization'
        },
        {
          label: 'Job Title',
          field: 'job_title'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Phone',
          field: 'phone'
        },
        {
          label: 'Email opt-in',
          field: 'optin'
        },
        {
          label: 'Attended',
          field: 'attended'
        },
        {
          label: 'Connected From',
          field: 'geo_ip_data'
        }
      ]
    }
  },
  created () {
    this.getEventAttendees()
  },
  computed: {
    attendeeFileName () {
      const fileName = `attendees_${this.$route.params.id}`
      if (fileName >= 31) {
        return 'attendees'
      }
      return fileName
    }
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getEventAttendees: function () {
      this.isAttendeesLoading = true
      this.GET({ route: `/ticketing/event/${this.$route.params.id}/attendee_report` })
        .then(({ data }) => {
          this.attendees = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isAttendeesLoading = false
        })
    }
  }
}
</script>

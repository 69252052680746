<template>
  <v-card>
    <v-card-title class="headline">Are you sure you want to delete this item?</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close" :disabled="updating">Cancel</v-btn>
      <v-btn color="blue darken-1" text @click="deleteObject" :loading="updating" :disabled="updating">OK</v-btn>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'delete-modal',
  components: {},
  props: ['close', 'url', 'item', 'noIdMode'],
  data () {
    return {
      updating: false
    }
  },
  computed: {
    // todo remove noIdMode
    /*    route () {
      // Exception for group-user modal - not id but name or username
      let route = `${this.url}/${this.item.id}`
      if (this.noIdMode) {
        const name = this.item.name ? this.item.name : this.item.username
        const split = this.url.split('$')
        route = split[0] + name + split[1] + `/eid/${name}`
      }
      return route
    } */
  },
  methods: {
    ...mapActions([
      'DELETE'
    ]),
    deleteObject: function () {
      this.updating = true
      console.log('item to DELETE', this.item)
      console.log('route DELETE', `${this.url}/${this.item.id}`)
      this.DELETE({ route: `${this.url}/${this.item.id}` })
        .then(({ data }) => {
          this.updating = false
          this.$emit('tableUpdated', 'delete')
          this.close()
        })
        .catch(err => {
          this.updating = false
          console.log(err)
          this.$emit('modalError', 'delete')
          this.close()
        })
    }
  }
}
</script>

<style lang="scss">

</style>

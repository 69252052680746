<template>
  <v-card>
<!--    <Preloader v-if="loading"/>-->
    <v-card-title class="d-flex justify-center">
      <span class="headline">{{ formTitle }}</span>
    </v-card-title>

    <!--Edit mode-->
    <EditFields v-if="editedItem" :close="close" :editableFields="editableFields" :url="url" :item="editedItem" v-on="$listeners"/>

    <!--create mode -->
    <CreateFields v-else :close="close" :editableFields="editableFields" :url="url" :form="form" v-on="$listeners"/>

  </v-card>
</template>

<script>
import { makeFormFields } from '~src/assets/js/utils'
import CreateFields from './create-fields'
import EditFields from './edit-fields'

export default {
  name: 'create-edit-modal',
  components: {
    EditFields,
    CreateFields
  },
  props: ['formTitle', 'url', 'fields', 'close', 'editedItem'],
  data () {
    return {
      form: null
    }
  },
  created () {
    console.log('this.editedItem', this.editedItem)
    this.form = this.makeFormFields(this.editableFields)
  },
  computed: {
    editableFields () {
      return this.fields.filter(i => i.update)
    }
  },
  methods: {
    makeFormFields
  }
}
</script>

<template>
  <v-container class="marketing pa-0">
    <v-row>
      <v-col sm="12" md="6">
        <CardTemplate
          title="Landing page"
          button-text="copy link"
          is-background-transparent-button
          @card-btn-clicked="copyLink"
        >
          <v-img
            src="https://picsum.photos/400/800"
            class="marketing__thumbnail"
          />
        </CardTemplate>
      </v-col>
      <v-col sm="12" md="6">
        <CardTemplate
          title="Social media posts"
          button-text="get images"
          is-background-transparent-button
          @card-btn-clicked="getImages"
        >
          <v-img
            src="https://picsum.photos/400/800"
            class="marketing__thumbnail"
          />
        </CardTemplate>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CardTemplate from '~src/components/common/Cards/CardTemplate'

export default {
  name: 'Marketing',
  props: {},
  components: {
    CardTemplate
  },
  data: () => ({}),
  computed: {},
  methods: {
    copyLink () {
      console.log('copy link')
    },
    getImages () {
      console.log('get images')
    }
  }
}
</script>

<style lang="scss">
// @import 'path/_variables';
.marketing {
  &__thumbnail {
    height: 580px;
    width: 100%;
    object-fit: fill;
  }
}
</style>

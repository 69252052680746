<template>
  <v-container class="no-items mt-6">
    <div class="no-items__text px-6">
      <slot />
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'NoItems',
  props: {},
  components: {},
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
// @import 'path/_variables';
.no-items {
  border: 1px solid rgba(0, 0, 0, 0.1);
  height: calc(100vh - 64px - 48px);
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    font-size: 34px;
    line-height: 150%;
    text-align: center;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.6);
  }
}
</style>

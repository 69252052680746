<template>
  <Table
    :loading="loading"
    objectName="blogposts"
    :data="posts"
    :tableHeader="headers"
    :fields="postsFields"
    url='/blogpost'
    :refresh="getPosts"
    :allowCreation=true
    note="Before you go! Please add tag with event id for event related blogposts"
  />
</template>

<script>
import { mapActions } from 'vuex'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import Table from '~src/components/common/table/Table'

export default {
  name: 'BlogPostsTable',
  components: { Table },
  data () {
    return {
      loading: false,
      headers: jsonHeaders.BlogPosts,
      posts: undefined,
      postsFields: null
    }
  },
  created () {
    this.getPosts()
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getPostsFields: function () {
      this.GET({ route: '/fields/blogpost' })
        .then(({ data }) => {
          this.postsFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPosts: function () {
      this.loading = true
      this.GET({ route: '/blogpost' })
        .then(({ data }) => {
          this.posts = data.data
          this.getPostsFields()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

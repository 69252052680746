<template>
  <v-card style="width: 70vw" v-if="editedItem" class="px-9 py-3">
    <v-form
      ref="form"
      @submit.prevent="saveSubmission"
    >
    <SpeakerKitManagement v-if="editedItem.status === 'confirmed'" :id="item.id" />
      <v-card-title>
        <span class="headline">Edit {{editedItem.status === 'confirmed' ? 'Talk' : 'Submission'}}</span>
      </v-card-title>
      <v-card-text>
        <div class="bio">
          <h3 class="my-3">Notes</h3>
          <v-textarea
            outlined
            label="Notes"
            v-model="editedItem.notes"
          />
          <h3 class="my-3">Talk</h3>
          <v-text-field
            v-model="editedItem.talk.title"
            label="Title"
            outlined
          />
          <v-textarea
            outlined
            label="Talk Description"
            v-model="editedItem.talk.description"
          />
          <h3 class="my-3">Bio</h3>
          <v-row>
            <v-col>
              <ImageUpload
                v-model="editedItem.bio.image"
                :url="editedItem.bio.image"
                :workflow="workflow"
                @imageUrl="(data)=>{editedItem.bio.image = data}"
                style="width: 250px"
              />
            </v-col>
            <v-col>
              <v-text-field
                outlined
                label="First name"
                v-model="editedItem.bio.first_name"
              />
              <v-text-field
                outlined
                label="Last name"
                v-model="editedItem.bio.last_name"
              />
              <v-checkbox
                v-model="editedItem.is_keynote"
                label="Keynote Speech"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" md="6">
              <v-text-field
                v-model="editedItem.bio.organization"
                label="Company"
                outlined
              />
              <v-text-field
                v-model="editedItem.bio.job_title"
                label="Position"
                outlined
              />
            </v-col>
            <v-col sm="12" md="6">
              <v-textarea
                v-model="editedItem.bio.bio"
                outlined
                label="Bio"/>
            </v-col>
          </v-row>
          <h3 class="my-3">Track</h3>
          <v-select
            style="width: 100%"
            outlined
            label="Select Track"
            :items="tracks"
            v-model="editedItem.track"
            item-text="title"
            item-value="id"
            class="mb-3"
          />
          <h3 class="my-3">Status</h3>
          <v-select
            style="width: 100%"
            outlined
            label="Select Status"
            :items="statuses"
            v-model="editedItem.status"
            item-text="name"
            item-value="value"
            class="mb-3"
          />
          <h3 class="my-3">Contact details</h3>
          <v-row>
            <v-col>
              <v-text-field
                v-model="editedItem.phone"
                label="Phone"
                outlined
              />
            </v-col>
            <v-col>
              <v-text-field
                v-model="editedItem.email"
                label="Email"
                outlined
              />
            </v-col>
          </v-row>
          <SocialMedia
            :value="editedItem.bio.social_media"
            :v-model="editedItem.bio.social_media"
            @social="(data)=>{editedItem.bio.social_media=data}"
          />
          <div v-if="editedItem.status === 'confirmed'">
            <h3 class="my-3">You can add additional speakers to this talk, please choose</h3>
            <v-autocomplete
              v-model="editedItem.add_bio_ids"
              :value="editedItem.add_bio_ids"
              :items="submissions"
              chips
              clearable
              color="blue-grey lighten-2"
              label="Additional bios"
              multiple
              item-text="bio.first_name"
              item-value="id"
              class="mt-5 mb-8"
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeChip(data.item, 'add_bio_ids')"
                >
                  <v-avatar left color="grey">
                    <img
                      v-if="data.item.bio.image"
                      :src="data.item.bio.image"
                      :alt="data.item.bio.first_name + ' ' + data.item.bio.last_name">
                    <span v-else-if="data.item.bio.first_name" class="white--text headline">{{data.item.bio.first_name.charAt(0)}}{{data.item.bio.last_name.charAt(0)}}</span>
                    <v-icon v-else dark>
                      mdi-account-circle
                    </v-icon>
                  </v-avatar>
                  {{ data.item.bio.first_name }}
                  {{ data.item.bio.last_name }}
                </v-chip>
              </template>
              <template v-slot:item="data">
                <v-list-item-avatar color="grey" class="d-flex justify-center">
                  <img
                    v-if="data.item.bio.image"
                    :src="data.item.bio.image"
                    :alt="data.item.bio.first_name  + ' ' + data.item.bio.last_name">
                  <span v-else-if="data.item.bio.first_name" class="black--text headline">{{data.item.bio.first_name.charAt(0)}}{{data.item.bio.last_name.charAt(0)}}</span>
                  <v-icon v-else dark>
                    mdi-account-circle
                  </v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{data.item.bio.first_name + ' ' + data.item.bio.last_name}}</v-list-item-title>
                  <v-list-item-subtitle> {{data.item.bio.organization + ' - ' + data.item.bio.job_title}} </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </div>
        </div>
        <!-- <div class="mb-3">Invite code</div>
        <v-text-field
          outlined
          label="Invite code"
          v-model="editedItem.invite_code"
        /> -->
        <div class="mt-5 mb-3">Meeting url</div>
        <v-text-field
          outlined
          label="Meeting url"
          v-model="editedItem.meeting_url"
        />
        <SelectField
          v-model="editedItem.managing_sponsor"
          :value="editedItem.managing_sponsor"
          :field="managing_sponsor_field"
          @select="(data)=>{editedItem.managing_sponsor=data}"
        />
        <v-autocomplete
          v-model="editedItem.associated_users"
          :value="editedItem.associated_users"
          :disabled="isUsersLoading"
          :loading="isUsersLoading"
          :items="users"
          chips
          clearable
          color="blue-grey lighten-2"
          label="Associated users"
          multiple
          item-text="email"
          item-value="id"
          class="mt-5"
        >
          <template v-slot:selection="data">
            <v-chip
              v-bind="data.attrs"
              :input-value="data.selected"
              close
              @click="data.select"
              @click:close="removeChip(data.item, 'associated_users')"
            >
              <v-avatar left color="grey">
                <img
                  v-if="data.item.image"
                  :src="data.item.image"
                  :alt="data.item.display_name">
                <span v-else-if="data.item.first_name" class="white--text headline">{{data.item.first_name.charAt(0)}}{{data.item.last_name.charAt(0)}}</span>
                <v-icon v-else dark>
                  mdi-account-circle
                </v-icon>
              </v-avatar>
              {{ data.item.display_name }}
            </v-chip>
          </template>
          <template v-slot:item="data">
              <v-list-item-avatar color="grey" class="d-flex justify-center">
                <img
                  v-if="data.item.image"
                  :src="data.item.image"
                  :alt="data.item.display_name">
                <span v-else-if="data.item.first_name" class="black--text headline">{{data.item.first_name.charAt(0)}}{{data.item.last_name.charAt(0)}}</span>
                <v-icon v-else dark>
                  mdi-account-circle
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title v-html="data.item.display_name"></v-list-item-title>
                <v-list-item-subtitle>{{data.item.first_name + ' ' + data.item.last_name}}</v-list-item-subtitle>
                <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
              </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="updating"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          type="submit"
          color="blue darken-1"
          :loading="updating"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import { makeFieldName } from '~src/assets/js/utils'
import rules from '~src/assets/js/validationRules'

import ImageUpload from '~src/components/common/files/imageUpload'
import SocialMedia from '~src/components/common/socialMedia/social-media'
import SelectField from '~src/components/common/createEditModal/select-field'
import SpeakerKitManagement from './SpeakerKitManagement'

export default {
  name: 'submission-modal',
  props: ['item', 'close', 'tracks', 'submissions'],
  components: {
    SpeakerKitManagement,
    SelectField,
    SocialMedia,
    ImageUpload
  },
  data () {
    return {
      editedItem: this.item,
      workflow: ['profile_image'],
      statuses: [
        {
          name: 'Submitted',
          value: 'submitted'
        },
        {
          name: 'Accepted',
          value: 'accepted'
        },
        {
          name: 'Scheduled',
          value: 'scheduled'
        },
        {
          name: 'Confirmed',
          value: 'confirmed'
        }],
      updating: false,
      isUsersLoading: false,
      users: [],
      managing_sponsor_field: {
        f_name: 'managing_sponsor',
        metadata: { foreign_key: `event/${this.$router.currentRoute.params.id}/sponsorship` },
        required: false,
        type: 'str',
        update: true
      },
      rules
    }
  },
  created () {
    this.getUsers()
  },
  watch: {
    item (value) {
      this.editedItem = value
    }
  },
  methods: {
    ...mapActions(['GET', 'PUT']),
    makeFieldName,
    getUsers: function () {
      this.isUsersLoading = true
      this.GET({ route: '/user' })
        .then(({ data }) => {
          this.users = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isUsersLoading = false
        })
    },
    removeChip (item, field) {
      const index = this.editedItem[field].findIndex(i => i === item.id)
      if (index >= 0) this.editedItem[field].splice(index, 1)
    },
    saveSubmission: function () {
      if (this.$refs.form.validate()) {
        console.log(this.editedItem)
        this.updating = true
        this.PUT({
          route: `/event/${this.$router.currentRoute.params.id}/submission/${this.item.id}`,
          data: this.editedItem
        })
          .then(({ data }) => {
            this.$emit('tableUpdated')
            this.$refs.form.reset()
            this.close()
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.updating = false
          })
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .bio {
    border-bottom: 1px solid #d2d2d2;
  }
</style>

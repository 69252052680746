<template>
  <div class="profile">
    <PageTitle :title="userName">
      <v-dialog v-if="user && !user.enabled" v-model="blockUserModal">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            unblock
          </v-btn>
        </template>
        <BlockUserModal :close="closeModals" :user="userName"/>
      </v-dialog>
    </PageTitle>
    <v-container class="pa-8 pr-10">
      <div v-if="errorUser">Sorry, an error occurred with this user</div>
      <Preloader v-if="loading"/>
      <v-card v-else>
        <div v-if="user && !user.enabled"
             class="v-alert__border v-alert__border--right error v-alert__border--has-color"/>
        <div class="pa-5 d-flex flex-column flex-md-row">
          <ImageView :url="user.image" class="mr-5 profile__avatar"/>
          <div>
            <div class="d-flex mt-3">
              <h3 class="pb-2">{{user.first_name}} {{user.last_name}}</h3>
              <v-chip
                v-if="user && !user.enabled"
                label
                outlined
                small
                color="red"
                class="ml-5"
              >
                blocked
              </v-chip>
            </div>

            <p class="subtitle">{{user.email}}</p>
            <p class="subtitle">{{user.job_title}} - {{user.organization}}</p>
            <div class="d-flex">
              <v-dialog
                v-model="groupModal"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    outlined
                    large
                    class="mr-5"
                    v-bind="attrs"
                    v-on="on"
                  >
                    Manage groups
                  </v-btn>
                </template>
                <SelectGroupModal :close="closeModals" :user="user" @groupUpdated="getUser"/>
              </v-dialog>

              <v-expansion-panels v-model="panel" class="profile__select-wrap pa-0" dark>
                <v-expansion-panel>
                  <v-expansion-panel-header class="profile__select-header">Edit</v-expansion-panel-header>
                  <v-expansion-panel-content class="profile__select-content">
                    <div class="profile__select-item" @click="userModal=true">Edit</div>
                    <div class="profile__select-item" @click="deleteUserModal=true">Delete</div>
                    <div class="profile__select-item" @click="blockUserModal=true">Block</div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>

            </div>
          </div>
        </div>
        <v-tabs v-model="tab" grow class="mb-2">
          <v-tab>Basic info</v-tab>
          <v-tab>Contact</v-tab>
          <v-tab v-show="user.bio">Bio</v-tab>
        </v-tabs>
      </v-card>

      <v-tabs-items v-if="user" v-model="tab" class="pa-5">
        <v-tab-item>
          <v-row>
            <v-col>
              <b>Username</b>
              <p>{{userName}}</p>
              <div class="d-flex justify-space-between">
                <div>
                  <b>Password</b>
                  <p>xxxxxxxx</p>
                </div>

                <v-dialog v-model="changePasswModal" max-width="500px">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      outlined
                      class="mr-5"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Reset
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="headline text-center">
                      Reset password for {{userName}}? <br/> User will receive an e-mail.
                    </v-card-title>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" text @click="closeModals" :disabled="isResetPasswordLoading">Cancel
                      </v-btn>
                      <v-btn color="blue darken-1" text @click="resetPassword" :loading="isResetPasswordLoading">Reset
                      </v-btn>
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>

              </div>

              <b>Groups</b>
              <p>{{user.groups}}</p>
            </v-col>
            <v-col>
              <b>Account status</b>
              <p>{{user.enabled ? 'Enabled' : 'Disabled' }}</p>
              <b>Created</b>
              <p>{{user.created | moment }}</p>
              <b>Last modified</b>
              <p>{{user.modified | moment}}</p>
            </v-col>
          </v-row>

        </v-tab-item>
        <v-tab-item>
          <div v-show="user.email">
            <b>Email</b>
            <div>{{user.email}}</div>
          </div>
          <div v-show="user.phone">
            <b>Phone</b>
            <div>{{user.phone}}</div>
          </div>
          <!--          TODO social media-->
          <div v-show="user.social_media.length">
            <b>Social media</b>
            <div>{{user.social_media}}</div>
          </div>
        </v-tab-item>
        <v-tab-item v-show="user.bio">
          {{user.bio}}
        </v-tab-item>
      </v-tabs-items>

      <v-dialog v-model="userModal" width="700px">
        <UserModal :close="closeModals" :item="user" @tableUpdated="getUser"/>
      </v-dialog>

      <v-dialog v-model="deleteUserModal" max-width="500px">
        <DeleteModal
          :close="closeModals"
          :noIdMode="true"
          :url='`/admin/p/user/pid/$/e/useracl`'
          :item="user"
          @tableUpdated="userDeleted"
        />
      </v-dialog>

      <v-dialog v-model="blockUserModal">
        <BlockUserModal :close="closeModals" :user="userName" @tableUpdated="getUser"/>
      </v-dialog>

    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import Preloader from '~src/components/common/preloader/Preloader'
import ImageView from '../../common/files/Image'
import UserModal from './user-create-edit-modal'
import DeleteModal from '~src/components/common/deleteModal/delete-modal'
import SelectGroupModal from './select-group-modal'
import BlockUserModal from './block-user-modal'

export default {
  name: 'UserProfile',
  props: {},
  components: {
    PageTitle,
    Preloader,
    ImageView,
    BlockUserModal,
    SelectGroupModal,
    DeleteModal,
    UserModal
  },
  data: () => ({
    loading: false,
    isResetPasswordLoading: false,
    user: null,
    errorUser: false,
    tab: null,
    panel: null,
    groupModal: false,
    userModal: false,
    addToGroupModal: false,
    deleteUserModal: false,
    blockUserModal: false,
    changePasswModal: false
  }),
  created () {
    this.getUser()
  },
  computed: {
    userName () {
      return this.$router.currentRoute.params.id
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('MMM Do YYYY, h:mm:ssa')
    }
  },
  methods: {
    ...mapActions([
      'GET', 'POST'
    ]),
    getUser: function () {
      this.loading = true
      this.panel = false
      this.GET({ route: `admin/e/profile/eid/${this.userName}` })
        .then(({ data }) => {
          console.log(data)
          this.user = data.data
          this.getUserAcl()
        })
        .catch(err => {
          console.log(err)
          this.errorUser = true
        })
    },
    getUserAcl: function () {
      this.GET({ route: `user_profile/${this.userName}` })
        .then(({ data }) => {
          console.log(data)
          this.user = {
            ...this.user,
            username: this.userName,
            groups: data.data.groups.join(', '),
            enabled: data.data.enabled
          }
        })
        .catch(err => {
          console.log(err)
          this.errorUser = true
        })
        .finally(() => {
          this.loading = false
        })
    },
    resetPassword () {
      console.log('reset')
    },
    userDeleted () {
      console.log('deleted')
      // go to home page + message about success
    },
    closeModals () {
      this.groupModal = false
      this.userModal = false
      this.deleteUserModal = false
      this.changePasswModal = false
      this.blockUserModal = false
      this.addToGroupModal = false
      this.tab = null
    }
  }
}
</script>

<style lang="scss">

  .profile {

    &__avatar {
      width: 152px;
      height: 152px
    }

    &__select-wrap {
      width: 95px;
      height: 44px;
    }

    &__select-header {
      text-transform: uppercase;
      padding: 10px 15px;
      background-color: #1976d2;
    }

    .v-expansion-panel--active > .v-expansion-panel-header {
      min-height: 40px;
    }

    &__select-item {
      cursor: pointer;
      text-transform: uppercase;
      padding: 10px 15px;
      border: 1px solid rgba(255, 255, 255, 0.6);
    }

    &__select-content {
      .v-expansion-panel-content__wrap {
        padding: 0;
      }

      background-color: #1976d2;
    }

  }

  .v-alert__border--right {
    z-index: 2;
  }
</style>

<template>
  <v-card class="card-stats">
    <p v-show="title" class="card-stats__subtitle">
      {{ title }}
    </p>
    <p v-show="leftTitle" class="card-stats__title">
      {{ leftTitle }}
    </p>
    <div class="d-flex flex-column align-center">
      <p class="card-stats__quantity mb-0 mt-7">
        {{ quantity }}
      </p>
      <p v-show="description" class="card-stats__description" v-bind:class="{ 'card-stats__box': highlighted }">
        {{ description }}
      </p>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'CardStats',
  props: ['title', 'leftTitle', 'quantity', 'description', 'highlighted'],
  components: {},
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
// @import 'path/_variables';
.card-stats {
  min-height: 206px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__title {
    position: absolute;
    top: 16px;
    left: 16px;
    font-weight: 500;
    font-size: 15px;
    line-height: 23px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.87);
  }

  &__subtitle {
    position: absolute;
    top: 16px;
    right: 20px;
    margin: 0;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6);
  }

  &__quantity {
    font-size: 64px;
    line-height: 75px;
    font-weight: 300;
  }

  &__description {
    color: rgba(0, 0, 0, 0.87);
    font-size: 12px;
    padding: 9px 12px;
  }

  &__box {

    background: rgba(100, 181, 246, 0.3);
    border-radius: 3px;

  }

}
</style>

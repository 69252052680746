<template>
  <div>
    <v-card class="pa-5" v-if="chosenTemplate">
      <div class="d-flex justify-space-between">
        <v-tooltip right>
          <template v-slot:activator="{ on, attrs }">
            <h3 v-bind="attrs" v-on="on" class="d-inline mr-5">
              Hotspots filling
            </h3>
          </template>
          <span>This block will be shown in the top of your sponsor page</span>
        </v-tooltip>

        <v-btn
          color="primary"
          :loading="loading"
          @click="save"
        >
          Save
        </v-btn>
      </div>

      <v-row>
        <v-col sm="12" md="8">
          <v-select
            v-model="chosenTemplate"
            :items="data"
            :item-text="chosenTemplate.name ? 'name' : 'link'"
            return-object
            label="Choose template"
            outlined
            class="mt-4 mb-2"
          />
        </v-col>

      </v-row>

      <!--      <div class="d-flex align-baseline">
              <v-dialog v-model="addImgModal">

                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    color="blue darken-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    I want to add image
                  </v-btn>
                </template>

                <v-card class="environment__add-img-modal">
                  <v-card-title>
                    <span class="headline mb-5">Add custom image</span>
                  </v-card-title>
                  <v-card-text>
                    <div class="environment__text">Our Admin will add image with spots to your list as soon as possible.</div>
                    <v-text-field
                      outlined
                      label="Template name"
                    />
                    <div class="environment__text"><i>Note: images with dark background looks better.</i></div>
                    <ImageUpload @imageUrl="(data)=>{imageToAdd=data}" width="490px"/>
                    <div class="environment__text mt-5">Here you can put any special wishes, for example - number of
                      hotspots.
                    </div>
                    <v-textarea
                      outlined
                      label="Comment"
                      value="comment field - quantity of hotspots I need??"
                    ></v-textarea>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="blue darken-1"
                      text
                      :disabled="isImgLoading"
                      @click="addImgModal=false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="blue darken-1"
                      :loading="isImgLoading"
                      :disabled="isEmpty(imageToAdd)"
                      @click="saveImage"
                    >
                      Submit
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
      &lt;!&ndash;        <i class="ml-3"><sup>*</sup> Your image is under moderation. We will add it as soon as possible</i>&ndash;&gt;
            </div>-->

      <div class="my-5">Please click the hotspot on the image to see it’s data, than you can create or edit what will be
        attached to it by clicking
        <v-icon>mdi-pencil</v-icon>
        on the right side
      </div>

      <v-row v-if="chosenTemplate">
        <v-col sm="12" md="8">
          <ImageWithSpots :hotspot="chosenTemplate"/>
        </v-col>
        <v-col sm="12" md="4" v-show="chosenTemplate.hotspots">
          <v-list rounded>

            <v-subheader>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-row
                    class="mb-8"
                    align="center"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <strong class="mx-4 info--text text--darken-2">
                      Remaining: {{ remainingSpots}}
                    </strong>

                    <v-divider vertical></v-divider>

                    <strong class="mx-4 success--text text--darken-2">
                      Completed: {{ completedSpots }}
                    </strong>

                    <v-spacer></v-spacer>

                    <v-progress-circular
                      :value="progress"
                      class="mr-2"
                    ></v-progress-circular>
                  </v-row>
                </template>
                <span>Click on each to add or edit</span>
              </v-tooltip>
            </v-subheader>

            <v-list-item-group color="primary">
                  <v-list-item
                    v-for="spot in chosenTemplate.hotspots"
                    v-bind:key="spot.id"
                    @click="openModal(spot)"
                  >
                    <v-list-item-icon>
                      <v-icon class="text--darken-2" v-bind:class="spot.resource_type ? 'success--text': 'info--text'">
                        {{ spot.resource_type? 'mdi-check-circle' : 'mdi-checkbox-blank-circle-outline'}}
                      </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>
                        <div class="text-capitalize">
                          {{spot.resource_type || 'Click to add'}}
                          <v-icon class="mx-4">{{spot.resource_type ? 'mdi-pencil':'mdi-plus-thick'}}</v-icon>
                        </div>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
            </v-list-item-group>
          </v-list>

          <v-dialog v-model="addEditSpotInfoModal" width="700px">
            <AddEditHotspotModal v-if="chosenSpot" :spot="chosenSpot" :close="closeModal" @resource="changeResource"/>
          </v-dialog>
        </v-col>

      </v-row>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from '~src/assets/js/utils'
import ImageWithSpots from './ImageWithSpots'
import AddEditHotspotModal from './AddEditHotspotInfoModal'

export default {
  name: 'Environment',
  props: ['data', 'loading', 'chosen'],
  components: {
    AddEditHotspotModal,
    ImageWithSpots
  },
  data: () => ({
    addEditSpotInfoModal: false,
    chosenTemplate: null,
    chosenSpot: null
    // imageToAdd: '',
    // addImgModal: false,
    // isImgLoading: false,
    /*    isUpdateError: false,
    updateErrorText: '',
    isUpdateSuccess: false,
    updateSuccessText: '',
    notificationTimeout: null */
  }),
  computed: {
    ...mapGetters({ baseUrl: 'currentBaseUrl' }),
    completedSpots () {
      return this.chosenTemplate?.hotspots?.filter(spot => spot.resource_type).length
    },
    progress () {
      return this.completedSpots / this.chosenTemplate.hotspots?.length * 100
    },
    remainingSpots () {
      return this.chosenTemplate?.hotspots?.length - this.completedSpots
    }
  },
  mounted () {
    this.chosenTemplate = this.data[0]
  },
  watch: {
    data: {
      handler (val) {
        if (val && val.length) this.chosenTemplate = val[0]
      }
    },
    chosen () {
      if (this.chosen) this.chosenTemplate = this.chosen// for saving the template view after adding smth
    }
  },
  methods: {
    ...mapActions(['GET', 'PUT']),
    isEmpty,
    save () {
      this.$emit('save')
    },
    changeResource (query) {
      this.$emit('spot', this.chosenTemplate, { ...this.chosenSpot, ...query })
    },
    openModal (spot) {
      this.chosenSpot = spot
      this.addEditSpotInfoModal = true
    },
    closeModal () {
      this.addEditSpotInfoModal = false
      this.chosenSpot = null
    }
  }
}
</script>

<style scoped lang="scss">
  .environment {

    &__text {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.87);
      margin-bottom: 20px;
    }

    &__add-img-modal {
      min-width: 620px;
    }
  }
</style>

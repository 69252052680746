<template>
  <v-card class="card-image">
    <ImageView :url="data.head_picture" class="card-image__img"/>
    <p class="card-image__title mt-3 mb-6 px-4">
      {{ data.title }}
    </p>
    <p class="card-image__text mb-0 px-4" v-html="textReady"/>
    <v-card-actions class="card-image__btn-container">
      <v-btn
        text
        x-large
        color="primary"
        @click="$emit('read-more', data.id)"
      >
        read more
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ImageView from '../../common/files/Image'

export default {
  name: 'CardImage',
  props: ['data'],
  components: { ImageView },
  data: () => ({}),
  computed: {
    textReady () {
      return this.data.content.length <= 250
        ? this.data.content
        : `${this.data.content.split('').splice(0, 250).join('')}...`
    }
  },
  methods: {}
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .card-image {
    height: 100%;
    max-height: 510px;
    display: flex;
    flex-direction: column;

    &__img {
      flex-grow: 0;
      height: 250px
    }

    &__title {
      font-size: 20px;
      line-height: 23px;
      font-weight: 500;
    }

    &__text {
      font-size: 14px;
      line-height: 22px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 300;
    }

    &__btn-container {
      margin-top: auto;
    }
  }
</style>

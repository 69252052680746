<template>
  <div class="profile">
    <Preloader v-if="isSponsorLoading" class="my-auto" style="height: 20vh"/>
    <PageTitle title="Company profile"/>
    <v-container class="pa-8 pr-10">
      <v-alert
        text
        :value="isUpdateSuccess || isUpdateError"
        :type="isUpdateSuccess ? 'success' : 'error'"
      >
        {{ isUpdateSuccess ? updateSuccessText : updateErrorText }}
      </v-alert>
      <v-card>
        <v-card-text>
          Data filling for {{ fillingPercentage }} percents
        </v-card-text>
        <v-container>
          <v-row
            no-gutters
            justify="space-between"
            align="center"
          >
            <v-col sm="11">
              <v-progress-linear :value="fillingPercentage"></v-progress-linear>
            </v-col>
            <v-col sm="1" class="profile__percents">
              {{ fillingPercentage }}%
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-row>
        <v-col sm="12" md="8">
          <CardTemplate title="Link To Exhibitor Kit">
            <div class="d-flex justify-space-between">
              <a :href="exhibitorKitLink" target="_blank" rel="noopener noreferrer" style="font-size: 20px">
                Open link
              </a>
              <v-btn text @click="copyToClipboard(exhibitorKitLink)">
                <v-icon class="mr-2">mdi-share</v-icon>
                {{copied ? 'Link copied to clipboard' : ' Click to copy link'}}
              </v-btn>
            </div>
          </CardTemplate>
        </v-col>
        <v-col sm="12" md="8">
          <CardTemplate title="Basic Information" class="mb-6">
            <v-text-field
              v-model="form.name"
              label="Name"
              outlined
              :loading="isSponsorUpdating"
            />
            <v-textarea
              v-model="form.summary"
              outlined
              label="Summary"
              :loading="isSponsorUpdating"
            />
            <label class="v-label theme--light" for="sponsor-description">Description</label>
            <QuillEitor id="sponsor-description" v-model="form.description" class="editor"/>

          </CardTemplate>
          <CardTemplate title="Contact info" class="mb-6">
            <div class="main-grey-header my-4">Website</div>
            <v-text-field
              v-model="form.website"
              placeholder="Add website link"
              outlined
              :loading="isSponsorUpdating"
            />

            <SocialMedia :value="form.social_media" v-model="form.social_media" @social="(data)=>{form.social_media = data}" />
          </CardTemplate>
        </v-col>
        <v-col sm="12" md="4">

          <CardTemplate title="Logo" class="mb-6">
            <ImageUpload :url="form.logo" v-model="form.logo" @imageUrl="(data)=>{form.logo = data}"/>
          </CardTemplate>
          <v-checkbox
            v-model="form.logo_only"
            label="Logo only"
          />
          <v-checkbox
            v-model="form.paid"
            label="Paid"
          />

          <label class="v-label theme--light" for="sponsor-videos">Videos</label>
          <div class="nested" :key="videosMarker">
            <v-btn
              class="nested__add-btn"
              @click="addNested"
              text
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
            <div
              id="sponsor-videos"
              v-for="(item, counter) in form.videos"
              v-bind:key="item + counter + form.videos.length"
              class="nested__item"
            >
              <VideoUpload
                :url="item.value"
                @videoUrl="(data)=>{addVideoItem(data,counter)}"
              />
              <v-btn text @click="deleteNested(counter)" color="grey" class="delete-action">
                <v-icon>
                  mdi-delete-outline
                </v-icon>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-btn color="primary" width="100%" class="mb-6" @click="saveProfile">
        save
      </v-btn>

      <v-alert
        text
        :value="isUpdateSuccess || isUpdateError"
        :type="isUpdateSuccess ? 'success' : 'error'"
      >
        {{ isUpdateSuccess ? updateSuccessText : updateErrorText }}
      </v-alert>

      <HotspotsModeration
        v-if="hotspotsData"
        :data="hotspotsData"
        :loading="isSponsorUpdating"
        @templates="changeTemplates"
        @save="updateSponsor({img_info: hotspotsData })"
      />

      <v-alert
        text
        :value="isUpdateSuccess || isUpdateError"
        :type="isUpdateSuccess ? 'success' : 'error'"
      >
        {{ isUpdateSuccess ? updateSuccessText : updateErrorText }}
      </v-alert>

      <Environment
        :key="isUpdateSuccess"
        v-if="hotspotsData"
        :data="hotspotsData"
        :loading="isSponsorUpdating"
        :chosen="templateWhichEditing"
        @spot="changeSpots"
        @save="updateSponsor({img_info: hotspotsData })"
      />

    </v-container>
<!--    <div v-if="!companyProfileRaw.was_viewed" class="profile__footer">-->
<!--      <v-container>-->
<!--        <v-row align="center">-->
<!--          <v-col sm="8">-->
<!--            This is data from your home page. Is data correct?-->
<!--          </v-col>-->
<!--          <v-col sm="4">-->
<!--            <v-btn-->
<!--              text-->
<!--              color="#fff"-->
<!--              class="profile__first-save"-->
<!--              @click="saveProfile('firstSave')"-->
<!--            >-->
<!--              save-->
<!--            </v-btn>-->
<!--          </v-col>-->
<!--        </v-row>-->
<!--      </v-container>-->
<!--    </div>-->
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmpty } from '~src/assets/js/utils'
import config from '~public/config/config.json'
import Preloader from '~src/components/common//preloader/Preloader'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import CardTemplate from '~src/components/common/Cards/CardTemplate'
import QuillEitor from '~src/components/common/quillEditor/quillEditor'
import ImageUpload from '~src/components/common/files/imageUpload'
import VideoUpload from '~src/components/common/files/videoUpload'
import SocialMedia from '~src/components/common/socialMedia/social-media'
import Environment from '~src/components/common/Environment/Environment'
import HotspotsModeration from './EnvironmentHotspotsModeration'
// import FileUpload from '~src/components/common/files/fileUpload'

export default {
  name: 'CompanyProfile',
  props: {},
  components: {
    Preloader,
    PageTitle,
    QuillEitor,
    ImageUpload,
    VideoUpload,
    SocialMedia,
    CardTemplate,
    // FileUpload,
    Environment,
    HotspotsModeration
  },
  data: () => ({
    config,
    form: {
      name: '',
      description: '',
      summary: '',
      logo: '',
      website: '',
      social_media: [],
      videos: [],
      paid: false,
      logo_only: false
      // parent_sponsor: ''
      // resources: ''
    },
    exhibitorKitLink: null,
    copied: false,
    videosMarker: 0,
    templateWhichEditing: null,
    hotspotsData: null,
    isSponsorUpdating: false,
    isSponsorLoading: false,
    isUpdateError: false,
    updateErrorText: '',
    isUpdateSuccess: false,
    updateSuccessText: '',
    notificationTimeout: null
  }),
  computed: {
    fillingPercentage () {
      const form = this.form
      delete form.logo_only
      delete form.paid
      const formArr = Object.entries(form)
      const full = formArr.length
      const filled = formArr.reduce((acc, [key, value]) => {
        if (value && !isEmpty(value)) acc++
        return acc
      }, 0)
      return Math.round((filled / full) * 100)
    }
  },
  mounted () {
    this.getSponsor()
    this.getSponsorCodes()
  },
  beforeDestroy () {
    clearTimeout(this.notificationTimeout)
  },
  watch: {
    // hotspotsData (value) {
    //   if (value) {
    //     console.log('watch hotspotsData', value)
    //   }
    // },
    isUpdateError (value) {
      if (value) {
        this.notificationTimeout = setTimeout(() => {
          this.clearNotifications()
        }, 5000)
      }
    },
    isUpdateSuccess (value) {
      if (value) {
        this.notificationTimeout = setTimeout(() => {
          this.clearNotifications()
        }, 5000)
      }
    }
  },
  methods: {
    ...mapActions(['GET', 'PUT']),
    isEmpty,
    getSponsor () {
      this.isSponsorLoading = true
      this.GET({
        route: `event/${this.$router.currentRoute.params.id}/sponsorship/${this.$router.currentRoute.params.sponsorId}`
      }).then(({ data }) => {
        console.log('Sponsor RESPONSE:', data.data.img_info)
        this.hotspotsData = data.data.img_info
        this.form = {
          name: data.data.name,
          description: data.data.description,
          summary: data.data.summary,
          logo: data.data.logo,
          logo_only: data.data.logo_only,
          website: data.data.website,
          social_media: data.data.social_media,
          paid: data.data.paid
          // parent_sponsor: data.data.parent_sponsor
        }

        if (data.data.videos && data.data.videos.length) {
          const editedItems = []
          data.data.videos.map(item => {
            editedItems.push({ value: item })
          })
          this.form.videos = editedItems
        } else {
          this.form.videos = [{ value: '' }]
        }
      }).finally(() => { this.isSponsorLoading = false })

      /* return this.GET({ route: 'admin/whoami' })
        .then(({ data }) => {
          this.sponsorObj = data.data.sponsor[0]
          this.form.companyName = this.sponsorObj.title
        }) */
    },
    getSponsorCodes () {
      this.GET({
        route: `/protected/event/${this.$router.currentRoute.params.id}/sponsorship/${this.$router.currentRoute.params.sponsorId}/code_set`
      }).then(({ data }) => {
        if (data.data.url) {
          this.exhibitorKitLink = `${process.env.VUE_APP_BASE_URL.split('/api/')[0]}/#/${this.$router.currentRoute.params.id}/exhibitor-kit/${data.data.url}`
        }
      }).catch((err) => { console.error(err) })
    },
    saveProfile () {
      const formData = { ...this.form }
      let videosArr = []
      this.form.videos.map(i => {
        videosArr = [...videosArr, i.value]
      })
      formData.videos = videosArr.filter(item => item)

      this.updateSponsor(formData)
    },
    updateSponsor (data) {
      this.isSponsorUpdating = true
      this.PUT({
        route: `admin/event/${this.$router.currentRoute.params.id}/sponsorship/${this.$router.currentRoute.params.sponsorId}`,
        data
      }).then(() => {
        this.isUpdateSuccess = true
        this.updateSuccessText = 'Company profile updated successfully'
      }).catch(() => {
        this.isUpdateError = true
        this.updateErrorText = 'An error occurred. Please try again later or contact our support at ' + this.config.helpEmail
      }).finally(() => { this.isSponsorUpdating = false })
    },
    addVideoItem (query, idx) {
      this.form.videos = [...this.form.videos.slice(0, idx), { value: query }, ...this.form.videos.slice(idx + 1)]
    },
    addNested () {
      if (this.form.videos.length < 10) {
        this.form.videos.push({ value: '' })
        this.videosMarker = this.videosMarker++
      }
    },
    deleteNested (counter) {
      if (this.form.videos.length > 1) this.form.videos.splice(counter, 1); this.videosMarker = this.videosMarker--
    },
    changeTemplates (data) {
      this.hotspotsData = data
    },
    changeSpots (template, spot) {
      const templateIdx = this.hotspotsData.findIndex(el => el.link === template.link)
      const newHotspotsData = this.hotspotsData[templateIdx].hotspots.map(el => {
        if (el.id === spot.id) {
          return spot
        }
        return el
      })
      this.hotspotsData = [
        ...this.hotspotsData.slice(0, templateIdx),
        { ...this.hotspotsData[templateIdx], hotspots: newHotspotsData },
        ...this.hotspotsData.slice(templateIdx + 1)
      ]

      this.templateWhichEditing = { ...this.hotspotsData[templateIdx], hotspots: newHotspotsData } // for saving the template view in environment block
    },
    clearNotifications () {
      this.isUpdateSuccess = false
      this.updateSuccessText = ''
      this.isUpdateError = false
      this.updateErrorText = ''
    },
    async copyToClipboard (code) {
      try {
        await navigator.clipboard.writeText(code)
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 2000)
      } catch ($e) {
        console.log('Cannot copy')
      }
    }
  }
}
</script>

<style scoped lang="scss">
  // @import 'path/_variables';
  .profile {
    &__percents {
      flex-grow: 0;
      min-width: max-content;
      text-align: right;
      color: #2196F3;
    }

  }
  .editor{
    height: auto;
  }
  .nested {
    position: relative;

    &__add-btn {
      position: absolute;
      top: -30px;
      right: 0;
    }

    &__item {
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;

      &-card + .delete-action {
        position: absolute;
        top: 4%;
        right: 0;
      }
    }

  }
</style>

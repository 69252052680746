import { Auth } from 'aws-amplify'
import router from '../../router/index'
const state = {
  cognitoUsername: '',
  cognitoGroups: [],
  userAcl: {},
  sponsorInfo: {},
  userInfo: {}
}
const getters = {
  getCognitoRoles: state => state.cognitoGroups
    .filter(group => group.toLowerCase().includes('admin') ||
      group.toLowerCase().includes('moderator') ||
      group.toLowerCase().includes('sponsor') ||
      group.toLowerCase().includes('speaker')),
  getAdmin: state => state.cognitoGroups.some(group => group.toLowerCase().includes('admin')),
  getModerator: state => state.cognitoGroups.some(group => group.toLowerCase().includes('moderator')),
  getSponsor: state => state.cognitoGroups.some(group => group.toLowerCase().includes('sponsor')),
  getSpeaker: state => state.cognitoGroups.some(group => group.toLowerCase().includes('speaker')),
  getCognitoUsername: state => state.cognitoUsername,
  getUserAcl: state => state.userAcl,
  getSponsorInfo: state => state.sponsorInfo,
  getUserInfo: state => state.userInfo
}
const mutations = {
  setCognitoUsername (state, payload) {
    state.cognitoUsername = payload
  },
  setCognitoGroups (state, payload) {
    state.cognitoGroups = payload
  },
  setUserAcl (state, payload) {
    state.userAcl = payload
  },
  setSponsorInfo (state, payload) {
    state.sponsorInfo = payload
  },
  setUserInfo (state, payload) {
    state.userInfo = payload
  }
}
const actions = {
  signIn: function (_, creds) {
    return new Promise((resolve, reject) => {
      Auth.signIn(creds.username, creds.password)
        .then(user => {
          const token = user.signInUserSession.idToken.jwtToken
          // this.$axios.defaults.headers.common['Authorization'] = token;
          resolve(token)
        })
        .catch(err => {
          localStorage.clear()
          reject(err)
        })
    })
  },
  // signUp: function(state, creds){
  //     return new Promise((resolve, reject) => {
  //         Auth.signUp({
  //             username: creds.username,
  //             password: creds.password,
  //             attributes: {
  //                 email: creds.email,
  //             },
  //             validationData: []
  //         })
  //             .then(response => {
  //                 resolve(response)
  //             })
  //             .catch(err => {
  //                 reject(err);
  //             })
  //     })
  // },
  // confirmSignUp: function(state, creds){
  //     return new Promise((resolve, reject) => {
  //         Auth.confirmSignUp(creds.username, creds.code, {forceAliasCreation: true})
  //             .then(response => {
  //                 resolve(response)
  //             })
  //             .catch(err => {
  //                 reject(err);
  //             })
  //     })
  // },
  // resendSignUp: function(state, creds){
  //     return new Promise((resolve, reject) => {
  //         Auth.resendSignUp(creds.username)
  //             .then(response => {
  //                 resolve(response)
  //             })
  //             .catch(err => {
  //                 reject(err);
  //             })
  //     })
  // },
  getCurrentToken () {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(user => {
          // store.dispatch('updateUser', user.idToken.payload['cognito:username'])
          const token = user.idToken.jwtToken
          // this.$axios.defaults.headers.common['Authorization'] = token;
          resolve(token)
        })
        .catch(err => {
          localStorage.clear()
          reject(err)
        })
    })
  },
  obtainToken (state, creds) {
    return new Promise((resolve, reject) => {
      Auth.signIn(creds.username, creds.password)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            router.push(
              {
                name: 'CompleteNewPassword',
                params: { id: creds.username.toLowerCase(), password: creds.password },
                query: creds.query
              })
          } else {
            const token = user.signInUserSession.idToken.jwtToken
            // this.$axios.defaults.headers.common['Authorization'] = token;
            resolve(token)
          }
        })
        .catch(err => {
          localStorage.clear()
          reject(err)
        })
    })
  },
  getCurrentUser: function (state, creds) {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(user => {
          resolve(user.idToken.payload['cognito:username'])
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  getCurrentUserGroups: function (state, creds) {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(user => {
          resolve(user.idToken.payload['cognito:groups'])
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  signOut () {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then(data => {
          resolve(data)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  changePassword (e, params) {
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser()
        .then(user => {
          Auth.changePassword(user, params.oldPassword, params.newPassword)
            .then(response => {
              router.push('/login')
            })
            .catch(err => {
              localStorage.clear()
              reject(err)
            })
        })
    })
  },
  completeNewPassword: function (e, params) {
    return new Promise((resolve, reject) => {
      Auth.signIn(params.username, params.oldPassword)
        .then(user => {
          if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
            // const { requiredAttributes } = user.challengeParam
            Auth.completeNewPassword(
              user,
              params.newPassword,
              {
                name: params.name,
                email: params.email,
                phone_number: params.number
              })
              .then(user => {
                router.push('/')
              })
              .catch(err => {
                reject(err)
              })
          } else {
            // console.log(user)
          }
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  forgotPassword: function (e, params) {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(params)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  forgotPasswordSubmit: function (e, params) {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(params.username, params.code, params.password)
        .then(response => {
          resolve(response)
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}
export default {
  state,
  getters,
  mutations,
  actions
}

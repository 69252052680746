<template>
  <v-form
    ref="createForm"
    @submit.prevent="save"
  >
    <v-card-text>
      <v-container class="d-flex flex-wrap px-5">
        <div
          v-for="field in editableFields"
          v-bind:key="field.f_name"
          class="fields-wrapper"
          v-bind:class="[field.type === 'datetime' ? halfWidth : '']"
          :style='additionalImgStyles(field.metadata.image)'
        >
          <div v-if="field.type === 'str'">
            <div v-if="field.metadata.image">
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <ImageUpload
                :id="field.f_name"
                v-model="form[field.f_name]"
                @imageUrl="(data)=>{form[field.f_name]=data}"
              />
            </div>
            <div v-else-if="field.metadata.video">
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <VideoUpload :id="field.f_name" @videoUrl="(data)=>{form[field.f_name]=data}"/>
            </div>
            <div v-else-if="field.metadata.editor" class="editor-item pt-5">
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <QuillEitor class="pt-2" :id="field.f_name" v-model="form[field.f_name]"/>
            </div>
            <div v-else-if="field.metadata.paragraph" class="pt-5">
              <v-textarea
                outlined
                v-model="form[field.f_name]"
                :label="makeFieldName(field.f_name)"
                :rules="field.required ? [rules.required] : []"
              ></v-textarea>
            </div>
            <div v-else-if="field.metadata.email">
              <v-text-field
                v-model="form[field.f_name]"
                :label="makeFieldName(field.f_name)"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </div>
            <div v-else-if="field.metadata.foreign_key">
              <SelectField
                v-model="form[field.f_name]"
                :field="field"
                @select="(data)=>{form[field.f_name]=data}"
              />
            </div>
            <div v-else>
              <v-text-field
                v-model="form[field.f_name]"
                :label="makeFieldName(field.f_name)"
                :rules="field.required ? rules.required : []"
              ></v-text-field>
            </div>
          </div>

          <div v-else-if="field.type === 'bool'">
            <v-checkbox
              :value=false
              v-model="form[field.f_name]"
              :label="makeFieldName(field.f_name)"
            >
            </v-checkbox>
          </div>

          <div v-else-if="field.type === 'float'">
            <v-text-field
              v-model.number="form[field.f_name]"
              :label="makeFieldName(field.f_name)"
              type="number"
              maxlength="10"
              :rules="field.required ? rules.required : []"
            ></v-text-field>
          </div>

          <div v-else-if="field.type === 'int'">
            <v-text-field
              v-model.number="form[field.f_name]"
              :label="makeFieldName(field.f_name)"
              type="number"
              maxlength="10"
              :rules="field.required ? [rules.required, rules.noFloat] : [rules.noFloat]"
            ></v-text-field>
          </div>

          <!-- type date for future - if it exist -->
          <!--          <div v-else-if="field.type === 'date'" class=" mr-5">-->
          <!--            <v-date-picker :v-model="form[field.f_name]"/>-->
          <!--          </div>-->

          <div v-else-if="field.type === 'datetime'">
            <div class="my-5 v-input__control">
              <label class="v-label theme--light my-3" :for="field.f_name">
                <v-icon class="mr-3">mdi-calendar-clock</v-icon>
                {{makeFieldName(field.f_name)}}
              </label>
              <datetime
                :id="field.f_name"
                v-model="form[field.f_name]"
                type="datetime"
                input-style="border: 1px solid #9e9e9e; padding: 10px 5px 10px 8px"
                :minute-step="15"
                use12-hour
                value-zone="local"
                zone="local"
              >
              </datetime>
            </div>
          </div>

          <div v-else-if="field.type === 'nested'" class="mb-5">
            <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
            <NestedFields
              :id="field.f_name"
              :schema="field.inner"
              style="max-width: 100%"
              @nestedForm="(data)=>{form[field.f_name]=data}"
            />
          </div>

          <div v-else-if="field.type === 'list'" class="pt-5">
            <div v-if="field.f_name === 'img_info'"></div>
            <div v-else-if="field.f_name === 'social_media'">
              <SocialMedia
                v-model="form[field.f_name]"
                @social="(data)=>{form[field.f_name]=data}"
              />
            </div>
            <div v-else>
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <NestedBlocksFields
                :id="field.f_name"
                class="nested-items-wrapper"
                v-model="form[field.f_name]"
                :metadata="field.inner.metadata"
                :schema="field.inner.schema"
                @nested="(data)=>{form[field.f_name]=data}"
              />
            </div>
          </div>

          <div v-else-if="field.type === 'dict'">
            <div v-if="!field.metadata.hide">
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <DictionaryField
                :id="field.f_name"
                v-model="form[field.f_name]"
                :metadata="field.metadata"
                @nestedDict="(data)=>{form[field.f_name]=data}"
              />
            </div>
          </div>

          <div v-else>
            {{field}}
          </div>

        </div>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :disabled="loading"
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        type="submit"
        color="blue darken-1"
        :loading="loading"
      >
        Create
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import NestedBlocksFields from './nested-blocks-field'
import NestedFields from './nested-fields'
import QuillEitor from '../quillEditor/quillEditor'
import SocialMedia from '../socialMedia/social-media'
import ImageUpload from '../files/imageUpload'
import VideoUpload from '../files/videoUpload'
import SelectField from './select-field'
import DictionaryField from './dictionary-field'

import { makeFieldName } from '~src/assets/js/utils'
import rules from '~src/assets/js/validationRules'
import { mapActions } from 'vuex'

export default {
  name: 'create-fields',
  components: {
    SelectField,
    DictionaryField,
    NestedBlocksFields,
    NestedFields,
    QuillEitor,
    SocialMedia,
    ImageUpload,
    VideoUpload
  },
  props: ['close', 'editableFields', 'url', 'form'],
  data () {
    return {
      loading: false,
      halfWidth: 'half-width-item',
      rules
    }
  },
  created () {
    console.log('created', this.form)
  },
  methods: {
    ...mapActions([
      'POST'
    ]),
    makeFieldName,
    additionalImgStyles: function (img) {
      if (img) return 'order: -1;'
    },
    save: function () {
      console.log(this.$refs.createForm.validate())
      if (this.$refs.createForm.validate()) {
        this.loading = true
        console.log('create', this.form, this.url)
        const data = Object.entries(this.form).reduce((a, [k, v]) => (v ? (a[k] = v, a) : a), {})
        this.POST({ route: this.url, data })
          .then(({ data }) => {
            console.log('create result', data)
            this.$refs.createForm.reset()
            this.$emit('tableUpdated')
            this.close()
          })
          .catch(err => {
            console.log(err)
            this.$emit('modalError', err.response.data.msg)
            this.close()
          })
          .finally(() => {
            this.loading = false
          })
      }
    }
  }
}
</script>

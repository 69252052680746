<template>
  <v-container fluid class="pa-0 sponsor-upcoming-event">
    <PageTitle
      :title="pageTitle"
      class="sponsor-upcoming-event__title"
    >
      <v-btn
        v-if="!isEmpty(eventInfo) && currentEvent === 'past'"
        text
        x-large
        color="primary"
        :loading="isListLoading"
        @click="downloadAttendeeList(eventInfo.id)"
      >
        download attendee list
      </v-btn>
    </PageTitle>
    <v-tabs grow>
      <v-tab>
        analytics
      </v-tab>
      <v-tab>
        resources
      </v-tab>
      <v-tab>
        info for event
      </v-tab>
      <v-tab>
        schedule
      </v-tab>
      <v-tab>
        marketing
      </v-tab>

      <v-tab-item>
        <Analytics
          :attendees-today="attendeesTodayString"
          :sponsors-by-type="sponsorsByType"
          :attendees-by-type="attendeesByType"
          :attendees-comp="attendeesComp"
          :circle-chart-description="circleChartDescription"
          :card-stats-title="cardStatsTitle"
        />
      </v-tab-item>
      <v-tab-item>
        <Resourses />
      </v-tab-item>
      <v-tab-item>
        <InfoForEvent
          :event-type="currentEvent"
        />
      </v-tab-item>
      <v-tab-item>
        <Schedule />
      </v-tab-item>
      <v-tab-item>
        <Marketing />
      </v-tab-item>
    </v-tabs>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmpty } from '~src/assets/js/utils'
import axios from 'axios'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import Analytics from './common/Analytics'
import Resourses from './event/Resourses'
import InfoForEvent from './event/InfoForEvent'
import Schedule from './event/Schedule'
import Marketing from './event/Marketing'

export default {
  name: 'SponsorEvent',
  props: {},
  components: {
    PageTitle,
    Analytics,
    Resourses,
    InfoForEvent,
    Schedule,
    Marketing
  },
  data: () => ({
    eventInfo: {},
    attendeesTodayCount: null,
    sponsorsByType: {},
    attendeesByType: {},
    attendeesComp: {},
    isListLoading: false
  }),
  computed: {
    currentEvent () {
      return this.$route.path.includes('upcoming')
        ? 'upcoming'
        : 'past'
    },
    eventId () {
      return this.$route.params.id
    },
    circleChartDescription () {
      return this.currentEvent === 'upcoming'
        ? ['Expected attendee', 'Current registration']
        : ['Expected attendee', 'Total registration']
    },
    cardStatsTitle () {
      return this.currentEvent === 'upcoming'
        ? 'Attendee today'
        : 'Total attendee'
    },
    attendeesTodayString () {
      let string = ''

      if (Number.isInteger(this.attendeesTodayCount)) {
        string = this.currentEvent === 'upcoming'
          ? `+${this.attendeesTodayCount || '0'}`
          : `${this.attendeesTodayCount || '0'}`
      }

      return string
    },
    pageTitle () {
      return this.eventInfo.name ||
        `${this.currentEvent === 'upcoming' ? 'Next' : 'Last'} event`
    }
  },
  created () {
    Promise.all([
      this.getEventInfo(),
      this.getAttendeesToday(this.eventId),
      this.getAttendeesByType(this.eventId)
    ])
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    isEmpty,
    getEventInfo () {
      this.GET({
        route: this.currentEvent === 'upcoming'
          ? 'admin/sponsor/upcoming_event'
          : `event/${this.$route.params.id}`
      })
        .then(({ data }) => { this.eventInfo = data.data })
    },
    getAttendeesToday (id) {
      this.GET({ route: `admin/event/${id}/attendees_today` })
        .then(({ data }) => { this.attendeesTodayCount = data.data.count })
    },
    getAttendeesByType (id) {
      this.GET({ route: `admin/event/${id}/attendees_sponsors_summary` })
        .then(({ data }) => {
          this.attendeesComp = {
            expected: data.data.event.expected_atendees,
            current: data.data.event.current_attendees
          }
          this.attendeesByType = data.data.attendees_by_type
          this.sponsorsByType = data.data.sponsors_by_type

          // set missing fields to sponsors by type
          if (!this.sponsorsByType.basic) {
            this.$set(this.sponsorsByType, 'basic', this.sponsorsByType.silver + this.sponsorsByType.gold)
          } else if (!this.sponsorsByType.silver) {
            this.$set(this.sponsorsByType, 'silver', 0)
          } else if (!this.sponsorsByType.gold) {
            this.$set(this.sponsorsByType, 'gold', 0)
          }

          // set missing fields to attendees by type
          if (!this.attendeesByType.basic) this.$set(this.attendeesByType, 'basic', 0)
          if (!this.attendeesByType.silver) this.$set(this.attendeesByType, 'silver', 0)
          if (!this.attendeesByType.gold) this.$set(this.attendeesByType, 'gold', 0)
        })
    },
    downloadAttendeeList (id) {
      this.isListLoading = true

      let listDownloadingLink = ''
      this.GET({ route: `admin/event/${id}/attendee_list` })
        .then(({ data }) => {
          listDownloadingLink = data.download_url
          axios.get(listDownloadingLink, { responseType: 'blob' }).then(({ data }) => {
            this.downloadExcel(data, id)
            this.isListLoading = false
          })
        })
    },
    downloadExcel (data, eventId) {
      const blob = new Blob([data], { type: 'application/octet-stream' })
      if (typeof window.navigator.msSaveBlob !== 'undefined') {
        window.navigator.msSaveBlob(blob, `attendee-list-${eventId}.xlsx`)
        return
      }
      const blobURL = window.URL.createObjectURL(blob)
      const tempLink = document.createElement('a')
      tempLink.style.display = 'none'
      tempLink.href = blobURL
      tempLink.setAttribute('download', `attendee-list-${eventId}.xlsx`)
      if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
      }
      document.body.appendChild(tempLink)
      tempLink.click()
      document.body.removeChild(tempLink)
      setTimeout(() => {
        window.URL.revokeObjectURL(blobURL)
      }, 100)
    }
  }
}
</script>

<style lang="scss">
// @import 'path/_variables';
.sponsor-upcoming-event {
  &__title {
    box-shadow: none;
  }

  .v-tabs-bar {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.14);
    margin-bottom: 8px;
  }
}
</style>

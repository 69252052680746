<template>
  <div>
    <PageTitle title="Home"/>
    <v-container class="pa-10">
      <v-alert
        v-if="notification"
        border="right"
        colored-border
        type="error"
        elevation="2"
        icon="mdi-alert-circle-outline"
      >
        {{ notification }}
      </v-alert>
      <Details/>
    </v-container>
  </div>
</template>

<script>
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import Details from './home/Details'

export default {
  name: 'SponsorHome',
  props: {},
  components: {
    PageTitle,
    Details
  },
  data: () => ({
    // should come from BE
    // not sure what type of notification is it
    notification: ''
  }),
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  // @import 'path/_variables';
</style>

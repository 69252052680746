<template>
  <Table
    v-slot="{ editedItem, close }"
    objectName="submissions"
    :data="talks"
    :tableHeader="tableHeader('Talks')"
    :url='`event/${$route.params.id}/submission`'
    :refresh="getEventSubmissions"
    :loading="isSubmissionLoading"
  >
    <SubmissionModal
      :item="editedItem"
      :close="close"
      :tracks="tracks"
      :submissions="talks"
      @tableUpdated="getEventSubmissions"
    />
  </Table>
</template>

<script>
import { mapActions } from 'vuex'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import Table from '~src/components/common/table/Table'
import SubmissionModal from './submissions/submission-create-edit-modal'

export default {
  name: 'SubmissionsAndTalks',
  components: { Table, SubmissionModal },
  props: ['tracks'],
  data () {
    return {
      isSubmissionLoading: false,
      loading: false,
      talks: undefined,
      tableHeaders: jsonHeaders
    }
  },
  created () {
    this.getEventSubmissions()
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    tableHeader (title) {
      return jsonHeaders[title]
    },
    getEventSubmissions: function () {
      this.isSubmissionLoading = true
      this.GET({ route: `/event/${this.$route.params.id}/submission` })
        .then(({ data }) => {
          console.log('getEventSubmissions', data)
          this.talks = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isSubmissionLoading = false
        })
    }
  }
}
</script>

<template>
  <vue-excel-xlsx
    :loading="isAttendeesLoading"
    :data="attendees"
    :columns="columns"
    :file-name="attendeeFileName"
    :sheet-name="attendeeFileName"
    class="mb-2 mt-2 ml-5 v-btn v-btn--contained theme--dark v-size--default primary"
  >
    Download Event Registrations
  </vue-excel-xlsx>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'RegistrationsDownload',
  components: {},
  props: [''],
  data () {
    return {
      isAttendeesLoading: false,
      loading: false,
      attendees: undefined,
      columns: [
        {
          label: 'Name',
          field: 'first_name'
        },
        {
          label: 'Last Name',
          field: 'last_name'
        },
        {
          label: 'Organization',
          field: 'organization'
        },
        {
          label: 'Job Title',
          field: 'job_title'
        },
        {
          label: 'Email',
          field: 'email'
        },
        {
          label: 'Phone',
          field: 'phone'
        },
        {
          label: 'Email opt-in',
          field: 'optin'
        },
        {
          label: 'Code',
          field: 'code'
        },
        {
          label: 'Code organization',
          field: 'code_org'
        }
      ]
    }
  },
  created () {
    this.getEventAttendees()
  },
  computed: {
    attendeeFileName () {
      const fileName = `registrations_${this.$route.params.id}`
      if (fileName >= 31) {
        return 'registrations'
      }
      return fileName
    }
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getEventAttendees: function () {
      this.isAttendeesLoading = true
      this.GET({ route: `/ticketing/event/${this.$route.params.id}/attendee` })
        .then(({ data }) => {
          this.attendees = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isAttendeesLoading = false
        })
    }
  }
}
</script>

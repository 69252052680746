<template>
  <v-card class="pa-5 mb-3">
    <div class="d-flex justify-space-between">
      <h3>Hotspots creation</h3>
      <v-btn
        color="primary"
        class="px-10"
        :loading="loading"
        @click="saveHotspots"
      >
        Save
      </v-btn>
    </div>

    <v-select
      v-model="chosenTemplate"
      :items="data"
      @change="chooseTemplateToShow"
      item-text="link"
      item-value="link"
      label="Template chosen to show in booth"
      outlined
      background-color="teal lighten-4"
      class="mt-4 mb-2"
    />
    <div v-show="emptyChosenTemplate" class="error--text">You need to choose which template will be shown</div>

    <div class="d-flex justify-space-between align-end mb-5">
      <v-tabs v-model="tab" grow>
        <v-tab v-for="(template, index) in data" :key="template.name">
          {{template.name || index + 1}}
        </v-tab>
      </v-tabs>
      <v-dialog v-model="addImgModal">

        <template v-slot:activator="{ on, attrs }">
          <v-btn
            outlined
            color="blue darken-1"
            v-bind="attrs"
            v-on="on"
            class="ml-3"
            :disabled="loading"
          >
            Add new template
          </v-btn>
        </template>

        <v-card class="environment__add-img-modal">
          <v-card-title>
            <span class="headline mb-5">Add custom image</span>
          </v-card-title>
          <v-card-text>
            <div class="black--text mb-4">Please put the booth image here, then you will add spots on it.</div>
            <!--            <div class="environment__text">Our Admin will add image with spots to your list as soon as possible.</div>-->
            <v-text-field
              outlined
              label="Template name"
              v-model="newTemplate.name"
            />
            <div class="environment__text"><i>Note: images with dark background looks better.</i></div>
            <ImageUpload @imageUrl="(data)=>{newTemplate.link=data}" style="width: 300px"/>
            <!--          <div class="environment__text mt-5">Here you can put any special wishes, for example - number of
                        hotspots.
                      </div>
                      <v-textarea
                        outlined
                        label="Comment"
                        value="comment field - quantity of hotspots I need??"
                      ></v-textarea>-->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="addImgModal=false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="blue darken-1"
              :disabled="isEmpty(newTemplate.link)"
              @click="addTemplate"
            >
              Submit
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <v-tabs-items v-model="tab">
      <v-tab-item v-for="(template) in data" :key="template.id">
        <v-row align="baseline">
          <v-col cols="12">
            <div class="environment__text">NB : Templates name should not be repeated.</div>
          </v-col>
          <v-col sm="12" md="6">
            <!-- <div class="mt-5"><b>User’s Comment:</b><p class="mt-2">I want 5 spots</p></div>-->
            <v-text-field
              outlined
              label="Template name"
              v-model="template.name"
            />
          </v-col>
          <v-col sm="12" md="6">
            <v-btn
              outlined
              color="primary"
              :disabled="loading"
              @click="deleteTemplate(template.link)"
              class="mr-5"
            >
              Delete template
            </v-btn>
            <!-- <v-btn outlined color="primary" class="mr-5" > Change image </v-btn>-->
          </v-col>

        </v-row>
        <div v-if="isItHardcodedOldTemplate(template.hotspots)">
          <h3>Sorry, hotspots in old hardcoded templates are not editable </h3>
          <ImageWithSpots :hotspot="template" class="mt-3"/>
        </div>
        <DraggableHotspot
          v-else
          :image="template.link"
          :items="template.hotspots"
          @change="(e)=>editTemplate(e,template)"
        />
      </v-tab-item>
    </v-tabs-items>

  </v-card>
</template>

<script>
import { isEmpty } from '~src/assets/js/utils'
import ImageUpload from '~src/components/common/files/imageUpload'
import ImageWithSpots from '~src/components/common/Environment/ImageWithSpots'
import DraggableHotspot from './DraggableHotspot'

export default {
  name: 'HotspotsModeration',
  props: ['data', 'loading'],
  components: {
    ImageWithSpots,
    DraggableHotspot,
    ImageUpload
  },
  data: () => ({
    tab: null,
    chosenTemplate: null,
    emptyChosenTemplate: false,
    addImgModal: false,
    newTemplate: {
      name: '',
      link: ''
    }
  }),
  created () {
    this.data.map((el) => {
      if (el.selected) this.chosenTemplate = el.link
    })
  },
  methods: {
    isEmpty,
    isItHardcodedOldTemplate (data) {
      if (!data) return false
      return data.find(el => 'right' in el.styles || 'bottom' in el.styles || 'transform' in el.styles)
    },
    saveHotspots () {
      if (!this.chosenTemplate) {
        this.emptyChosenTemplate = true
      } else {
        this.emptyChosenTemplate = false
        this.$emit('save')
      }
    },
    addTemplate () {
      this.$emit('templates', [...this.data, { ...this.newTemplate }])
      this.addImgModal = false
    },
    editTemplate (spots, template) {
      // template editing
      const idx = this.data.findIndex(el => el.link === template.link)
      this.$emit('templates', [...this.data.slice(0, idx), {
        ...template,
        hotspots: spots
      }, ...this.data.slice(idx + 1)])
    },
    deleteTemplate (link) {
      const idx = this.data.findIndex(el => el.link === link)
      this.$emit('templates', [...this.data.slice(0, idx), ...this.data.slice(idx + 1)])
    },
    chooseTemplateToShow (link) {
      this.data.map((el) => {
        el.selected = el.link === link
      })
    }
  }
}
</script>

<style scoped>
</style>

<template>
  <div>
    <v-card elevation="0" class="comment mb-5">
      <div class="px-3 pb-2" @click="showEmoji=false">

        <v-btn
          v-show="comment.author_name === username"
          @click="deleteComment"
          :loading="isCommentDeleting"
          icon
          class="float-right"
        >
          <v-icon small>
            mdi-close
          </v-icon>
        </v-btn>

        <div class="d-flex justify-space-between mb-3">
          <div class="d-flex">
            <v-avatar
              class="mr-3"
              color="grey"
            >
              <img
                v-if="comment.user_profile.image"
                :src="comment.user_profile.image"
                :alt="comment.author_name">
              <span v-else-if="comment.user_profile.first_name" class="white--text headline">{{comment.user_profile.first_name.charAt(0)}}{{comment.user_profile.last_name.charAt(0)}}</span>
              <v-icon v-else dark>
                mdi-account-circle
              </v-icon>
            </v-avatar>

            <div class="comment__title">
        <span class="comment__name">
        {{  comment.user_profile.first_name ? comment.user_profile.first_name + ' ' + comment.user_profile.last_name : comment.author_name}}
        </span>
              <span class="comment__time">
              {{comment.created | moment }}
        </span>
            </div>

          </div>

          <!--          <v-speed-dial v-show="comment.author_name !== username" v-model="fab" direction="left">
                      <template v-slot:activator>
                        <v-btn icon>
                          <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                      </template>
                      <div class="comment__report primary&#45;&#45;text">Report to moderator</div>
                    </v-speed-dial>-->

        </div>

        <div class="comment__reply">
          <span v-show="replyTo" class="primary--text pr-1">@{{replyTo}}</span>
          {{comment.content}}
        </div>
      </div>

      <div class="d-flex justify-space-between">
        <div>
          <v-btn @click="showEmoji=!showEmoji"
                 text
                 small
                 color="primary"
                 style="position: relative"
                 :loading="isEmojiLoading"
          >
            React
          </v-btn>

          <div v-show="showEmoji" class="comment__feelings">
            <Picker
              set="twitter"
              :data="emojiIndex"
              @select="addEmoji"
              :showPreview="false"
            />
          </div>

          <v-btn
            v-show="!replyTo"
            text
            small
            color="primary"
            @click="showReplyInput = !showReplyInput"
          >
            Reply
          </v-btn>
        </div>

        <div v-if="comment.reactions" class="comment__likes">
          <span
            v-for="like in Object.keys(comment.reactions.reaction_summary)"
            v-bind:key="like"
            class="mr-1"
          >
            {{ like | emojiDecoder }}
            {{ comment.reactions.reaction_summary[like]}}
          </span>
        </div>

      </div>

    </v-card>

    <div class="pl-6">
      <v-expand-transition>
        <div v-show="showReplyInput">
          <v-text-field
            v-model="newCommentReply"
            append-icon="mdi-send"
            @click:append="addReply"
            @keyup.enter.prevent="addReply"
            label="Add reply"
            outlined
            class="mb-3"
            :loading="isNewCommentReplyLoading"
          />
        </div>
      </v-expand-transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import data from 'emoji-mart-vue-fast/data/all.json'
import { Picker, EmojiIndex } from 'emoji-mart-vue-fast'
import 'emoji-mart-vue-fast/css/emoji-mart.css'

const emojiIndex = new EmojiIndex(data)

export default {
  name: 'Comment',
  props: ['entityId', 'comment', 'replyTo'],
  components: { Picker },
  data () {
    return {
      emojiIndex: emojiIndex,
      showEmoji: false,
      isEmojiLoading: false,
      showReplyInput: false,
      isNewCommentReplyLoading: false,
      newCommentReply: '',
      isCommentDeleting: false,
      fab: false
    }
  },
  computed: {
    ...mapGetters({
      username: 'getCognitoUsername'
    }),
    postId () {
      return this.$route.params.id
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).format('MM-DD-YYYY h:mm:a')
    },
    emojiDecoder (str) {
      const like = '&#x' + str + ';'
      const textArea = document.createElement('textarea')
      textArea.innerHTML = like
      return textArea.value
    }
  },
  methods: {
    ...mapActions([
      'GET', 'POST', 'DELETE'
    ]),
    deleteEmoji (emoji) {
      this.DELETE({
        route: `/user_reaction/${this.comment.id}/${emoji}`
      }).then(() => {
        this.$emit('updateComment')
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.isEmojiLoading = false
      })
    },
    addEmoji (emoji) {
      console.log('emoji', emoji)
      this.showEmoji = false
      this.isEmojiLoading = true
      this.POST({
        route: `/user_reaction/${this.comment.id}/${emoji.unified}`
      }).then((res) => {
        console.log('res', res)
        this.$emit('updateComment')
        this.isEmojiLoading = false
      }).catch((error) => {
        console.log(error)
        if (error.response && error.response.status === 422 && error.response.data.errors.includes('Reaction for user already exists. Reaction not added')) {
          this.deleteEmoji(emoji.unified)
        }
      })
    },
    deleteComment () {
      this.isCommentDeleting = true
      this.DELETE({
        route: `/comment/${this.entityId}/${this.comment.id}`
      }).then((res) => {
        console.log(res)
        this.$emit('updateComment')
      }).catch((err) => {
        console.log(err)
      }).finally(() => {
        this.isCommentDeleting = false
      })
    },
    addReply () {
      if (this.newCommentReply) {
        this.isNewCommentReplyLoading = true
        const data = {
          reply_to: this.comment.id,
          parent: this.entityId,
          content: this.newCommentReply
        }
        console.log(data)
        this.POST({
          route: `/comment/${this.comment.id}`,
          data: data
        }).then((res) => {
          console.log(res)
          this.$emit('updateComment')
          this.newCommentReply = ''
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          this.isNewCommentReplyLoading = false
        })
      }
    }
  }
}
</script>

<style lang="scss">
  .comment {
    &__title {
      font-size: 14px;
      line-height: 24px;
      display: flex;
      flex-direction: column;
    }

    &__name {
      color: #2B343E;
    }

    &__time {
      color: #4A5560;
    }

    &__feelings {
      z-index: 2;
      position: absolute;
      bottom: 28px;
      left: -2px;

      section:first-of-type {
        max-height: 200px;
      }
    }

    &__likes {
      font-size: 13px;
    }

    &__reply {
      display: inline;
    }

    &__report {
      font-size: 14px;
      line-height: 16px;
      padding: 15px 20px;
      border: 1px solid #E9EFF3;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
      border-radius: 2px;
      white-space: nowrap;
    }
  }

</style>

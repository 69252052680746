<template>
  <div>
    <PageTitle title="Bios"/>
    <v-container class="pa-10 pr-15">
      <Preloader v-if="loading"/>
      <NoItems v-show="noBios">
        You don’t create any bio yet.
        <br/>
        Let’s fix it!
        <br/>
        <v-dialog
          v-model="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mt-3 pa-6"
              v-bind="attrs"
              v-on="on"
            >
              Create new bio
            </v-btn>
          </template>
          <CreateEditModal
            formTitle="New Bio"
            :url="`/admin/p/profile/pid/${this.userInfo.id}/e/bio`"
            :fields="biosFields"
            :close="closeModal"
            @tableUpdated="getBios"
            style="width:500px"
          />
        </v-dialog>
      </NoItems>
      <Table
        v-if="bios"
        objectName="bios"
        :data="bios"
        :fields="biosFields"
        :tableHeader="tableHeader('Bios')"
        :url='`/admin/p/profile/pid/${this.userInfo.id}/e/bio`'
        :refresh="getBios"
        :allowCreation=true
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PageTitle from '../common/pageTitle/pageTitle'
import Preloader from '../common/preloader/Preloader'
import CreateEditModal from '~src/components/common/createEditModal/create-edit-modal'
import Table from '~src/components/common/table/Table'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import NoItems from '../common/noItems/noItems'

export default {
  name: 'Bios',
  components: {
    NoItems,
    PageTitle,
    Preloader,
    Table,
    CreateEditModal
  },
  props: {},

  data () {
    return {
      loading: false,
      dialog: false,
      bios: null,
      noBios: false,
      biosFields: null,
      tableHeaders: jsonHeaders
    }
  },
  created () {
    this.getBios()
    this.getBiosFields()
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    })
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    tableHeader (title) {
      return jsonHeaders[title]
    },
    closeModal () {
      this.dialog = false
    },
    getBios: function () {
      this.loading = true
      this.noBios = false
      this.GET({ route: `/admin/p/profile/pid/${this.userInfo.id}/e/bio` })
      // this.GET({ route: `/admin/p/profile/pid/${this.userInfo.id}/e/bio` })
      // this.GET({ route: `/bio/${this.userInfo.id}` })
        .then(({ data }) => {
          console.log('bios', data)
          data.data ? this.bios = data.data : this.noBios = true
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    getBiosFields: function () {
      this.GET({ route: '/admin/fields/bio' })
        .then(({ data }) => {
          console.log(data)
          this.biosFields = data.data
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss">

</style>

<template>
  <v-card class="pa-4 card-template">
    <v-row justify="space-between" align="center">
      <v-col sm="8">
        <h3 class="card-template__title">
          {{ title }}
        </h3>
      </v-col>
      <v-col sm="4">
        <v-btn
          v-if="buttonText"
          color="primary"
          :text="isBackgroundTransparentButton"
          :outlined="isOutlinedButton"
          class="card-template__button"
          @click="$emit('card-btn-clicked', $event)"
        >
          {{ buttonText }}
        </v-btn>
      </v-col>
    </v-row>
    <slot />
  </v-card>
</template>

<script>
export default {
  name: 'CardTemplate',
  props: {
    title: {
      type: String,
      default: ''
    },
    buttonText: {
      type: String,
      default: ''
    },
    isBackgroundTransparentButton: {
      type: Boolean,
      default: false
    },
    isOutlinedButton: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
// @import 'path/_variables';
.card-template {
  &__title {
    text-transform: capitalize;
  }

  &__button {
    float: right;
  }
}
</style>

<template>
  <div class="mt-2 d-flex flex-column">
    <v-avatar
      :style="`position: relative; ${image ? 'width: 100%; height: 80%;' : 'width: 150px; height: 150px;'} `"
      class="profile"
      color="grey"
      tile
    >
<!--      :size="width ? width: 214"-->
      <v-btn v-show="image && !isUploading" icon @click="removeImgUrl" class="close">
        <v-icon color="black">
          mdi-close
        </v-icon>
      </v-btn>

      <v-img
        v-if="image"
        :src="image"
        :lazy-src="image"
        aspect-ratio="1"
        class="grey lighten-2"
        alt="Image"
      >
        <template v-slot:placeholder>
          <v-row
            class="fill-height ma-0"
            align="center"
            justify="center"
          >
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>

      <v-icon v-else dark x-large>
        mdi-image
      </v-icon>

    </v-avatar>
<!--    accept=".png, .jpg, .jpeg"-->
    <v-file-input
      accept="image/*"
      placeholder="Choose an image file"
      :loading="isUploading"
      :disabled="isUploading"
      v-model="file"
      @change="uploadImage($event)"
      @click:clear="removeImgUrl"
    />

  </div>
</template>

<script>

import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'imageUpload',
  props: ['url', 'width', 'workflow'],
  components: {},
  data () {
    return {
      isUploading: false,
      uploadingData: null,
      file: null,
      image: null,
      base64: '',
      result: null,
      autogeneratedFileName: null,
      error: null
    }
  },
  created () {
    if (this.url) this.image = this.url
  },
  watch: {
    result: {
      handler (result) {
        this.$emit('imageUrl', result)
      },
      deep: true
    },
    url: {
      handler (result) {
        if (result) this.image = result
      }
    }
  },
  computed: {
    ...mapGetters({
      baseUrl: 'currentBaseUrl'
    })
  },
  methods: {
    ...mapActions([
      'GET', 'POST'
    ]),
    uploadImage: function (event) {
      if (event) {
        // part to display on front
        this.showImages(event)
        this.getBase64(event).then(
          data => this.base64 = data
        )
        // part to upload to back
        this.isUploading = true
        this.upload(event)
      }
    },
    getBase64: function (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.split('base64,')[1])
        reader.onerror = error => reject(error)
      })
    },
    showImages: function (file) {
      const blobImg = URL.createObjectURL(file)
      this.removeImgUrl()
      this.image = blobImg
      this.file = file
    },
    removeImgUrl: function () {
      this.image = null
      this.file = null
      this.error = null
      this.result = null
    },
    upload (file) {
      this.uploadingData = new FormData()
      this.POST({
        route: '/file/',
        data: {
          file_name: file.name,
          workflows: this.workflow || ['image_compress'],
          content_type: 'application/json'
        }
      }).then(({ data }) => {
        this.result = process.env.VUE_APP_FILE_UPLOAD_BASE_URL + '/' + data.data.fields.key
        Object.keys(data.data.fields).forEach(key => {
          this.uploadingData.append(key, data.data.fields[key])
        })
        this.uploadingData.append('file', file)
        axios({
          method: 'post',
          url: data.data.url,
          data: this.uploadingData
        }).then((res) => {
          if (res.status !== 204) this.result = null
          this.isUploading = false
        }).then(() => {
          this.GET(
            {
              route: `/file/${data.data.id}/run_workflows`
            }
          )
            .then(() => {
              console.log('workflows run')
              console.log(data)
            })
            .catch((e) => {
              console.log({ ERROR: e })
            })
        })
      })
    }
  }
}
</script>

<style scoped>
  .close {
    position: absolute;
    top: 0;
    right: 1%;
    z-index: 2
  }
</style>

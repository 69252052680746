import axios from 'axios'
import Vue from 'vue'

const baseUrl = process.env.VUE_APP_BASE_URL

const createInstance = baseURL => {
  return axios.create({
    baseURL,
    headers: {
      'Content-Type': 'application/json'
    }
  })
}

const axiosInstance = createInstance(baseUrl)

export default {
  install () {
    Vue.prototype.$axios = axiosInstance
  }
}

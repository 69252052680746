<template>
  <div>
    <PageTitle title="Profile"/>

    <v-container class="pa-8 pr-10">
<!--      <v-row>-->
<!--        <v-col sm="12" md="6">-->
          <CardTemplate title="General info" style="width: 60%">
            <v-form
              ref="form"
              @submit.prevent="saveProfile"
            >
              <v-row>
                <v-col sm="12" md="8" lg="6">
                  <ImageUpload v-model="form.image" :url="form.image" @imageUrl="(data)=>{form.image=data}" style="width: 150px"/>
                </v-col>
                <v-col sm="12" md="8" lg="6">
                  <v-text-field
                    v-model="form.first_name"
                    label="First name"
                    outlined
                    :rules="rules.required"
                  />
                  <v-text-field
                    v-model="form.last_name"
                    label="Last name"
                    outlined
                    :rules="rules.required"
                  />
                </v-col>
                <v-col sm="12" md="6">
                  <v-text-field
                    v-model="form.display_name"
                    label="Nickname"
                    outlined
                    readonly
                    :rules="rules.required"
                  />
                  <v-text-field
                    v-model="form.phone"
                    label="Phone"
                    outlined
                    :rules="rules.phone"
                  />
                </v-col>
                <v-col sm="12" md="6">
                  <v-text-field
                    v-model="form.organization"
                    label="Company"
                    outlined
                  />
                  <v-text-field
                    v-model="form.job_title"
                    label="Position"
                    outlined
                  />
                </v-col>
                <v-col>
                  <SocialMedia
                    :value="form.social_media"
                    :v-model="form.social_media"
                    @social="(data)=>{form.social_media=data}"
                  />
                </v-col>
              </v-row>
              <v-btn
                type="submit"
                color="primary"
                text
                :loading="isFormLoading"

              >
                Save
              </v-btn>
            </v-form>
          </CardTemplate>
<!--        </v-col>-->
  <!--      <v-col sm="12" md="6">
          <CardTemplate title="Change password">
            <v-form
              ref="passwordForm"
              v-model="isPasswValid"
              @submit.prevent="saveProfile"
            >
              &lt;!&ndash;            <v-text-field&ndash;&gt;
              &lt;!&ndash;              v-model="form.email"&ndash;&gt;
              &lt;!&ndash;              label="Email"&ndash;&gt;
              &lt;!&ndash;              outlined&ndash;&gt;
              &lt;!&ndash;              class="mt-3"&ndash;&gt;
              &lt;!&ndash;              :rules="[rules.required, rules.email]"&ndash;&gt;
              &lt;!&ndash;            />&ndash;&gt;
              <v-text-field
                v-model="passwordForm.password"
                label="Old password"
                outlined
                class="mt-3"
                :append-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.min]"
                :type="showPassw ? 'text' : 'password'"
                @click:append="showPassw = !showPassw"
              ></v-text-field>
              <v-text-field
                v-model="passwordForm.new_password"
                label="New password"
                outlined
                :append-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.password]"
                :type="showPassw ? 'text' : 'password'"
                @click:append="showPassw = !showPassw"
              ></v-text-field>
              <v-text-field
                v-model="passwordForm.confirm_password"
                label="Confirm new password"
                outlined
                :append-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="[rules.required, rules.password, (form.password === form.confirm_password) || 'Passwords must match']"
                :type="showPassw ? 'text' : 'password'"
                class="mb-3"
                @click:append="showPassw = !showPassw"
              ></v-text-field>
              <v-btn
                type="submit"
                color="primary"
                text
                :loading="isPasswLoading"
                :disabled="!isPasswValid"
              >
                Save
              </v-btn>
            </v-form>
          </CardTemplate>

        </v-col>-->
<!--      </v-row>-->

    </v-container>
  </div>
</template>

<script>
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import CardTemplate from '~src/components/common/Cards/CardTemplate'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import rules from '~src/assets/js/validationRules'
import ImageUpload from '../common/files/imageUpload'
import SocialMedia from '../common/socialMedia/social-media'

export default {
  name: 'UserProfile',
  props: {},
  components: {
    SocialMedia,
    ImageUpload,
    PageTitle,
    CardTemplate
  },
  data: () => ({
    form: {
      first_name: '',
      last_name: '',
      display_name: '',
      image: '',
      job_title: '',
      organization: '',
      email: '',
      phone: '',
      social_media: []
    },
    // form: {
    //   image: this.userInfo.image,
    //   first_name: this.userInfo.first_name,
    //   last_name: this.userInfo.last_name,
    //   display_name: this.userInfo.display_name,
    //   email: this.userInfo.email,
    //   organization: this.userInfo.organization,
    //   job_title: this.userInfo.job_title,
    //   phone: this.userInfo.phone,
    //   social_media: this.userInfo.social_media
    // },
    // valid: true,
    isFormLoading: false,
    // passwordForm: {
    //   password: '',
    //   new_password: '',
    //   confirm_password: ''
    // },
    // isPasswValid: false,
    // isPasswLoading: false,
    // showPassw: false,
    rules
  }),
  created () {
    console.log('userInfo___', this.userInfo)
    this.form = {
      image: this.userInfo.image,
      first_name: this.userInfo.first_name,
      last_name: this.userInfo.last_name,
      display_name: this.userInfo.display_name,
      email: this.userInfo.email,
      organization: this.userInfo.organization,
      job_title: this.userInfo.job_title,
      phone: this.userInfo.phone,
      social_media: this.userInfo.social_media
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    })
  },
  watch: {
    userInfo () {
      console.log('userInfo___', this.userInfo)
      this.form = {
        image: this.userInfo.image,
        first_name: this.userInfo.first_name,
        last_name: this.userInfo.last_name,
        display_name: this.userInfo.display_name,
        email: this.userInfo.email,
        organization: this.userInfo.organization,
        job_title: this.userInfo.job_title,
        phone: this.userInfo.phone,
        social_media: this.userInfo.social_media
      }
    }
  },
  methods: {
    ...mapActions([
      'PUT'
    ]),
    ...mapMutations([
      'setUserInfo'
    ]),
    saveProfile () {
      if (this.$refs.form.validate()) {
        this.isFormLoading = true
        this.form.visible = []
        console.log(this.form)
        this.PUT({
          route: `/user/${this.userInfo.display_name}`,
          data: this.form
        })
          .then(res => {
            console.log(res)
            this.setUserInfo(this.form)
          })
          .catch(err => {
            console.log(err)
          })
          .finally(() => {
            this.isFormLoading = false
          })
      }
    }
    /*    changePassword () {
      this.isPasswLoading = true
      this.$refs.passwordForm.resetValidation()
      this.$refs.passwordForm.validate()

      if (!this.isPasswValid) return
      console.log(this.passwordForm)
      // console.log('PUT', `/admin/p/user/pid/${this.form.display_name}/e/useracl/eid/${this.form.display_name}`)
      // this.PUT({
      //   route: `/admin/p/user/pid/${this.form.display_name}/e/useracl/eid/${this.form.display_name}`,
      //   data: this.form
      // })
      //   .then(({ data }) => {
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //   .finally(() => {
      //     this.isPasswLoading = false
      //   })
    } */
  }
}
</script>

<style lang="scss">
</style>

<template>
  <v-container class="blog">
    <v-row
      v-if="!blogPosts.length"
      justify="center"
      class="pt-3"
    >
      <v-progress-circular indeterminate color="primary" />
    </v-row>
    <template v-else>
      <v-row align="stretch">
        <v-col
          v-for="post in blogPosts"
          :key="post.id"
          sm="12"
          md="6"
          lg="4"
        >
          <CardBlogPost
            :data="post"
            @read-more="$router.push(`/blog/${post.id}`)"
          />
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import CardBlogPost from './CardBlogPost'

export default {
  name: 'BlogPostsList',
  props: {
    blogPosts: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CardBlogPost
  },
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
// @import 'path/_variables';
</style>

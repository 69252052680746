<template>
    <v-btn
      outlined
      color="blue"
      @click="downloadEventToCalendar"
    >
      Add to Calendar
    </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
// eslint-disable-next-line
const cal = ics()

export default {
  name: 'add-to-calendar',
  props: ['event'],
  created () {
    const start = this.convertTime(this.event.start)
    const end = this.convertTime(this.event.end)
    const route = this.baseUrl + '#/' + this.event.id + '/vc'
    cal.addEvent(this.event.name, this.event.description, route, start, end)
  },
  computed: {
    ...mapGetters({
      baseUrl: 'currentBaseUrl'
    })
  },
  methods: {
    downloadEventToCalendar: function () {
      cal.download(this.event.name)
    },
    convertTime: function (time) {
      const utcTime = moment.utc(time).toDate()
      const localTime = moment(utcTime).local().format('YYYY-MM-DDTHH:mm')
      const localTimeFormatted = moment(
        localTime,
        'YYYY-MM-DDTHH:mm'
      ).toDate()
      return moment(localTimeFormatted)
        .locale('en')
        .format('MM/DD/YYYY hh:mm a')
    }
  }
}
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"bar-chart-card pa-5  d-flex align-content-center justify-space-between"},[_c('div',{staticClass:"bar-chart-card__body d-flex flex-column justify-space-between "},[_c('h3',{directives:[{name:"show",rawName:"v-show",value:(_vm.title),expression:"title"}],staticClass:"bar-chart-card__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.description),expression:"description"}],staticClass:"bar-chart-card__description"},_vm._l((_vm.description),function(label,index){return _c('p',{key:("bar-chart-card-" + index),staticClass:"mb-0 bar-chart-card__item"},[_c('span',{staticClass:"bar-chart-card__marker mr-3",style:(("background-color: " + (_vm.chartBackgrounds[index])))}),_vm._v(" "+_vm._s(label)+" ")])}),0),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.extension),expression:"extension"}],staticClass:"bar-chart-card__box"},[_vm._v(" "+_vm._s(_vm.extension)+" ")])])]),_c('div',{},[_c('BarChart',{attrs:{"width":280,"height":260,"chartdata":{
        labels: _vm.description,
        datasets: [
          {
            backgroundColor: _vm.chartBackgrounds,
            data: _vm.data,
          }
        ]
      }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
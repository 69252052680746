<template>
  <div>
    <v-sheet
      tile
      height="54"
      class="d-flex"
    >
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.prev()"
      >
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-select
        v-model="type"
        :items="types"
        dense
        outlined
        hide-details
        class="ma-2"
        label="type"
      ></v-select>
      <v-select
        v-model="mode"
        :items="modes"
        dense
        outlined
        hide-details
        label="event-overlap-mode"
        class="ma-2"
      ></v-select>
      <v-select
        v-model="weekday"
        :items="weekdays"
        dense
        outlined
        hide-details
        label="weekdays"
        class="ma-2"
      ></v-select>
      <v-spacer></v-spacer>
      <v-btn
        icon
        class="ma-2"
        @click="$refs.calendar.next()"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-color="getColor"
        :event-overlap-mode="mode"
        @change="getEvents"
      ></v-calendar>
    </v-sheet>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'Calendar',
  components: {},
  props: ['calendarData'],
  data: () => ({
    type: 'month',
    types: ['month', 'week', 'day'],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [0, 1, 2, 3, 4, 5, 6],
    weekdays: [
      {
        text: 'Mon - Sun',
        value: [1, 2, 3, 4, 5, 6, 0]
      },
      {
        text: 'Sun - Sat',
        value: [0, 1, 2, 3, 4, 5, 6]
      },
      {
        text: 'Mon - Fri',
        value: [1, 2, 3, 4, 5]
      },
      {
        text: 'Mon, Wed, Fri',
        value: [1, 3, 5]
      }
    ],
    value: '',
    events: []
  }),
  created () {
    this.getEvents()
  },
  methods: {
    getColor (event) {
      return event.color
    },
    getEvents () {
      const events = []
      this.calendarData.map((event) => {
        events.push({
          name: event.name,
          start: moment(event.start).format('YYYY-MM-DD hh:mm'),
          end: moment(event.end).format('YYYY-MM-DD hh:mm'),
          color: this.getEventColor(event.type)
        })
      })
      this.events = events
    },
    getEventColor (type) {
      let color
      switch (type) {
        case 'Conference':
          color = 'indigo'
          break
        case 'Meeting':
          color = 'blue'
          break
        case 'Workshop':
          color = 'deep-purple'
          break
        case 'Event':
          color = 'purple'
          break
        case 'Livestream':
          color = 'grey'
          break
        default:
          color = 'grey darken-1'
          break
      }
      return color
    }
  }
}
</script>

<style lang="scss">
</style>

<template>
  <Preloader v-if="loading" class="mt-16"/>
  <div v-else>
    <PageTitle :title="location.name"/>
    <v-container class="pa-10 pr-15 toggle-wrapper">
      <div v-show="!upcomingLoading && !archiveLoading && !events && !pastEvents">
        <no-items>Sorry, no events yet. <br> Let’s create it!</no-items>
      </div>
      <div v-show="!loading && events" class="toggle-btn" @click="calendarView=!calendarView">
        <v-icon>{{toggleIcon}}</v-icon>
      </div>
      <div v-show="!calendarView">
        <Table
          :loading="upcomingLoading"
          id="location-events"
          objectName="upcoming events"
          :data="events"
          :tableHeader="tableHeader('LocationEvents')"
        />
        <Table
          :loading="archiveLoading"
          id="location-past-events"
          objectName="past events"
          :data="pastEvents"
          :tableHeader="tableHeader('LocationEvents')"
        />
      </div>
      <div v-show="calendarView">
        <Calendar v-if="events" :calendarData="events"/>
      </div>
    </v-container>
  </div>
</template>

<script>
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import Table from '~src/components/common/table/Table'
import Preloader from '~src/components/common/preloader/Preloader'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import NoItems from '~src/components/common/noItems/noItems'
import Calendar from './Calendar'
import { mapActions } from 'vuex'

export default {
  name: 'LocationPage',
  components: {
    NoItems,
    PageTitle,
    Calendar,
    Preloader,
    Table
  },
  props: {},

  data () {
    return {
      calendarView: false,
      loading: false,
      upcomingLoading: false,
      archiveLoading: false,
      location: null,
      locations: null,
      tableHeaders: jsonHeaders,
      events: undefined,
      pastEvents: undefined
    }
  },
  computed: {
    toggleIcon () {
      return this.calendarView ? 'mdi-menu' : 'mdi-calendar'
    },
    locationId () {
      return this.$router.currentRoute.params.id
    }
  },
  created () {
    this.loading = true
    this.getLocation()
    this.getLocationEvents()
    this.getLocationPastEvents()
  },

  methods: {
    ...mapActions([
      'GET'
    ]),
    tableHeader (title) {
      return jsonHeaders[title]
    },
    getLocation: function () {
      this.GET({ route: `location/${this.locationId}` })
        .then(({ data }) => {
          this.location = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getLocationEvents: function () {
      this.upcomingLoading = true
      this.GET({ route: `/public/location/${this.locationId}/upcoming_events/` })
        .then(({ data }) => {
          console.log('upcoming_events', data.data)
          this.events = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.upcomingLoading = false
        })
    },
    getLocationPastEvents: function () {
      this.archiveLoading = true
      this.GET({ route: `/public/location/${this.locationId}/past_events/` })
        .then(({ data }) => {
          console.log('past_events', data.data)
          this.pastEvents = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.archiveLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
  .toggle-wrapper {
    position: relative;
  }

  .toggle-btn {
    position: absolute;
    top: 40px;
    right: 15px;
    cursor: pointer;
  }
</style>

<template>
  <div class="video-card">
    <!--    <ImageView url="https://picsum.photos/510/300?random" />-->
    <v-row>
      <v-col cols="8" class="pa-0">
        <v-img
          src="https://picsum.photos/344/600?random"
          aspect-ratio="1"
          class="grey lighten-2 video-card__img"
          alt="Image"
        >
        </v-img>
      </v-col>
      <v-col class="pa-5 d-flex flex-column justify-space-between">
        <div>
          <div class="video-card__duration">00:00:00</div>
          <div class="video-card__title mt-2 mb-1">
            Hacking for fun and profit
          </div>
          <div class="video-card__text">
            Addeline Michell - Devops Engineer
          </div>
          <div class="video-card__text">
            Threat Informant
          </div>
          <div class="my-3">
            <v-chip
              label
              small
              color="#e9f5fd"
              class="mr-2 mb-1"
            >
              one_tag
            </v-chip>
            <v-chip
              label
              small
              color="#e9f5fd"
              class="mr-2 mb-1"
            >
              one_tag
            </v-chip>
            <v-chip
              label
              small
              color="#e9f5fd"
              class="mr-2 mb-1"
            >
              one_tag
            </v-chip>

          </div>
        </div>

        <div class="video-card__footer">
          <div>05/05/2021</div>
          <div>
            <span class="mr-5"><v-icon small class="mr-1">mdi-eye-outline</v-icon>300</span>
            <span class="mr-2"><v-icon small class="mr-1">mdi-comment-multiple-outline</v-icon>300</span>
          </div>
        </div>

      </v-col>
    </v-row>

  </div>
</template>

<script>
// import ImageView from '~src/components/common/files/Image'

export default {
  name: 'CarouselVideoCard',
  props: ['video'],
  components: {
    // ImageView
  },
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style scoped lang="scss">

  .video-card {
    height: 100%;
    border: 1px solid #E9EFF3;

    &__img {
      position: relative;
      max-height: 344px;
    }

    &__duration {
      position: absolute;
      top: 61%;
      left: 57%;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.25px;
      color: #FFFFFF;
      padding: 4px 8px;
      background: rgba(0, 0, 0, 0.5);
    }

    &__title {
      font-size: 24px;
      line-height: 32px;
      color: #2B343E;
    }

    &__text {
      font-size: 14px;
      line-height: 24px;
      color: #4A5560;
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.25px;
      color: #4A5560;
    }
  }

</style>

<template>
  <div>
    <Preloader
      v-if="arePostsLoading"
      :size="50"
      :width="3"
      class="mt-4"
    />
    <v-row v-else>
      <v-col
        v-for="post in blogPosts"
        :key="post.id"
        sm="12"
        md="6"
        lg="4"
      >
        <CardBlogPost
          :data="post"
          @read-more="$router.push(`/blog/${post.id}`)"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmpty } from '~src/assets/js/utils'
import CardBlogPost from '../../GeneralPages/blog/CardBlogPost'
import Preloader from '~src/components/common/preloader/Preloader'

export default {
  name: 'BlogPostsList',
  props: {},
  components: {
    CardBlogPost,
    Preloader
  },
  created () {
    this.arePostsLoading = true

    this.getBlogPosts()
      .then(posts => {
        this.blogPosts = this.getRandomPosts(posts)
      })
  },
  data: () => ({
    blogPosts: [],
    arePostsLoading: false
  }),
  computed: {},
  methods: {
    ...mapActions([
      'GET'
    ]),
    isEmpty,
    getBlogPosts () {
      return this.GET({ route: '/blogpost' })
        .then(({ data }) => data.data)
        .finally(() => { this.arePostsLoading = false })
    },
    getRandomPosts (postsArr) {
      const max = postsArr.length - 1
      const randomIndexes = [this.getRandomNum(max), this.getRandomNum(max), this.getRandomNum(max)]
      const indexesChecked = this.alterRandomNums(randomIndexes, max)

      return postsArr.filter((cur, index) => indexesChecked.some(item => item === index))
    },
    alterRandomNums (numsArr, max) {
      return numsArr.reduce((acc, cur, index) => {
        if (acc.some(item => item === cur)) {
          return [...acc, this.getRandomNum(max)]
        }

        return [...acc, cur]
      }, [])
    },
    getRandomNum (max) {
      return Math.round(Math.random() * max)
    }
  }
}
</script>

<style lang="scss">
// @import 'path/_variables';
</style>

<template>
  <div class="d-flex justify-center align-center loader-wrapper">
    <v-progress-circular
      :size="size"
      :width="width"
      :color="color"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>

export default {
  name: 'Preloader',
  props: {
    size: {
      type: Number,
      default: 70
    },
    width: {
      type: Number,
      default: 7
    },
    color: {
      type: String,
      default: 'primary'
    }
  },

  data: () => ({})

}
</script>

<style lang="scss">

  .loader-wrapper {
    width: 100%;
    height: 100%;
  }

</style>

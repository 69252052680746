<template>
  <Table
    :loading="loading"
    objectName="Ticket Types"
    :data="ticketTypes"
    :tableHeader="headers"
    :fields="ticketTypesFields"
    :url='`/ticketing/event/${$route.params.id}/ticket_type`'
    :refresh="getTicketTypes"
    :allowCreation=true
  />
</template>

<script>
import { mapActions } from 'vuex'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import Table from '~src/components/common/table/Table'

export default {
  name: 'TicketTypes',
  components: { Table },
  props: [],
  data () {
    return {
      loading: false,
      headers: jsonHeaders.TicketTypes,
      ticketTypes: undefined,
      ticketTypesFields: null
    }
  },
  created () {
    this.getTicketTypes()
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getTicketTypes: function () {
      this.loading = true
      this.GET({ route: `/ticketing/event/${this.$route.params.id}/ticket_type` })
        .then(({ data }) => {
          this.ticketTypes = data.data
          this.getTicketTypesFields()
        })
        .catch(err => {
          console.log(err)
        })
    },
    getTicketTypesFields: function () {
      this.GET({ route: '/fields/ticket_type' })
        .then(({ data }) => {
          this.ticketTypesFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<template>
  <Preloader v-if="loading" class="mt-16"/>
  <div v-else>
    <PageTitle :title="workshop.title"/>
    <v-container class="pa-10 pr-15">

      <div class="workshop">
        <div><span>Dates :</span> {{workshop.start | moment}} - {{workshop.end | moment}}</div>
        <div><span>Registered :</span> {{workshop.registered}} / {{workshop.quantity}}</div>
        <div><span>Description :</span>
          <p v-html="workshop.description"/></div>
        <div><span>Instructor{{workshop.instructor.length > 1 ? 's' : null}} :</span></div>
        <div v-for="(instructor, index) in workshop.instructor" :key="instructor.instructor + index" class="d-flex ml-0 mr-0">
          <ImageView :url="instructor.bio_image" class="instructor-img"/>
          <div>
            <b>{{instructor.instructor}}</b>
            {{instructor.job_title}}
            <i>{{instructor.organization}}</i>
          </div>
          <div v-html="instructor.bio"/>
        </div>
      </div>

      <Table
        :loading="loadingParticipants"
        objectName="participants"
        :tableHeader="headers"
        :data="participants"
        :fields="participantsFields"
        :url='`/workshop/${$router.currentRoute.params.id}/participant`'
        :refresh="getWorkshopParticipants"
      />

    </v-container>
  </div>
</template>

<script>
import Preloader from '~src/components/common/preloader/Preloader'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import Table from '~src/components/common/table/Table'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import { mapActions } from 'vuex'
import moment from 'moment'
import ImageView from '../common/files/Image'

export default {
  name: 'WorkshopPage',
  components: {
    ImageView,
    PageTitle,
    Preloader,
    Table
  },
  props: {},

  data () {
    return {
      headers: jsonHeaders.WorkshopParticipants,
      loading: false,
      loadingParticipants: false,
      workshop: undefined,
      participants: undefined,
      participantsFields: null
    }
  },
  computed: {},
  created () {
    this.getWorkshop()
    this.getWorkshopParticipants()
  },
  filters: {
    moment: function (date) {
      return moment.parseZone(date).format('MMMM Do YYYY, h:mm a')
    }
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getWorkshop: function () {
      this.loading = true
      this.GET({ route: `workshop/${this.$router.currentRoute.params.id}` })
        .then(({ data }) => {
          this.workshop = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getWorkshopParticipants: function () {
      this.loadingParticipants = true
      this.GET({ route: `workshop/${this.$router.currentRoute.params.id}/participant` })
        .then(({ data }) => {
          this.participants = data.data
          this.getWorkshopParticipantsFields()
        })
        .catch(err => {
          console.log(err)
        })
    },
    getWorkshopParticipantsFields: function () {
      this.GET({ route: '/fields/participant/' })
        .then(({ data }) => {
          this.participantsFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loadingParticipants = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
  .workshop {
    div {
      font-size: 20px;
      margin: 15px;

      span {
        font-weight: 500;
      }
    }
  }

  .instructor-img {
    min-width: 200px;
    width: 200px;
    height: 200px;
  }
</style>

<template>
  <div class="profile">
    <PageTitle title="Company profile">
      <v-btn
        v-if="companyProfileRaw.was_viewed"
        color="primary"
        text
        @click="saveProfile"
      >
        save
      </v-btn>
    </PageTitle>
    <v-container class="pa-8 pr-10">
      <v-alert
        :value="isUpdateSucces || isUpdateError"
        text
        :type="isUpdateSucces ? 'success' : 'error'"
      >
        {{ isUpdateSucces ? updateSuccessText : updateErrorText }}
      </v-alert>
    <v-card class="profile__filling">
      <v-card-text>
        Data filling for {{ fillingPercentage }} percents
      </v-card-text>
      <v-container>
        <v-row
          no-gutters
          justify="space-between"
          align="center"
        >
          <v-col sm="11">
            <v-progress-linear :value="fillingPercentage"></v-progress-linear>
          </v-col>
          <v-col sm="1" class="profile__percents">
            {{ fillingPercentage }}%
          </v-col>
        </v-row>
      </v-container>
    </v-card>
    <v-row>
      <v-col sm="12" md="8">
        <CardTemplate title="Basic Information" class="mb-6">
          <v-text-field
            v-model="form.companyName"
            label="Name"
            outlined
            :loading="isProfileLoading"
          />
          <v-textarea
            v-model="form.description"
            outlined
            label="Description"
            :loading="isProfileLoading"
          />
          <v-textarea
            v-model="form.summary"
            outlined
            label="Summary"
            :loading="isProfileLoading"
          />
        </CardTemplate>
        <CardTemplate title="Contact info" class="mb-6">
          <div class="main-grey-header my-4">Website</div>
          <v-text-field
            v-model="form.website"
            placeholder="Add website link"
            outlined
            :loading="isProfileLoading"
          />
<!--          <v-card-text class="py-2">Social media</v-card-text>-->
          <SocialMedia v-model="testSocial" @social="(data)=>{testSocial=data}" />
<!--          <template v-if="!isEmpty(socialMediaReady)">-->
<!--          <template v-for="(link, index) in socialMediaReady">-->
<!--            <v-row :key="index" no-gutters align="baseline">-->
<!--              <v-col :sm="index === socialMediaReady.length - 1 ? '10' : '12'">-->
<!--                <v-text-field-->
<!--                  v-model="form.socialMedia[index]"-->
<!--                  placeholder="Add social media link"-->
<!--                  outlined-->
<!--                  :loading="isProfileLoading"-->
<!--                />-->
<!--              </v-col>-->
<!--              <v-col v-if="index === socialMediaReady.length - 1" sm="2">-->
<!--                <v-btn text>-->
<!--                  <v-icon>-->
<!--                    mdi-plus-->
<!--                  </v-icon>-->
<!--                </v-btn>-->
<!--              </v-col>-->
<!--            </v-row>-->
<!--          </template>-->
<!--          </template>-->
        </CardTemplate>
      </v-col>
      <v-col sm="12" md="4">
        <CardTemplate title="Logo" class="mb-6">
          <ImageUpload v-model="form.image" @imageUrl="(data)=>{form.image = data}"/>
<!--          <FileUpload url="https://hm-conf-web-prd.s3-us-west-2.amazonaws.com/videos/alaska/Alaska+Communications+-+Achieve+More.mp4"/>-->
        </CardTemplate>
        <CardTemplate title="Resources">
          <v-text-field
            v-model="form.resources"
            placeholder="Add resources"
            outlined
            :loading="isProfileLoading"
          />
        </CardTemplate>
      </v-col>
    </v-row>

    <Environment/>

    </v-container>
    <div v-if="!companyProfileRaw.was_viewed" class="profile__footer">
      <v-container>
        <v-row align="center">
          <v-col sm="8">
            This is data from your home page. Is data correct?
          </v-col>
          <v-col sm="4">
            <v-btn
              text
              color="#fff"
              class="profile__first-save"
              @click="saveProfile('firstSave')"
            >
              save
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { isEmpty } from '~src/assets/js/utils'
import config from '~public/config/config.json'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import CardTemplate from '~src/components/common/Cards/CardTemplate'
import ImageUpload from '~src/components/common/files/imageUpload'
import Environment from '~src/components/common/Environment/Environment'
// import FileUpload from '~src/components/common/files/fileUpload'
import SocialMedia from '~src/components/common/socialMedia/social-media'

export default {
  name: 'CompanyProfile',
  props: {},
  components: {
    SocialMedia,
    // FileUpload,
    Environment,
    ImageUpload,
    PageTitle,
    CardTemplate
  },
  data: () => ({
    config,
    form: {
      name: '',
      description: '',
      summary: '',
      image: '',
      website: '',
      socialMedia: [],
      resources: ''
    },
    eventId: '',
    testSocial: [{ id: 'ayehanian', provider: 'linkedin' }],
    companyProfileRaw: {},
    isProfileLoading: false,
    isSponsorLoading: false,
    sponsorObj: {},
    isUpdateError: false,
    updateErrorText: '',
    isUpdateSucces: false,
    updateSuccessText: '',
    notificationTimeout: null
  }),
  computed: {
    ...mapGetters({
      // sponsorInfo: 'getSponsorInfo',
      baseUrl: 'currentBaseUrl'
    }),
    fillingPercentage () {
      const formArr = Object.entries(this.form)
      const full = formArr.length
      const filled = formArr.reduce((acc, [key, value]) => {
        if (value && !isEmpty(value)) acc++
        return acc
      }, 0)

      return (filled / full) * 100
    }
    // socialMediaReady () {
    //   return [...this.form.socialMedia.filter(link => link), '']
    // }

  },
  mounted () {
    this.getSponsor()
    this.getCompanyProfile()

    // this.getSponsor().then(() => {
    //   this.getCompanyProfile()
    // })
  },
  beforeDestroy () {
    clearTimeout(this.notificationTimeout)
  },
  watch: {
    isUpdateError (value) {
      if (value) {
        this.notificationTimeout = setTimeout(() => {
          this.clearNotifications()
        }, 5000)
      }
    },
    isUpdateSucces (value) {
      if (value) {
        this.notificationTimeout = setTimeout(() => {
          this.clearNotifications()
        }, 5000)
      }
    }
  },
  methods: {
    ...mapActions([
      'GET',
      'PUT'
    ]),
    isEmpty,
    getSponsor () {
      this.isSponsorLoading = true

      return this.GET({ route: 'admin/whoami' })
        .then(({ data }) => {
          console.log('whoami RESPONSE:', data)
          this.sponsorObj = data.data.sponsor[0]
          this.form.companyName = this.sponsorObj.title
        })
        .finally(() => { this.isSponsorLoading = false })
    },
    getCompanyProfile () {
      this.isProfileLoading = true

      this.GET({
        route: `admin/p/event/pid/${this.sponsorObj.event}/e/sponsor/eid/${this.sponsorObj.id}`
      }).then(({ data }) => {
        console.log('Company Profile RESPONSE:', data)
        this.companyProfileRaw = data.data
        this.form = {
          companyName: this.sponsorObj.title,
          description: data.data.description,
          summary: data.data.summary,
          livestream: '',
          image: data.data.logo,
          website: data.data.website,
          socialMedia: data.data.social_media.map(cur => this.makeSocialLink(cur)),
          resources: data.data.resources
        }
      }).finally(() => { this.isProfileLoading = false })
    },
    saveProfile (modifyer) {
      const formData = { ...this.form }
      delete formData.companyName
      delete formData.livestream
      delete formData.logoObj
      delete formData.socialMedia

      const newProfile = {
        ...this.companyProfileRaw,
        ...formData,
        socialMedia: this.form.socialMedia.map(cur => this.parseSocialLink(cur)),
        was_viewed: modifyer === 'fistSave' ? true : this.companyProfileRaw.was_viewed
      }

      this.PUT({
        route: `admin/p/event/pid/${this.sponsorObj.event}/e/sponsor/eid/${this.sponsorObj.id}`,
        data: newProfile
      }).then(() => {
        this.isUpdateSucces = true
        this.updateSuccessText = 'Company profile updates successfully'
      }).catch(() => {
        this.isUpdateError = true
        this.updateErrorText = 'An error occurred. Please try again later or contact our support at ' + this.config.helpEmail
      })
    },
    clearNotifications () {
      this.isUpdateSucces = false
      this.updateSuccessText = ''
      this.isUpdateError = false
      this.updateErrorText = ''
    },
    social (data) {
      console.log('social', data)
    }
    // makeSocialLink (item) {
    //   let link = ''
    //
    //   if (item.provider.includes('linkedin')) {
    //     if (item.provider === 'linkedin_company') {
    //       link = 'https://linkedin.com/company/' + item.id
    //     }
    //     if (item.provider === 'linkedin') {
    //       link = 'https://linkedin.com/in/' + item.id
    //     }
    //   } else {
    //     link = 'https://' + item.provider + '.com/' + item.id
    //   }
    //   if (item.provider.includes('www')) {
    //     link = item.id
    //   }
    //   return link
    // },
    // parseSocialLink (item) {
    //   const linkObj = {}
    //   const id = item
    //   linkObj.id = id.split('com/')[1]
    //   if (id.includes('htt')) {
    //     linkObj.provider = id.split('//')[1].split('.com')[0]
    //   } else {
    //     linkObj.provider = id.split('.com')[0]
    //   }
    //   if (id.includes('linkedin')) {
    //     if (id.includes('company')) {
    //       linkObj.id = id.split('company/')[1].split('/')[0]
    //       linkObj.provider = 'linkedin_company'
    //     } else {
    //       linkObj.id = id.split('in/')[1].split('/')[0]
    //       linkObj.provider = 'linkedin'
    //     }
    //   }
    //   if (id.includes('www')) {
    //     linkObj.id = id
    //   }
    //
    //   return {
    //     ...linkObj,
    //     valid: true
    //   }
    // }
  }
}
</script>

<style scoped lang="scss">
// @import 'path/_variables';
.profile {
  &__percents {
    flex-grow: 0;
    min-width: max-content;
    text-align: right;
    color: #2196F3;
  }

  &__footer {
    position: fixed;
    bottom: 0;
    left: 256px;
    right: 0;
    height: 72px;
    display: flex;
    align-items: center;
    background-color: #2196F3;
    color: #fff;
  }

  &__first-save {
    float: right;
  }

}
</style>

<template>
  <v-card class="wrapper">
    <div v-if="spot.resource_type === 'video'">
      <Video :video="spot.url"/>
    </div>
<!--    <v-card-text v-else-if="spot.resource_type === 'resources'">
      resources
      &lt;!&ndash;              <Resources :resources="spot.resources"/>&ndash;&gt;
    </v-card-text>
    <v-card-text v-else-if="spot.resource_type === 'drawing'">
      <Drawing
        v-for="drawing in drawings"
        :key="drawing.id"
        :data="drawing"
        class="mb-6"
      />
    </v-card-text>-->
    <div v-else class="headline pa-10">
        This spot is empty. Please add something by clicking on the list
        <v-icon x-large class="d-flex my-3 info--text text--darken-2">mdi-chevron-triple-right</v-icon>
    </div>

  </v-card>
</template>

<script>

import Video from './video'
// import Drawing from '../drawing/Drawing'
// import Resources from '~components/authed/global/resources'

export default {
  name: 'hotspot-modal',
  components: {
    // Drawing,
    Video
    // Resources
  },
  props: ['spot'],
  data () {
    return {
      dialog: false
    }
  },
  watch: {},
  methods: {}
}
</script>

<style scoped>
  .wrapper{
    overflow: hidden;
    max-width: 600px;
  }
</style>

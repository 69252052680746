<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'AdminHomePage',
  props: {},
  components: {},
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
// @import 'path/_variables';
</style>

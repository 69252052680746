<template>
  <v-card class="talk pa-3">
    <div class="talk__title mb-3">
      <v-icon v-show="data.is_keynote" color="primary" class="mr-2">
        mdi-key
      </v-icon>
      {{data.talk.title}}
      <v-icon x-large class="talk__icon">mdi-drag-variant</v-icon>
    </div>
    <div class="d-flex">
      <v-avatar
        class="mr-3"
        color="grey"
        size="48"
      >
        <img
          v-if="data.bio.image"
          :src="data.bio.image"
          :alt="data.bio.first_name + 'avatar'">
        <span v-else-if="data.bio.first_name" class="white--text headline">{{data.bio.first_name.charAt(0)}}{{data.bio.last_name.charAt(0)}}</span>
        <v-icon v-else dark>
          mdi-account-circle
        </v-icon>
      </v-avatar>
      <div>
        <div>{{data.bio.first_name}} {{data.bio.last_name}}</div>
        <div class="grey--text"> {{data.bio.job_title}}</div>
      </div>
    </div>
    <div class="mt-3 text-center">Track: {{data.track}}</div>
  </v-card>
</template>

<script>
import { makeFieldName } from '~src/assets/js/utils'

export default {
  name: 'TalkCard',
  props: ['data'],
  methods: { makeFieldName }
}
</script>

<style scoped lang="scss">
  .talk {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
    min-height: 90px;
    margin-bottom: 30px;

    &:active {
      cursor: grabbing;
      cursor: -moz-grabbing;
      cursor: -webkit-grabbing;
    }

    &__icon {
      float: right;
      color: #dcdcdc;
    }

    &__title {
      font-weight: bold;
      font-size: 16px;
      text-align: center;
    }
  }
</style>

export default {
  required: [value => !!value || 'Field is required'],
  min: v => (v && v.length >= 3) || 'Min 3 characters',
  requiredPhone: value => {
    // eslint-disable-next-line
    const pattern = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/
    return pattern.test(value) || 'Invalid phone number.'
  },
  phone: [
    // eslint-disable-next-line
    v => (!v?.length || /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(v)) || 'Invalid phone number.'
  ],
  email: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return pattern.test(value) || 'Invalid e-mail.'
  },
  notRequiredEmail: value => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return !value?.length || pattern.test(value) || 'Invalid e-mail.'
  },
  password: value => {
    const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
    return pattern.test(value) || 'Password must be 8 characters long and contain an uppercase, lowercase, digit, and special character'
  },
  notRequiredPassword: value => {
    const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,}$/
    return !value?.length || pattern.test(value) || 'Password must be 8 characters long and contain an uppercase, lowercase, digit, and special character'
  },
  noFloat: value => {
    // const float pattern = /(?<=^| )\d+(\.\d+)?(?=$| )|(?<=^| )\.\d+(?=$| )/
    const pattern = /^[0-9]+$/
    return pattern.test(value) || 'Only integers are allowed.'
  },
  acl: value => {
    const pattern = /[\t\WA-Z]/
    console.log(pattern.test(value))
    return !pattern.test(value) || 'Group name is not valid'
  },
  emailMatch: () => ('The email and password you entered don\'t match')
}

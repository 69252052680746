var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card pa-5  d-flex align-content-center justify-space-between"},[_c('div',{staticClass:"card__body d-flex flex-column justify-space-between "},[_c('h3',{directives:[{name:"show",rawName:"v-show",value:(_vm.title),expression:"title"}],staticClass:"card__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.description),expression:"description"}],staticClass:"card__description"},[_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.description[0])+" - "+_vm._s(_vm.data[0]))]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.description[1])+" - "+_vm._s(_vm.data[1]))])]),_c('div',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.extension),expression:"extension"}],staticClass:"card__box"},[_vm._v(" "+_vm._s(_vm.extension)+" ")])])]),_c('div',{staticClass:"card__wrapper"},[_c('div',{staticClass:"card__sum"},[_vm._v(" "+_vm._s(_vm.dataInPercentage)+" ")]),_c('DoughnutChart',{attrs:{"width":247,"height":247,"chartdata":{
          labels: _vm.description,
          datasets: [
           {
           borderWidth: 0,
           backgroundColor: _vm.chartBackgrounds,
           data: _vm.data
           }
         ]
     }}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }
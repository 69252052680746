<template>
  <v-row align="center" justify="center" class="login">
    <v-alert
      v-if="hasError"
      type="error"
      prominent
      text
      class="login__error"
    >
      An error occurred on your login. Try again or get help on
      <a :href="`mailto:${config.helpEmail}`"> {{config.helpEmail}} </a>
    </v-alert>
    <v-alert
      v-if="notAdmin"
      type="error"
      prominent
      text
      class="login__error"
    >
     Admin panel for sponsor and speaker is coming soon.
      If you have questions, please, contact us at <a :href="`mailto:${config.helpEmail}`"> {{config.helpEmail}} </a>
    </v-alert>
    <v-col lg="3" md="4" sm="6" xs="12" class="login__wrapper">
      <h2 class="login__heading">
        Login
      </h2>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="submitLogin"
      >
        <v-text-field
          v-model="form.username"
          label="Login"
          outlined
          :rules="rules.required"
        />
        <v-text-field
          v-model="form.password"
          type="password"
          label="Password"
          outlined
          :rules="rules.required"
        />
        <router-link
          :to="'/forgot'"
        >
          <div class="mb-6 text-end"> Forgot password?</div>
        </router-link>
        <div class="d-flex justify-space-between">
          <v-btn
            :disabled="loginBtnLoading"
            @click="resetForm"
            class="px-10"
            x-large>
            Reset
          </v-btn>
          <v-btn
            color="primary"
            :loading="loginBtnLoading"
            :disabled="!valid"
            type="submit"
            class="px-10"
            x-large>
            Login
          </v-btn>
        </div>

      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import rules from '~src/assets/js/validationRules'
import config from '~public/config/config.json'
import { mapActions } from 'vuex'

export default {
  name: 'Login',
  props: {},
  components: {},
  data: () => ({
    config,
    rules,
    form: {
      username: '',
      password: ''
    },
    valid: false,
    hasError: false,
    notAdmin: false,
    loginBtnLoading: false
  }),
  computed: {},
  methods: {
    ...mapActions([
      'GET', 'signIn'
    ]),
    submitLogin () {
      this.hasError = false
      this.loginBtnLoading = true
      this.$refs.form.resetValidation()
      this.$refs.form.validate()

      if (!this.valid) return

      this.signIn({ ...this.form })
        .then(() => {
          this.checkIfAdmin()
        }).catch(() => {
          this.hasError = true
        })
        // .finally(() => {
        //   this.loginBtnLoading = false
        // })
    },
    checkIfAdmin () {
      this.GET({ route: '/admin_authorizer' })
        .then(({ data }) => {
          if (data.msg === 'Authorized') this.$router.push('/')
        })
        .catch((err) => {
          err.response.data.msg === 'Not authorized'
            ? this.notAdmin = true
            : this.hasError = true
        })
        .finally(() => {
          this.loginBtnLoading = false
        })
    },
    resetForm () {
      this.hasError = false
      this.$refs.form.reset()
    }
  }
}
</script>

<style lang="scss">
  @import '~src/assets/scss/_variables';

  .login {
    height: 100vh;
    position: relative;

    &__wrapper {
      padding: 0 32px !important;
    }

    &__heading {
      font-size: 60px;
      line-height: 70px;
      font-weight: 300;
      margin-bottom: 32px;
      text-align: center;
    }

    &__button-col {
      flex-grow: 0 !important;
    }

    &__error {
      position: absolute !important;
      top: 32px;
      left: 32px;
      right: 32px;
    }
  }

  @media (min-width: $mob-xs) {
    .login {
      &__wrapper {
        padding: unset !important;
      }
    }
  }
</style>

<template>
  <div>
    <PageTitle :title="event.name">
      <v-btn
        icon
        color="blue"
        x-large
      >
        <v-icon>mdi-play</v-icon>
      </v-btn>
    </PageTitle>

    <v-container class="pa-8 pr-10 ">
      <Preloader v-if="loading"/>
      <v-row>
        <v-col sm="12" order-lg="2" lg="6">
          <CardCircleChart
            title="Amount attendee"
            :data="[analytics.event.expected_atendees, analytics.event.current_attendees]"
            :description="[`Expected attendee`,`Current registration`]"
            :extension="attendeesToday > 0 ? `+${attendeesToday} attendee today` : null"
            :chart-backgrounds="['#2196F3', '#E3F2FD']"
            class="mb-5"
          />
          <CardBarChart
            title="Attendee by type"
            :description="['Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum', 'Lorem ipsum']"
            :chart-backgrounds="['#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB']"
            :data="[100, 80, 60, 40, 20]"
            class="mb-5"
          />
          <CardBubbles
            title="Sponsors Level"
            is-smaller
            :data="{
            total: 100,
            golden: Math.round(analytics.sponsors_by_type.gold/(analytics.sponsors_by_type.gold + analytics.sponsors_by_type.silver)*100) ,
            silver: Math.round(analytics.sponsors_by_type.silver/(analytics.sponsors_by_type.gold + analytics.sponsors_by_type.silver)*100),
          }"
          />
        </v-col>
        <v-col sm="12" order-lg="1" lg="6">
          <div style="height: 295px; border: 1px solid cornflowerblue" class="event-page_live-wrapper mb-5">
            <ActionButtonsGroup direction="left" class="event-page_action-btn"/>
          </div>
<!--          <TalksTimelineTabs v-if="tracks" :tracks="tracks" class="event-page_timeline-tabs" />-->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import PageTitle from '../common/pageTitle/pageTitle'
import Preloader from '../common/preloader/Preloader'
import CardBubbles from '~src/components/common/Cards/CardBubbles'
import CardCircleChart from '~src/components/common/Cards/CardСircleChart'
import CardBarChart from '~src/components/common/Cards/CardBarChart'
// import TalksTimelineTabs from '~src/features/talksTimelineTabs'
import ActionButtonsGroup from '../common/actionButtonsGroup/actionButtonsGroup'

export default {
  name: 'RealTimeEventPage',
  components: {
    ActionButtonsGroup,
    CardCircleChart,
    CardBarChart,
    CardBubbles,
    PageTitle,
    // TalksTimelineTabs,
    Preloader
  },
  props: {},

  data () {
    return {
      loading: false,
      event: null,
      events: null,
      analytics: null,
      attendeesToday: null,
      sponsors: null,
      tracks: null
    }
  },
  computed: {
    eventId () {
      return this.$router.currentRoute.params.id
    }
  },
  created () {
    this.getEvent()
    this.getAnalytics()
    this.getEventTracks()
  },
  filters: {
    moment: function (date) {
      return moment(date).format('hh:mm a')
    }
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getEvent: function () {
      this.loading = true
      this.GET({ route: `admin/e/event/eid/${this.eventId}` })
        .then(({ data }) => {
          console.log('get 1 Event ', data)
          this.event = data.data
          // this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAnalytics: function () {
      this.loading = true
      this.GET({ route: `admin/event/${this.eventId}/attendees_today` })
        .then(({ data }) => {
          this.attendeesToday = data.data.count
          // this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
      this.GET({ route: `admin/event/${this.eventId}/attendees_sponsors_summary` })
        .then(({ data }) => {
          this.analytics = data.data
          // this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    getEventTracks: function () {
      this.loading = true
      this.GET({ route: `admin/p/event/pid/${this.eventId}/e/track` })
        .then(({ data }) => {
          console.log('getEventTracks', data)
          this.tracks = data.data
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .event-page {

    &_live-wrapper {
      position: relative;
    }

    &_action-btn {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }

    &_timeline-tabs {
      overflow-x: hidden
    }
  }
</style>

<template>
  <div class="title-block">
    <v-container class="title-wrapper">
      <h3>{{title}}</h3>
      <div>
        <slot/>
      </div>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'pageTitle',
  props: ['title'],

  data: () => ({})

}
</script>

<style lang="scss">
  .title-block {
    height: 64px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.14), 0 4px 1px -2px rgba(0, 0, 0, 0.2);
  }

  .title-wrapper {
    height: 100%;
    padding: 0 55px 0 41px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    text-transform: capitalize;
  }
</style>

<template>
  <v-select
    :value="value"
    @change="$emit('select', $event)"
    :loading="choicesLoading"
    :label="makeFieldName(field.f_name)"
    :items="choices"
    item-text="name"
    item-value="id"
    :rules="field.required ? [rules.required] : []"
  />
</template>

<script>
import { makeFieldName } from '~src/assets/js/utils'
import rules from '~src/assets/js/validationRules'
import { mapActions } from 'vuex'

export default {
  name: 'select-field',
  props: ['value', 'field'],
  components: {},
  data () {
    return {
      choicesLoading: false,
      choices: [],
      rules
    }
  },
  created () {
    if (this.field) {
      this.getSelectChoices(this.field.metadata.foreign_key)
    }
  },
  methods: {
    makeFieldName,
    ...mapActions(['GET']),
    getSelectChoices: function (entity) {
      this.choicesLoading = true
      this.GET({ route: `/${entity}/` })
        .then(({ data }) => {
          this.choices = data.data
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.choicesLoading = false
        })
    }
  }
}
</script>

<template>
  <quill-editor
    class="editor"
    v-model="inputVal"
    ref="myQuillEditor"
    :options="editorOptions"
  >
  </quill-editor>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'quillEitor',
  components: { quillEditor },
  props: ['value'],
  computed: {
    inputVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  data () {
    return {
      editorOptions: {
        modules: {
          toolbar: [
            [
              { header: [1, 2, 3, 4, 5, 6, false] },
              { font: [] },
              { size: [] }
            ],
            ['bold', 'italic', 'underline', 'strike'],
            [{ color: [] }, { background: [] }],
            [{ script: 'sub' }, { script: 'super' }],
            ['blockquote', 'code-block'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' }
            ],
            [{ direction: 'rtl' }, { align: [] }],
            ['link', 'image', 'video'],
            ['clean']
          ]
        }
      }
    }
  }
}
</script>
<style>
  .editor {
    height: 150px;
  }
</style>

<template>
  <v-container class="event-card">
    <CardTemplate title="Basic info">
      <v-row>
        <v-col sm="12" md="3">
          <!--TODO: make normal picture of event
          should be probably in data.background -->
          <v-img src="https://picsum.photos/200/200" class="event-card__image"/>
          <div class="event-card__info-wrapper mt-4">
            <div class="event-card__info-block mb-2">
              <span class="event-card__subtitle">
                Type:
              </span>
              <span class="event-card__text event-card__text--event-type">
                {{ data.event_type }}
              </span>
            </div>
            <div class="event-card__info-block">
              <span class="event-card__subtitle">
                Date:
              </span>
              <div class="event-card__time-container">
                <div class="event-card__time">
                  <span class="event-card__text">
                    {{ startDate }}
                  </span>
                  <span class="event-card__text">
                    {{ startTime }}
                  </span>
                </div>
                <div class="event-card__time">
                  <span class="event-card__text">
                    {{ endDate }}
                  </span>
                  <span class="event-card__text">
                    {{ endTime }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col sm="12" md="9">
          <div class="mb-4">
            <span class="event-card__subtitle">
              Name:
            </span>
            <span class="event-card__text">
              {{ data.name }}
            </span>
          </div>
          <div v-show="data.ticket_type[0].price" class="mb-4">
            <span class="event-card__subtitle">
              Pricing:
            </span>
            <span class="event-card__text">
<!--              TODO is it right??-->
              $ {{ data.ticket_type[0].price }}
            </span>
          </div>
          <div class="mb-4">
            <span class="event-card__subtitle">
              Description:
            </span>
            <p class="mb-0 event-card__text">
              {{ data.description }}
            </p>
          </div>
          <span class="event-card__subtitle">
              Share in social media
          </span>
          <p class="mt-2">
            <SocialSharing network="linkedin" :title="`${data.name} ${startDate} - ${endDate}`" :description="data.description"/>
            <SocialSharing network="facebook" :title="`${data.name} ${startDate} - ${endDate}`" :description="data.description"/>
            <SocialSharing network="twitter" :title="`${data.name} ${startDate} - ${endDate}`" :description="data.description"/>
          </p>
          <AddToCalendar :event="data"/>
        </v-col>
      </v-row>
    </CardTemplate>
  </v-container>
</template>

<script>
import moment from 'moment'
import CardTemplate from '~src/components/common/Cards/CardTemplate'
import SocialSharing from '../socialMedia/SocialSharing'
import AddToCalendar from '../addToCalendar/add-to-calendar'

export default {
  name: 'EventCard',
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  },
  components: {
    AddToCalendar,
    SocialSharing,
    CardTemplate
  },
  data: () => ({}),
  computed: {
    startDate () {
      return moment(this.data.start).format('DD MMM YYYY')
    },
    startTime () {
      return moment(this.data.start).format('hh:mm a')
    },
    endDate () {
      return moment(this.data.end).format('DD MMM YYYY')
    },
    endTime () {
      return moment(this.data.end).format('hh:mm a')
    }
  },
  methods: {}
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .event-card {
    $self: &;

    &__image {
      width: 100%;
      height: 200px;
      object-fit: fill;
    }

    &__subtitle {
      font-weight: 500;
      min-width: 50px;
      flex-shrink: 0;
    }

    &__text {
      color: rgba(0, 0, 0, 0.6);

      &--event-type {
        text-transform: capitalize;
      }
    }

    &__info-block {
      display: flex;
    }

    &__time-container {
      width: 100%;
    }

    &__time {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      #{$self}__text:nth-child(odd) {
        margin-right: 16px;
      }

      #{$self}__text:nth-child(even) {
        min-width: 70px;
        text-align: right;
      }
    }
  }
</style>

<template>
  <v-card class="pa-4 card-textarea">
    <v-row justify="space-between">
      <v-col>
        <h3 class="pb-4 card-textarea__title">
          {{ title }}
        </h3>
      </v-col>
      <v-col v-if="hasButton">
        <v-btn
          color="primary"
          text
          :loading="isBtnLoading"
          class="card-textarea__button"
          @click="$emit('save', $event)"
        >
          save
        </v-btn>
      </v-col>
    </v-row>
    <p v-show="description" class="mb-4 card-textarea__description">
      {{ description }}
    </p>
    <v-textarea
      outlined
      :label='textareaLabel'
      :value="value"
      :readonly="isReadonly"
      :loading="isTextareaLoading"
      @input="$emit('input', $event)"
    />
  </v-card>
</template>

<script>
export default {
  name: 'CardTextarea',
  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    textareaLabel: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
    hasButton: {
      type: Boolean,
      default: false
    },
    isBtnLoading: {
      type: Boolean,
      default: false
    },
    isTextareaLoading: {
      type: Boolean,
      default: false
    }
  },
  components: {},
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
// @import 'path/_variables';
.card-textarea {
  &__heading {
    text-transform: capitalize;
  }

  &__description {
    font-weight: 300;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.6);
  }

  &__button {
    float: right;
  }
}
</style>

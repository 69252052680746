<template>
  <div>
    <PageTitle :title="event.name">
      <v-btn
        color="primary"
        text
      >
        Stop Event
      </v-btn>
    </PageTitle>
    <v-container class="pa-8 pr-10 ">
      <Preloader v-if="loading"/>
      <v-tabs v-model="tab" fixed-tabs class="mb-5">
        <v-tab v-for="track in tracks" :key="track.id">
          <h3 class="my-4">
            {{ track.title }}
          </h3>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="track in tracks" :key="track.id">
          <v-row>
            <v-col sm="12" lg="4">
              <div>
                <v-img src="https://picsum.photos/100/50"/>
                <h2 class="my-3">Stream controls</h2>
                <v-list>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon x-small color="green">mdi-checkbox-blank-circle</v-icon>
                    </v-list-item-icon>
                    Stream is live
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-icon>
                      <v-icon x-small color="green">mdi-checkbox-blank-circle</v-icon>
                    </v-list-item-icon>
                    No streaming issues
                  </v-list-item>
                </v-list>
              </div>
            </v-col>
            <v-col sm="12" lg="8">
              <v-card class="pa-4">
                <v-row>
                  <v-col sm="12" lg="6">
                    <v-img src="https://picsum.photos/200/150"/>
                  </v-col>
                  <v-col sm="12" lg="6">
                    <h3 class="pb-2">Hacking for fun and profit</h3>
                    <p class="subtitle">Addeline Mitchell - Devops Engineer Threat Informant</p>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
                      nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate
                      velit.</p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" lg="7">
              <CardTemplate title="Chat" style="height: 100%">
                <div class="d-flex flex-column justify-center align-center" style="height: 75%">
                  <div>No message in chat yet.</div>
                </div>
                <v-text-field
                  v-model="message"
                  append-outer-icon="mdi-send"
                  outlined
                  clear-icon="mdi-close-circle"
                  clearable
                  label="Message"
                  type="text"
                ></v-text-field>
                <!--                  :append-outer-icon="message ? 'mdi-send' : 'mdi-microphone'"-->
                <!--                  @click:append="toggleMarker"-->
                <!--                  @click:append-outer="sendMessage"-->
                <!--                  @click:prepend="changeIcon"-->
                <!--                  @click:clear="clearMessage"-->
              </CardTemplate>
            </v-col>
            <v-col sm="12" lg="5">
              <CardTemplate title="Alert">
                <v-alert
                  border="right"
                  colored-border
                  type="error"
                  elevation="2"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.
                </v-alert>
                <v-alert
                  border="right"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                  dolore magna aliqua.
                </v-alert>
                <v-alert
                  border="right"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  Lorem ipsum dolor sit amet, consectetur
                </v-alert>
                <v-alert
                  border="right"
                  colored-border
                  type="warning"
                  elevation="2"
                >
                  Lorem ipsum dolor sit amet, consectetur
                </v-alert>
                <ActionButtonsGroup direction="top" class="event-page_action-btn"/>
              </CardTemplate>
            </v-col>
          </v-row>
          <v-row>
            <v-col sm="12" lg="6">
              <CardTemplate title="Pools" style="height: 100%">
                <div class="d-flex flex-column justify-end" style="height: 90%">
                  <div class="pool-card-appendix mb-1">
                    <div>Addeline Mitchell</div>
                    <div>9 am</div>
                  </div>
                  <v-card
                    class="mx-auto"
                  >
                    <div class="pool-card-header">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                      labore
                      et dolore magna aliqua?
                    </div>
                    <div class="pa-5">
                      <div class="mb-3">
                        <div class="d-flex justify-space-between">
                          <p>One answer</p>
                          <p>12%</p>
                        </div>
                        <v-progress-linear value="12"></v-progress-linear>
                      </div>
                      <div class="mb-3">
                        <div class="d-flex justify-space-between">
                          <p>One answer</p>
                          <p>20%</p>
                        </div>
                        <v-progress-linear value="20"></v-progress-linear>
                      </div>
                      <div>
                        <div class="d-flex justify-space-between">
                          <p>One answer</p>
                          <p>0%</p>
                        </div>
                        <v-progress-linear value="0"></v-progress-linear>
                      </div>
                    </div>
                  </v-card>
                </div>
              </CardTemplate>
            </v-col>
            <v-col sm="12" lg="6">
              <CardTemplate title="Questions" class="questions">
                <div class="d-flex flex-column justify-center align-center" style="height: 100%">
                  <div>No question yet.</div>
                </div>
              </CardTemplate>
            </v-col>
          </v-row>

        </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import moment from 'moment'
import PageTitle from '../common/pageTitle/pageTitle'
import Preloader from '../common/preloader/Preloader'
import ActionButtonsGroup from '../common/actionButtonsGroup/actionButtonsGroup'
import CardTemplate from '~src/components/common/Cards/CardTemplate'

export default {
  name: 'ModeratorHome',
  components: {
    ActionButtonsGroup,
    PageTitle,
    CardTemplate,
    Preloader
  },
  props: {},

  data () {
    return {
      message: null,
      tab: null,
      loading: false,
      eventId: 'orv2020',
      event: null,
      tracks: null
    }
  },

  created () {
    this.getEvent()
    this.getEventTracks()
  },
  filters: {
    moment: function (date) {
      return moment(date).format('hh:mm a')
    }
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getEvent: function () {
      this.loading = true
      this.GET({ route: 'admin/e/event/eid/pdx2020' })
        .then(({ data }) => {
          console.log('get 1 Event ', data)
          this.event = data.data
          // this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    },
    getEventTracks: function () {
      this.loading = true
      this.GET({ route: 'admin/p/event/pid/pdx2020/e/track' })
        .then(({ data }) => {
          console.log('getEventTracks', data)
          this.tracks = data.data
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .subtitle {
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.02em;
    color: rgba(0, 0, 0, 0.6);
  }

  .pool-card-header {
    padding: 11px 16px;
    height: 64px;
    background: rgba(0, 0, 0, 0.05);
  }

  .pool-card-appendix {
    display: flex;
    justify-content: space-between;
    font-weight: 300;
    font-size: 12px;
    line-height: 14px;
    color: rgba(0, 0, 0, 0.6)
  }

  .questions {
    height: 560px;
  }

  .event-page {

  }
</style>

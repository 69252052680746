<template>
  <Table
    :loading="loading"
    objectName="Sponsorship packages"
    :data="packages"
    :tableHeader="headers"
    :fields="packagesFields"
    :url='`/event/${$route.params.id}/sponsorship_package`'
    :refresh="getPackages"
    :allowCreation=true
  />
</template>

<script>
import { mapActions } from 'vuex'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import Table from '~src/components/common/table/Table'

export default {
  name: 'SponsorshipPackages',
  components: { Table },
  props: [],
  data () {
    return {
      loading: false,
      headers: jsonHeaders.SponsorshipPackages,
      packages: undefined,
      packagesFields: null
    }
  },
  created () {
    this.getPackages()
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getPackages: function () {
      this.loading = true
      this.GET({ route: `/event/${this.$route.params.id}/sponsorship_package/` })
        .then(({ data }) => {
          this.packages = data.data
          this.getPackagesFields()
        })
        .catch(err => {
          console.log(err)
        })
    },
    getPackagesFields: function () {
      this.GET({ route: '/fields/sponsorship_package' })
        .then(({ data }) => {
          this.packagesFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

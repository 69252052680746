<template>
  <div>
    <SideBar/>
    <v-main>
      <div class="not-found__wrapper">
        <div class="not-found__title">404 ERROR</div>
        <div class="not-found__subtitle my-5">Page not found. Don’t worry, just come back to home page.</div>
        <router-link
          :to="'/'"
        >
          <v-btn color="primary" large>
            Back to home page
          </v-btn>
        </router-link>
      </div>
    </v-main>
  </div>
</template>

<script>
import SideBar from '~src/features/sideBar'

export default {
  name: 'NotFound',
  props: {},
  components: {
    SideBar
  },
  data: () => ({})
}
</script>

<style lang="scss">
  .not-found {
    &__wrapper {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
    }
    &__title {
      font-weight: 300;
      font-size: 60px;
      line-height: 70px;
      color: #000000;
    }
    &__subtitle {
      max-width: 45vw;
      font-size: 34px;
      text-align: center;
      line-height: 40px;
      letter-spacing: 0.02em;
      color: rgba(0, 0, 0, 0.6);
    }
  }
</style>

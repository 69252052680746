<template>
  <v-row align="center" justify="center" class="login">
    <v-alert
      v-if="error || success"
      :type="error? 'error':'success'"
      outlined
      text
      dismissible
      class="reset__alert"
    >
      {{error || 'Code was successfully sent. Please check your email!'}}
    </v-alert>

    <v-col lg="3" md="4" sm="6" xs="12" class="login__wrapper">
      <h2 class="reset__heading">
        Reset password
      </h2>
      <router-link :to="'/login'">
        <div class="mb-6">Back to login</div>
      </router-link>

      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="submit"
      >
        <v-text-field
          v-model="username"
          label="Username or email"
          outlined
          :rules="rules.required"
          @mousedown="resetAlerts"
        />
        <v-btn
          color="primary"
          :loading="updating"
          :disabled="!valid"
          type="submit"
          x-large
          block
        >
          Send
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import rules from '~src/assets/js/validationRules'
import { mapActions } from 'vuex'

export default {
  name: 'ForgotPassword',
  props: {},
  components: {},
  data: () => ({
    updating: false,
    username: '',
    valid: false,
    error: null,
    success: null,
    rules
  }),
  computed: {},
  methods: {
    ...mapActions([
      'POST'
    ]),
    resetAlerts () {
      this.error = null
      this.success = null
    },
    submit () {
      this.updating = true
      console.log({ username: this.username })
      this.POST({
        route: 'admin/forgot_passsword',
        data: { username: this.username }
      })
        .then((resp) => {
          console.log('success resp', resp)
          this.success = true
        })
        .catch(err => {
          console.log(err)
          this.error = err
        })
        .finally(() => {
          this.updating = false
        })
    }
  }
}
</script>

<style lang="scss">
  .reset {
    &__heading {
      font-size: 40px;
      line-height: 60px;
      font-weight: 300;
      margin-bottom: 24px;
      text-align: center;
    }

    &__alert {
      position: absolute;
      top: 5%;
      margin: 0 5%;
    }
  }
</style>

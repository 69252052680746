<template>
  <v-card class="talk-modal">
    <v-form
      ref="talkForm"
      @submit.prevent="createTalk">

      <v-card-title>
        <span class="headline">{{titleAction}}</span>
      </v-card-title>

      <v-card-text>
        <div class="my-3">Talk Info</div>
        <v-text-field
          outlined
          label="Talk Title"
          v-model="createTalkForm.title"
          :rules="rules.required"
        />
        <v-select
          v-if="biosList"
          outlined
          label="Please select a bio"
          class="mb-4"
          v-model="createTalkForm.bio_id"
          :items="biosList"
          item-text="bio"
          item-value="id"
          :rules="rules.required"
          :loading="areBiosLoading"
          :disabled="expandBio"
          @change="errorNoBio=false"
        ></v-select>
        <div class="mb-5">{{newBiosMsg}} you can create it now. Click to
          <span class="blue--text expand-control" @click="expandBio = !expandBio"> create a bio</span>
        </div>
        <p v-show="errorNoBio" class="red--text">The bio must be chosen.</p>
        <!--      block of bio creation-->
        <v-expand-transition>
          <div v-show="expandBio">
            <v-form
              ref="bioForm"
              @submit.prevent="createBio">
              <div class="my-3">Speaker Info</div>
              <v-text-field
                v-model="createBioForm.first_name"
                label="First name"
                outlined
                :rules="rules.required"
              />
              <v-text-field
                v-model="createBioForm.last_name"
                label="Last name"
                outlined
                :rules="rules.required"
              />
              <v-text-field
                v-model="createBioForm.company"
                label="Organization"
                outlined
                :rules="rules.required"
              />
              <v-text-field
                v-model="createBioForm.profession"
                label="Job Title"
                outlined
                :rules="rules.required"
              />
              <v-textarea
                v-model="createBioForm.bio"
                label="Description"
                outlined
                :rules="rules.required"
              ></v-textarea>
              <v-btn
                type="submit"
                outlined
                color="blue darken-1"
                class="mb-6"
                :loading="areBiosLoading"
              >
                Create new bio
              </v-btn>
            </v-form>
          </div>
        </v-expand-transition>
        <!--      block of bio creation end-->
        <v-textarea
          v-model="createTalkForm.description"
          label="Talk Description"
          outlined
          :rules="rules.required"
        ></v-textarea>

        <v-text-field
          v-show="!userInfo.first_name"
          outlined
          label="First Name"
          v-model="userInfoForm.first_name"
          :rules="rules.required"
        />
        <v-text-field
          v-show="!userInfo.last_name"
          outlined
          label="Last Name"
          v-model="userInfoForm.last_name"
          :rules="rules.required"
        />
        <v-text-field
          v-show="!userInfo.email"
          outlined
          label="Email"
          v-model="userInfoForm.email"
          :rules="[rules.required, rules.email]"
        />
        <v-text-field
          v-show="!userInfo.organization"
          outlined
          label="Organization"
          v-model="userInfoForm.organization"
          :rules="rules.required"
        />

      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="updating"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          type="submit"
          text
          color="blue darken-1"
          :loading="updating"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import rules from '~src/assets/js/validationRules'

export default {
  name: 'talk-modal',
  components: {},
  props: ['editedItem', 'close'],
  data () {
    return {
      updating: false,
      expandBio: false,
      biosList: null,
      areBiosLoading: false,
      errorNoBio: false,
      createBioForm: {
        first_name: '',
        last_name: '',
        profession: '',
        company: '',
        bio: ''
      },
      createTalkForm: {
        title: '',
        bio_id: null,
        description: ''
      },
      userInfoForm: {
        first_name: '',
        last_name: '',
        email: '',
        organization: ''
      },
      rules
    }
  },
  created () {
    this.getBios()
  },
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    }),
    titleAction () {
      return this.editedItem ? 'Edit Talk' : 'Create Talk'
    },
    newBiosMsg () {
      return this.biosList ? 'If you want new bio' : 'You don’t have bios -'
    }
  },
  methods: {
    ...mapActions([
      'GET', 'PUT', 'POST'
    ]),
    getBios () {
      this.areBiosLoading = true
      this.GET({ route: `/admin/p/profile/pid/${this.userInfo.id}/e/bio` })
        .then(({ data }) => {
          console.log('bios', data)
          this.biosList = data.data || null
          this.createTalkForm.bio_id = null
        })
        .finally(() => {
          this.areBiosLoading = false
        })
    },
    createBio: function () {
      if (this.$refs.bioForm.validate()) {
        this.areBiosLoading = true
        console.log(this.createBioForm)
        this.POST({
          route: `/admin/p/profile/pid/${this.userInfo.id}/e/bio`,
          data: this.createBioForm
        })
          .then(({ data }) => {
            console.log('create bio result', data)
            this.getBios()
            // TODO check it!
            this.$refs.bioForm.reset()
            this.expandBio = false
          })
          .catch(err => {
            console.log(err)
            this.areBiosLoading = false
          })
      }
    },
    createTalk: function () {
      // TODO: REQUIRED "last_name""first_name":"email":"organization":"bio_profile""talk_type" bio_id}}
      this.errorNoBio = !this.createTalkForm.bio_id
      if (this.$refs.talkForm.validate()) {
        if (!this.errorNoBio) {
          this.updating = true
          console.log({
            ...this.createTalkForm,
            bio_profile: this.userInfo.id,
            first_name: this.userInfo.first_name || this.userInfoForm.first_name,
            last_name: this.userInfo.last_name || this.userInfoForm.last_name,
            email: this.userInfo.email || this.userInfoForm.email,
            organization: this.userInfo.organization || this.userInfoForm.organization
          })
          this.POST({
            route: 'admin/e/talk',
            data: {
              ...this.createTalkForm,
              bio_profile: this.userInfo.id,
              first_name: this.userInfo.first_name || this.userInfoForm.first_name,
              last_name: this.userInfo.last_name || this.userInfoForm.last_name,
              email: this.userInfo.email || this.userInfoForm.email,
              organization: this.userInfo.organization || this.userInfoForm.organization
            }
          })
            .then(({ data }) => {
              console.log('create talk result', data)
              // this.getTalks()
              // this.expandTalk = false
              // this.$refs.talkForm.reset()
              this.$emit('tableUpdated', 'create')
              this.close()
            })
            .catch(err => {
              console.log(err)
              this.updating = false
            })
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

  .talk-modal {
    width: 784px;
  }

  .expand-control {
    cursor: pointer;
  }

</style>

<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: () => ({})
    }

    // chartdata example for doughnut chart
    // chartdata: {
    //   labels: ['Registered already', 'Not requstered yet'],
    //   datasets: [
    //     {
    //       borderWidth: 0,
    //       backgroundColor: ['#2196F3', '#E3F2FD'],
    //       data: [75, 25]
    //     }
    //   ]
    // },
  },
  data: () => ({
    options: {
      responsive: true,
      cutoutPercentage: 90,
      maintainAspectRatio: false,
      legend: {
        display: false
      }
    }
  }),

  mounted () {
    this.renderChart(this.chartdata, this.options)
  }
}
</script>

<template>
  <v-card
    class="card-bubble pa-5  d-flex align-content-center justify-space-between"
    :class="{'card-bubble--smaller': isSmaller}"
  >
    <div class="card-bubble__body d-flex flex-column justify-space-between ">
      <h3 v-show="title" class="card-bubble__title">
        {{ title }}
      </h3>
      <div class="card-bubble__description">
        <p
          v-for="(label, index) in description"
          :key="label"
          class="mb-0 card-bubble__item"
        >
          <span
            class="card-bubble__marker mr-3"
            :style="`background-color: ${backgrounds[index]}`"
          />
          {{ label }}
        </p>
      </div>
      <div>
        <div v-show="extension" class="card-bubble__box">
          {{ extension }}
        </div>
      </div>
    </div>
    <div class="card-bubble__bubbles">
      <div
        class="card-bubble__bubble card-bubble__bubble--lg"
        :style="`background-color: ${backgrounds[0]}`"
      >
        {{ `${dataFiltered[0]}%` }}
      </div>
      <div
        class="card-bubble__bubble card-bubble__bubble--md"
        :style="`background-color: ${backgrounds[1]}`"
      >
        {{ `${dataFiltered[1]}%` }}
      </div>
      <div
        class="card-bubble__bubble card-bubble__bubble--sm"
        :style="`background-color: ${backgrounds[2]}`"
      >
        {{ `${dataFiltered[2]}%` }}
      </div>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'CardBubbles',
  props: {
    title: {
      type: String,
      default: ''
    },
    extension: {
      type: String,
      default: ''
    },
    data: {
      type: Array,
      default: () => []
    },
    isSmaller: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      description: ['Total sponsor', 'Golden sponsor', 'Silver sponsor'],
      backgrounds: ['#BBDEFB', '#64B5F6', '#2196F3']
    }
  },
  computed: {
    dataFiltered () {
      return Object.values(this.data).sort((a, b) => {
        if (a < b) return 1
        if (a > b) return -1
      })
    }
  },
  methods: {}
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .card-bubble {
    $self: &;

    height: 295px;
    min-width: 450px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &--smaller {
      #{$self}__bubble {
        &--lg {
          width: 208px;
          height: 208px;
        }
        &--md {
          width: 112px;
          height: 112px;
          right: 145px;
          bottom: 8px;
        }
        &--sm {
          width: 78px;
          height: 78px;
          right: 215px;
          top: 24px;
        }
      }
    }

    &__body {
      height: 100%;
    }

    &__title {
      color: rgba(0, 0, 0, 0.87);
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: rgba(0, 0, 0, 0.6);
    }

    &__item {
      display: flex;
      align-items: center;
    }

    &__box {
      padding: 9px 12px;
      background: rgba(100, 181, 246, 0.3);
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: rgba(0, 0, 0, 0.87);
    }

    &__marker {
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }

    &__bubbles {
      position: relative;
      width: 70%;
      height: 100%;
    }

    &__bubble {
      border-radius: 100%;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 30px;
      line-height: 30px;
      letter-spacing: 0.05em;

      &--lg {
        width: 270px;
        height: 270px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
      }

      &--md {
        width: 150px;
        height: 150px;
        right: 200px;
        bottom: -8px;
      }

      &--sm {
        width: 100px;
        height: 100px;
        right: 300px;
        top: 10px;
        font-size: 28px;
      }
    }
  }
</style>

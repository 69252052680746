<template>
  <div>
    <v-banner elevation="3" class="mt-4 pa-3">
      <h3 class="mb-5 accentuated-headline">Comments{{comments.length?`(${comments.length})`:null}}</h3>
      <v-text-field
        v-model="newComment"
        append-icon="mdi-send"
        @click:append="addComment"
        @keyup.enter.prevent="addComment"
        label="What are your thoughts?"
        outlined
        class="mb-3"
        :loading="isNewCommentLoading"
      />
      <Preloader v-if="loading"/>
      <div v-else>
        <div v-show="!comments.length" class="mb-5">No comments yet. Let’s add first!</div>
        <div v-for="comment in comments" v-bind:key="comment.id">
          <Comment
            :entityId="entityId"
            :comment="comment"
            @updateComment="getComments"
          />
          <div v-show="comment.replies" v-for="reply in comment.replies" v-bind:key="reply.id" class="pl-6">
            <Comment
              :entityId="entityId"
              :comment="reply"
              :isReply="true"
              :replyTo="comment.author_name"
              @updateComment="getComments"
            />
          </div>

        </div>
      </div>

    </v-banner>
  </div>
</template>

<script>
import rules from '~src/assets/js/validationRules'
import { mapActions } from 'vuex'
import Comment from './Comment'
import Preloader from '../../common/preloader/Preloader'

export default {
  name: 'CommentBlock',
  components: {
    Preloader,
    Comment
  },
  props: ['entityId'],
  data: () => ({
    rules,
    loading: false,
    isNewCommentLoading: false,
    comments: [],
    newComment: ''
  }),
  created () {
    this.getComments()
  },
  methods: {
    ...mapActions([
      'GET', 'POST'
    ]),
    getComments () {
      this.loading = true
      this.GET({ route: `/comment/${this.entityId}` })
        .then(({ data }) => {
          console.log('getComments___________-', data)
          if (data.data) this.comments = data.data.slice().sort((a, b) => b.created - a.created)
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          this.loading = false
        })
    },
    addComment () {
      if (this.newComment) {
        this.isNewCommentLoading = true
        const data = {
          parent: this.entityId,
          content: this.newComment
        }
        console.log(data)
        this.POST({
          route: `/comment/${this.entityId}`,
          data: data
        }).then((res) => {
          console.log(res)
          this.getComments()
          this.newComment = ''
        }).catch((err) => {
          console.log(err)
        }).finally(() => {
          this.isNewCommentLoading = false
        })
      }
    }
  }
}
</script>

<style>
  .accentuated-headline::after {
    content: '';
    position: absolute;
    left: 0;
    top: 2.5%;
    width: 2px;
    height: 32px;
    background: #2698EA;
  }
</style>

import { Auth } from 'aws-amplify'

const baseUrl = process.env.VUE_APP_BASE_URL

const state = {
  baseUrl
}

const getters = {
  currentBaseUrl: state => state.baseUrl
}

const actions = {
  GET (_, payload) {
    return Auth.currentSession()
      .then(user => {
        const token = user.idToken.jwtToken

        return this._vm.$axios.get(payload.route, { headers: { Authorization: token } })
          .then(resp => resp)
      })
  },
  POST (_, payload) {
    return Auth.currentSession()
      .then(user => {
        const token = user.idToken.jwtToken

        return this._vm.$axios.post(payload.route, payload.data,
          { headers: { Authorization: token } })
          .then(resp => resp)
      })
  },
  PUT (_, payload) {
    return Auth.currentSession()
      .then(user => {
        const token = user.idToken.jwtToken

        return this._vm.$axios.put(payload.route, payload.data,
          { headers: { Authorization: token } })
          .then(resp => resp)
      })
  },
  DELETE (_, payload) {
    return Auth.currentSession()
      .then(user => {
        const token = user.idToken.jwtToken

        return this._vm.$axios.delete(payload.route, { headers: { Authorization: token } })
          .then(resp => resp)
      })
  }
}

export default {
  state,
  getters,
  actions
}

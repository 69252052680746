<template>
  <div>
    <PageTitle title="Blog" />
    <router-view :blogPosts="blogPosts" />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageTitle from '~src/components/common/pageTitle/pageTitle'

export default {
  name: 'SponsorBlog',
  props: {},
  components: {
    PageTitle
  },
  created () {
    this.getBlogPosts()
  },
  data: () => ({
    blogPosts: []
  }),
  computed: {},
  methods: {
    ...mapActions([
      'GET'
    ]),

    getBlogPosts () {
      this.GET({ route: '/blogpost' })
        .then(({ data }) => {
          console.log('blogPosts.data ', data.data)
          this.blogPosts = data.data
        })
    }
  }
}
</script>

<style lang="scss">
// @import 'path/_variables';
</style>

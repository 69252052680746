<template>
  <Table
    :loading="loading"
    objectName="venues"
    :data="venues"
    :tableHeader="headers"
    :fields="venuesFields"
    url='/venue'
    :refresh="getVenues"
    :allowCreation=true
  />
</template>

<script>
import { mapActions } from 'vuex'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import Table from '~src/components/common/table/Table'

export default {
  name: 'VenueTable',
  components: { Table },
  data () {
    return {
      loading: false,
      headers: jsonHeaders.Venues,
      venues: undefined,
      venuesFields: null
    }
  },
  created () {
    this.getVenues()
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getVenuesFields: function () {
      this.GET({ route: '/fields/venue' })
        .then(({ data }) => {
          this.venuesFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getVenues: function () {
      this.loading = true
      this.GET({ route: '/venue' })
        .then(({ data }) => {
          console.log(data.data)
          this.venues = data.data
          this.getVenuesFields()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<template>
  <div>
    <PageTitle title="Events"/>
    <v-container class="pa-10 pr-15">

      <v-tabs>
        <v-tab>
          My events
        </v-tab>
        <v-tab>
          All events
        </v-tab>
        <v-tab-item>
          <Table
            v-if="speakerEvents"
            objectName="events"
            :data="speakerEvents"
            :tableHeader="tableHeader('EventsNoAction')"
            :url="`/admin/talk/events`"
            :refresh="getTalksEvents"
            :loading="loading"
            :rowClick="goToEvent"
          />
          <NoItems v-show="!speakerEvents">
            No events yet.
            <br/>
            Apply to some event!
          </NoItems>
        </v-tab-item>
        <v-tab-item>
          <Table
            v-if="possibleEvents"
            objectName="events"
            :data="possibleEvents"
            :tableHeader="tableHeader('EventsNoAction')"
            :url="`/admin/talk/events`"
            :refresh="getPossibleEvents"
            :loading="loading"
            :rowClick="goToEvent"
          />
          <NoItems v-show="!possibleEvents">
            No events yet.
            <br/>
            Please wait until admin create it!
          </NoItems>
        </v-tab-item>
      </v-tabs>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageTitle from '../common/pageTitle/pageTitle'
// import Preloader from '../common/preloader/Preloader'
import Table from '~src/components/common/table/Table'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import NoItems from '../common/noItems/noItems'

export default {
  name: 'Events',
  components: {
    NoItems,
    PageTitle,
    // Preloader,
    Table
  },
  props: {},

  data () {
    return {
      loading: false,
      speakerEvents: null,
      possibleEvents: null,
      tableHeaders: jsonHeaders
    }
  },
  created () {
    this.getTalksEvents()
    this.getPossibleEvents()
  },

  methods: {
    ...mapActions([
      'GET'
    ]),
    tableHeader (title) {
      return jsonHeaders[title]
    },
    goToEvent (item, objectName) {
      this.$router.push(`/speaker/events/${item.id}`)
    },
    getTalksEvents: function () {
      this.loading = true
      // this.GET({ route: '/admin/speaker/upcoming_events' })
      this.GET({ route: '/public/event/upcoming_events' })
        .then(({ data }) => {
          console.log('speakerEvents data____________', data)
          this.speakerEvents = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    getPossibleEvents: function () {
      this.loading = true
      this.GET({ route: '/admin/speaker/not_my_upcoming_events' })
        .then(({ data }) => {
          this.possibleEvents = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style lang="scss">

</style>

<template>
  <v-card width="500px">
    <v-card-title>
      <span class="headline mb-6">Manage groups</span>
    </v-card-title>
    <v-card-text>
      <v-select
        style="width: 100%"
        outlined
        multiple
        chips
        label="Select group"
        :loading="loading"
        v-model="groupsToAdd"
        :items="groups"
        item-text="name"
        item-value="name"
      ></v-select>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn
        color="blue darken-1"
        text
        :disabled="loading"
        @click="close"
      >
        Cancel
      </v-btn>
      <v-btn
        color="blue darken-1"
        text
        :loading="loading"
        @click="save"
      >
        Save
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'select-group-modal',
  components: {},
  props: ['close', 'user'],
  data () {
    return {
      loading: false,
      groups: undefined,
      groupsToAdd: []
    }
  },
  created () {
    this.getGroups()
    this.groupsToAdd = this.user.groups.split(',').map(i => i.trim())
  },
  methods: {
    ...mapActions([
      'GET', 'PUT'
    ]),
    getGroups: function () {
      this.loading = true
      this.GET({ route: '/admin/acl/groups' })
        .then(({ data }) => {
          console.log('this.groupsToAdd', this.user.groups)
          console.log('this.groupsToAdd', this.groupsToAdd)
          console.log(' data.data', data.data.map(i => i.name))
          const StrList = data.data.map(i => i.name)
          const res = StrList.filter(f => !this.groupsToAdd.includes(f))
          console.log('res', [...this.groupsToAdd, ...res])
          this.groups = [...this.groupsToAdd, ...res]
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    },
    save: function () {
      console.log(this.groupsToAdd)
      this.updating = true
      this.PUT({
        route: `/admin/p/user/pid/${this.user.username}/e/useracl/eid/${this.user.username}`,
        data: { groups: this.groupsToAdd }
      })
        .then(({ data }) => {
          this.$emit('groupUpdated')
          this.close()
        })
        .catch(err => {
          console.log(err)
          this.close()
        })
        .finally(() => {
          this.updating = false
        })
    }
  }
}
</script>

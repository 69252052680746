import Vue from 'vue'
import Vuex from 'vuex'

// cognito
import Amplify from 'aws-amplify'

// modules
import userAuth from './modules/auth'
import crud from './modules/crud'

Vue.use(Vuex)

Amplify.configure({
  Auth: {
    region: 'us-west-2',
    userPoolId: process.env.VUE_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_USER_POOL_WEB_CLIENT_ID,
    authenticationFlowType: 'USER_PASSWORD_AUTH'
  }
})

export default new Vuex.Store({
  state: {
  },
  mutations: {
    UPDATE_BASE_URL (state, url) {
      state.url = url
    }
  },
  actions: {

  },
  modules: {
    userAuth,
    crud
  }
})

<template>
  <div class="image-wrapper">
    <v-img
      :src="hotspot.link"
      style="width: 100%"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-dialog v-model="dialog" overlay-opacity="0.95">
      <HotspotModal :spot="currentSpot"/>
    </v-dialog>

    <div v-for="spot in hotspot.hotspots" v-bind:key="spot.id">
      <a
        v-if="spot.resource_type === 'link'"
        :href="spot.url"
        target="_blank"
        rel="noopener noreferrer"
        v-bind:class="'play-box play-box-' + spot.id"
        v-bind:style="spot.styles"
      >
        <button class="play-btn">
          <v-icon color="white">mdi-link-variant</v-icon>
        </button>
      </a>

      <div
        v-else
        @click="showModal(spot)"
        v-bind:class="'play-box play-box-' + spot.id"
        v-bind:style="spot.styles"
      >
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <button class="play-btn" v-bind="attrs" v-on="on">
              <v-icon color="white">mdi-play</v-icon>
            </button>
          </template>
          <span>Click to see how it will look like</span>
        </v-tooltip>
      </div>
    </div>

  </div>
</template>

<script>
import HotspotModal from './HotspotModal'

export default {
  name: 'image-with-spots',
  components: { HotspotModal },
  props: ['hotspot'],
  data () {
    return {
      dialog: false,
      currentSpot: null
    }
  },
  methods: {
    showModal: function (spot) {
      this.currentSpot = spot
      this.dialog = true
    }
  }
}
</script>

<style scoped>

  .image-wrapper {
    position: relative;
  }

  @media (hover: hover) and (pointer: fine) {
    .play-box:hover {
      cursor: pointer;
      border: 1px solid #ED1C24;
      background: rgba(9, 11, 12, 0.5);
    }
  }

  .play-btn {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFFFFF;
    border-radius: 50%;
    position: absolute;
    color: white;
    top: 38%;
    left: 37%;
  }

  /*.big-btn {*/
  /*    width: 54px;*/
  /*    height: 54px;*/
  /*}*/

</style>

<template>
  <v-card class="pa-4 card-img-act">
    <v-row no-gutters>
      <v-col sm="8">
        <h4 class="mb-1 card-img-act__title">
          {{ data.prize }}
        </h4>
        <p class="card-img-act__description mb-2">
          End: {{ endTime }}
        </p>
        <v-card-actions v-show="editable" class="pa-0 card-img-act__actions">
          <v-btn
            text
            color="primary"
            @click="$emit('edit', $event)"
          >
            edit
          </v-btn>
          <v-btn
            text
            color="primary"
            @click="$emit('delete', $event)"
          >
            delete
          </v-btn>
        </v-card-actions>
      </v-col>
      <v-col sm="4">
        <ImageView :url="data.prize_image" class="card-img-act__img" />
<!--        <v-img-->
<!--          src="https://picsum.photos/400/500"-->
<!--          class="card-img-act__img"-->
<!--        />-->
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import ImageView from '../files/Image'

export default {
  name: 'Drawing',
  props: ['data', 'editable'],
  components: { ImageView },
  data: () => ({}),
  computed: {
    ...mapGetters([
      'currentBaseUrl'
    ]),
    endTime () {
      return moment(this.data.drawing_time).format('hh:mm a')
    }
  },
  methods: {}
}
</script>

<style lang="scss">
  .card-img-act {
    max-width: 450px;
    &__img {
      width: 90px;
      height: 90px;
      float: right;
    }

    &__actions {
      justify-content: space-between;
    }
  }
</style>

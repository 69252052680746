<template>
  <div>
    <h3 v-show="!speakerKitLink" class="my-3">Generate a Speaker Kit link:</h3>
    <CardTemplate v-if="speakerKitLink" title="Link To Speaker Kit" class="mb-6">
      <div class="d-flex justify-space-between">
        <a :href="speakerKitLink" target="_blank" rel="noopener noreferrer" style="font-size: 20px">
          Open link
        </a>
        <v-btn text @click="copyToClipboard(speakerKitLink)">
          <v-icon class="mr-2" color="primary">mdi-share</v-icon>
          {{copied ? 'Link copied to clipboard' : ' Click to copy link'}}
        </v-btn>
      </div>
    </CardTemplate>
    <v-btn v-else @click="generateCodes" class="mt-3 mb-8">
      <v-icon class="mr-2">mdi-draw</v-icon>
      Generate codes and link for Speaker
    </v-btn>
    <Preloader v-if="generating" class="my-3"/>
    <p v-show="errorMsg" class="red--text">Sorry, an error occurred. {{errorMsg}}</p>
    <hr>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import CardTemplate from '~src/components/common/Cards/CardTemplate'
import Preloader from '~src/components/common/preloader/Preloader'

export default {
  name: 'SpeakerKitManagement',
  props: ['id'],
  components: {
    Preloader,
    CardTemplate
  },
  data: () => ({
    speakerKitLink: null,
    generating: false,
    copied: false,
    errorMsg: null
  }),
  methods: {
    ...mapActions(['GET', 'POST']),
    generateCodes () {
      this.generating = true
      this.POST({
        route: `protected/event/${this.$router.currentRoute.params.id}/submission/${this.id}/code_set`
      })
        .then((res) => {
          if (res.status === 200) this.makeKit()
        })
        .catch((err) => {
          this.errorMsg = err.response.data.msg || ''
          this.generating = false
        })
    },
    makeKit: function () {
      this.GET({ route: `protected/event/${this.$router.currentRoute.params.id}/submission/${this.id}/code_set` })
        .then(({ data }) => {
          this.speakerKitLink = `${process.env.VUE_APP_BASE_URL.split('/api/')[0]}/#/${this.$router.currentRoute.params.id}/speaker-kit/${data.data.url}`
        })
        .catch(err => {
          this.errorMsg = err.response.data.msg || ''
          console.log(err)
        })
        .finally(() => {
          this.generating = false
        })
    },
    async copyToClipboard (code) {
      try {
        await navigator.clipboard.writeText(code)
        this.copied = true
        setTimeout(() => {
          this.copied = false
        }, 2000)
      } catch ($e) {
        console.log('Cannot copy')
      }
    }
  }
}
</script>

<style lang="scss"></style>

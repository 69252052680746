<template>
  <div class="d-flex schedule-wrapper">
    <v-card class="mt-6 pa-3 schedule">
      <v-progress-linear
        v-if="isScheduleUpdating"
        indeterminate
      />
      <h2 class="mb-3">
        <v-btn
          icon
          class="slot__close"
          @click="getAllData"
        >
          <v-icon>mdi-refresh</v-icon>
        </v-btn>
        Schedule
      </h2>
      <Preloader v-if="isSlotsLoading"/>

      <div class="schedule__slots">
        <div class="schedule__timeline ml-3">
          <div v-for="slot in slots.break_it" :key="slot.id + 'time'" class="schedule__timeline-item">
            <!--            FOR Getting first TRACK for time using  -->
            <!--          <div v-for="slot in slots[getFirstTrack(slots)]" :key="slot.id + 'time'" class="schedule__timeline-item">-->
            {{slot.start_time | moment}}
          </div>
        </div>

        <!--        BASIC VARIANT FOR VIEWING ALL TRACKS-->
        <!--        <div v-for="(list, track) in slots" :key="track" v-show="list && list.length" class="ml-6">
                  <Preloader v-if="slotsLoading[track]"/>
                  <div v-else-if="list && list.length">
                    <div class="mb-10"><b>{{makeFieldName(track)}}</b></div>
                    <div
                      v-for="slot in list"
                      :key="slot.id"
                      :id="slot.id"
                      @drop='onSlotDrop($event, slot.id, track)'
                      @dragover.prevent
                      @dragenter.prevent
                    >
                      <SlotCard :data="slot" @clearing="clearSlot"/>
                    </div>
                  </div>
                </div>-->

        <!--          VARIANT FOR VIEWING EACH TRACK HARDCODED  -->
        <Preloader v-if="slotsLoading.build_it"/>
        <div v-else>
          <div class="mb-10 ml-6"><b>{{makeFieldName('build_it')}}</b></div>
          <div v-for="(slot) in slots.build_it" :key="slot.id" class="ml-6">
            <div
              :id="slot.id"
              @drop='onSlotDrop($event, slot.id, "build_it")'
              @dragover.prevent
              @dragenter.prevent
            >
              <SlotCard :data="slot" @clearing="clearSlot"/>
            </div>
          </div>
        </div>
        <Preloader v-if="slotsLoading.break_it"/>
        <div v-else>
          <div class="mb-10 ml-6"><b>{{makeFieldName('break_it')}}</b></div>
          <div v-for="(slot) in slots.break_it" :key="slot.id" class="ml-6">
            <div
              :id="slot.id"
              @drop='onSlotDrop($event, slot.id, "break_it")'
              @dragover.prevent
              @dragenter.prevent
            >
              <SlotCard :data="slot" @clearing="clearSlot"/>
            </div>
          </div>
        </div>
        <div class="schedule__timeline ml-5 mr-2">
          <div v-for="slot in slots.show_it" :key="slot.id + 'time'" class="schedule__timeline-item">
            {{slot.start_time | moment}}
          </div>
        </div>
        <Preloader v-if="slotsLoading.show_it"/>
        <div v-else>
          <div class="mb-10 ml-6"><b>{{makeFieldName('show_it')}}</b></div>
          <div v-for="(slot) in slots.show_it" :key="slot.id" class="ml-6">
            <div
              :id="slot.id"
              @drop='onSlotDrop($event, slot.id, "show_it")'
              @dragover.prevent
              @dragenter.prevent
            >
              <SlotCard :data="slot" @clearing="clearSlot"/>
            </div>
          </div>
        </div>
        <Preloader v-if="slotsLoading.firetalks"/>
        <div v-else>
          <div class="mb-10 ml-6"><b>{{makeFieldName('firetalks')}}</b></div>
          <div v-for="(slot) in slots.firetalks" :key="slot.id" class="ml-6">
            <div
              :id="slot.id"
              @drop='onSlotDrop($event, slot.id, "firetalks")'
              @dragover.prevent
              @dragenter.prevent
            >
              <SlotCard :data="slot" @clearing="clearSlot"/>
            </div>
          </div>
        </div>

      </div>
    </v-card>
    <v-card v-if="submissions && submissions.length" class="mt-6 pa-3 ml-2 talks">
      <div class="mb-4 d-flex flex-wrap justify-space-between align-center">
        <h2>Talks</h2>
        <v-btn-toggle
          v-model="talksAlignment"
          mandatory
          dense
          @change="changeTalkAlign"
        >
          <v-btn value="start">Top</v-btn>
          <v-btn value="center">Center</v-btn>
          <v-btn value="end">Bottom</v-btn>
        </v-btn-toggle>
      </div>
      <Preloader v-if="isSubmissionsLoading"/>
      <div
        v-else
        @drop='onSubmissionDrop($event, 1)'
        @dragover.prevent
        @dragenter.prevent
        id="talks-wrapper"
      >
        <div
          v-for="submission in submissions"
          :key="submission.id"
          :id="submission.id"
          draggable="true"
          @dragstart='startDrag($event, submission)'
        >
          <TalkCard :data="submission"/>
        </div>
      </div>
    </v-card>
  </div>

</template>

<script>
import moment from 'moment'
import { mapActions } from 'vuex'
import { makeFieldName } from '~src/assets/js/utils'
import Preloader from '~src/components/common/preloader/Preloader'
import SlotCard from './SlotCard'
import TalkCard from './TalkCard'

export default {
  name: 'Schedule',
  props: ['tracks'],
  components: {
    Preloader,
    SlotCard,
    TalkCard
  },
  data: () => ({
    talksAlignment: 'top',
    isSlotsLoading: false,
    isSubmissionsLoading: false,
    isScheduleUpdating: false,
    submissions: undefined,
    slotsLoading: {},
    slots: {}
  }),
  created () {
    this.getAllData()
  },
  filters: {
    moment: function (date) {
      return moment.parseZone(date).format('hh:mm a')
    }
  },
  methods: {
    ...mapActions([
      'GET', 'PUT', 'DELETE'
    ]),
    makeFieldName,
    getFirstTrack (obj) {
      return Object.keys(obj)[0]
    },
    changeTalkAlign (align) {
      if (document.getElementById('talks-wrapper')) document.getElementById('talks-wrapper').style.justifyContent = align
    },
    startDrag: (evt, item) => {
      console.log(item)
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('item', JSON.stringify(item))
    },
    onSlotDrop (evt, slotId, track) {
      const item = evt.dataTransfer.getData('item')
      console.log('1 onDrop', JSON.parse(item))
      this.updateSlot(slotId, JSON.parse(item), track)
      // const item = this.items.find(item => item.id == itemID)
      // item.list = list
    },
    onSubmissionDrop (evt, list) {
      const itemID = evt.dataTransfer.getData('itemID')
      console.log('1 onDrop', itemID)

      // const item = this.items.find(item => item.id == itemID)
      // item.list = list
    },
    updateSlot: function (slot, talk, track) {
      this.isScheduleUpdating = true
      this.GET({ route: `/event/${this.$router.currentRoute.params.id}/schedule_slot/${slot}/talk/${talk.id}` })
        .then(({ data }) => {
          console.log('update slot', data)
          this.getSlots(track)
          this.getSubmissions()
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isScheduleUpdating = false
        })
    },
    clearSlot: function ({ slot, talk, track }) {
      this.isScheduleUpdating = true
      this.DELETE({
        route: `/event/${this.$router.currentRoute.params.id}/schedule_slot/${slot}/talk/${talk}`
      })
        .then(() => {
          this.getSlots(track)
          this.getSubmissions()
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isScheduleUpdating = false
        })
    },
    getAllData () {
      this.getSubmissions()
      this.isSlotsLoading = true
      this.tracks.map((i) => {
        this.slots[`${i}`] = []
        this.slotsLoading[`${i}`] = false
        this.getSlots(i)
      })
    },
    getSlots: function (track) {
      this.slotsLoading[track] = true
      this.GET({ route: `/event/${this.$router.currentRoute.params.id}/track/${track}/schedule?sorting=true` })
        .then(({ data }) => {
          // this.slots[track] = data.data
          // this.$set(this.slots, track, data.data)
          this.slots = Object.assign({}, this.slots, { [track]: data.data })
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isSlotsLoading = false
          // this.slotsLoading[track] = false
          // this.$set(this.slotsLoading, track, false)
          this.slotsLoading = Object.assign({}, this.slotsLoading, { [track]: false })
        })
    },
    getSubmissions: function () {
      this.isSubmissionsLoading = true
      this.GET({ route: `/event/${this.$router.currentRoute.params.id}/submission/unscheduled` })
        .then(({ data }) => {
          this.submissions = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isSubmissionsLoading = false
        })
    }
  }
}
</script>

<style scoped lang="scss">
  @import '~src/assets/scss/_variables';

  .schedule-wrapper {
    @media (min-width: $mob-md) {
      margin-right: -2%;
      margin-left: -2%;
    }
    @media (min-width: 1500px) {
      margin-right: -5%;
      margin-left: -5%;
    }
    @media (min-width: 1700px) {
      margin-right: -15%;
      margin-left: -15%;
    }
    @media (min-width: $mob-lg) {
      margin-right: -1.5%;
      margin-left: -1.5%;
    }
  }

  .schedule {
    overflow-x: scroll;
    width: 100%;

    &__timeline {
      margin-top: 5%;
      min-width: 70px;

      &-item {
        padding-bottom: 114px;
      }
    }

    &__slots {
      display: flex;
    }
  }

  #talks-wrapper {
    height: 99%;
    display: flex;
    flex-direction: column;
  }

  .talks {
    width: 35%;
    /*    width: 100%;
        @media (min-width: $mob-sm) {
          width: 35%;
        }*/
  }
</style>

<template>
  <div>
    <PageTitle title="Home" id="home"/>
<!--    <ModerationAlerts/>-->
    <v-container class="pa-10">
      <div id="events" class="mb-6">
        <Table
          :loading="isEventsLoading"
          objectName="events"
          :data="events"
          :fields="eventFields"
          :tableHeader="tableHeader('Events')"
          url='/event'
          :refresh="getEvents"
          :rowClick="rowClick"
          :allowCreation=true
        />
      </div>
      <v-spacer></v-spacer>
      <div id="locations">
        <Table
          :loading="isLocationsLoading"
          objectName="locations"
          :data="locations"
          :fields="locationFields"
          :tableHeader="tableHeader('Locations')"
          url='/location'
          :refresh="getLocations"
          :rowClick="rowClick"
          :allowCreation=true
        />
      </div>
      <v-spacer></v-spacer>
      <div id="venues">
        <VenueTable/>
      </div>
<!--      <v-spacer></v-spacer>
      <div id="users">
        <UsersTable/>
      </div>-->
<!--      <v-spacer></v-spacer>
      <div id="groups">
        <Table
          v-slot="{ editedItem, close }"
          :loading="loading"
          objectName="groups"
          :data="groups"
          :noIdMode="true"
          :url='`/admin/p/group/pid/$/e/groupacl`'
          :tableHeader="tableHeader('Groups')"
          :refresh="getGroups"
          :allowCreation=true
        >
          &lt;!&ndash;    :name="editedItem.name"          &ndash;&gt;
          <GroupModal
            :editedItem='editedItem'
            :close="close"
            @tableUpdated="getGroups"
            style="width: 1000px"
          />
        </Table>
      </div>-->
      <v-spacer></v-spacer>
      <div id="blogposts">
        <BlogPostsTable/>
      </div>
      <v-spacer></v-spacer>
      <div id="workshops">
        <WorkshopsTable :rowClick="rowClick" />
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
// import ModerationAlerts from './ModerationAlerts'
import Table from '~src/components/common/table/Table'
// import UsersTable from './users/UsersTable'
// import GroupModal from './users/group-create-edit-modal'
import BlogPostsTable from './blogposts/BlogPostsTable'
import WorkshopsTable from './workshops/WorkshopsTable'
import VenueTable from './venue/VenueTable'

export default {
  name: 'AdminHome',
  components: {
    // ModerationAlerts,
    BlogPostsTable,
    VenueTable,
    WorkshopsTable,
    // UsersTable,
    PageTitle,
    Table
    // GroupModal
  },
  props: {},
  data () {
    return {
      tableHeaders: jsonHeaders,
      isEventsLoading: false,
      isLocationsLoading: false,
      locations: undefined,
      locationFields: undefined,
      events: undefined,
      eventFields: undefined
      // groups: undefined
    }
  },
  computed: {
    hash () {
      return this.$route.hash.split('#')[1]
    }
  },
  created () {
    this.getEvents()
    this.getEventFields()
    this.getLocations()
    this.getLocationFields()
    // this.getGroups()
    // this.getGroupsFields()
  },
  watch: {
    hash (value) {
      this.scrollToTable(value)
    }
  },
  updated () {
    this.scrollToTable(this.hash)
  },
  methods: {
    ...mapActions([
      'GET', 'POST'
    ]),
    tableHeader (title) {
      return jsonHeaders[title]
    },
    scrollToTable (hash) {
      if (hash &&
          this.events &&
          this.locations &&
          !this.loading) {
        const nums = /\d+/g
        const elem = nums.test(this.hash)
          ? document.getElementById('home')
          : document.getElementById(this.hash)

        elem.scrollIntoView({ behavior: 'smooth' })
      }
    },
    rowClick (item, objectName) {
      this.$router.push(`/admin/${objectName}/${item.id}`)
    },
    getEvents: function () {
      this.isEventsLoading = true
      this.GET({ route: '/event' })
        .then(({ data }) => {
          this.events = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isEventsLoading = false
        })
    },
    getLocations: function () {
      this.isLocationsLoading = true
      this.GET({ route: 'location' })
        .then(({ data }) => {
          this.locations = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLocationsLoading = false
        })
    },
    /*    getGroups: function () {
      this.loading = true
      this.GET({ route: '/admin/acl/groups' })
        .then(({ data }) => {
          console.log('/admin/acl/groups', data)
          this.groups = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.loading = false
        })
    }, */
    getEventFields: function () {
      this.isEventsLoading = true
      this.GET({ route: '/fields/event' })
        .then(({ data }) => {
          this.eventFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isEventsLoading = false
        })
    },
    // getGroupsFields: function () {
    //   this.GET({ route: '/admin/fields/groupacl' })
    //     .then(({ data }) => {
    //       console.log(data)
    //       this.groupFields = data.data
    //       this.loading = false
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // },
    getLocationFields: function () {
      this.isLocationsLoading = true
      this.GET({ route: '/fields/location' })
        .then(({ data }) => {
          this.locationFields = data.data
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isLocationsLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
</style>

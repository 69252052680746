<template>
  <v-card class="card-list pb-1 pt-6">
    <p class="card-list__title">
      {{ title }}
    </p>
    <p v-if="label" class="card-list__label ma-0">
      {{ label }}
    </p>
    <v-row class="px-5">
      <v-col sm="12" md="6">
        <ImageView style="height: 100%"/>
      </v-col>
      <v-col sm="12" md="6">
        <v-list class="mb-4">
          <v-list-item
            v-for="item in listReady"
            :key="item[0]"
            class="card-list__item"
          >
            <v-row justify="space-between" no-gutters>
              <v-col
                v-for="string in item"
                :key="string"
                class="card-list__col"
              >
                {{ string }}
              </v-col>
            </v-row>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>

    <v-card-actions>
      <slot/>
    </v-card-actions>
  </v-card>
</template>

<script>
import ImageView from '../../common/files/Image'

export default {
  name: 'CardList',
  props: ['title', 'image', 'list', 'isUpcoming', 'label'],
  components: { ImageView },
  data: () => ({}),
  computed: {
    listReady () {
      return Object.entries(this.list)
    },
    buttonText () {
      return this.isUpcoming
        ? 'apply'
        : 'download attendee list'
    }
  },
  methods: {}
}
</script>

<style lang="scss">

  .card-list {
    position: relative;

    &__title {
      font-size: 20px;
      line-height: 23px;
      font-weight: 500;
      padding-left: 16px;
    }

    &__item {
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      &:last-child {
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }
    }

    &__col {
      flex-grow: 0;
      flex-basis: unset;
      width: auto;
      font-weight: 300;
      font-size: 14px;
      line-height: 16px;
      text-transform: capitalize;
    }

    &__label {
      position: absolute;
      top: 22px;
      right: 16px;
      background-color: rgba(100, 181, 246, 0.3);
      border-radius: 3px;
      padding: 9px 12px;
      font-weight: 300;
      font-size: 12px;
      line-height: 14px;
    }
  }
</style>

<template>
  <v-container class="po-0">
    <v-row>
      <v-col sm="12" lg="6">
        <CardCircleChart
          v-if="circleChartData.length"
          title="Amount attendee"
          :data="circleChartData"
          :description="circleChartDescription"
          :chart-backgrounds="['#2196F3', '#E3F2FD']"
        />
        <v-row v-else justify="center">
          <v-progress-circular
            color="primary"
            indeterminate
          />
        </v-row>
      </v-col>
      <v-col sm="12" lg="6">
        <CardBarChart
          v-if="barChartData.length"
          title="Attendee by type"
          :description="barChartDescription"
          :chart-backgrounds="['#2196F3', '#42A5F5', '#64B5F6', '#90CAF9', '#BBDEFB']"
          :data="barChartData"
        />
        <v-row v-else justify="center">
          <v-progress-circular
            color="primary"
            indeterminate
          />
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12" lg="8">
        <CardBubbles
          v-if="!isEmpty(sponsorsByType)"
          title="Sponsors Level"
          :data="sponsorsByType"
        />
        <v-row v-else justify="center">
          <v-progress-circular
            color="primary"
            indeterminate
          />
        </v-row>
      </v-col>
    <v-col sm="12" lg="4">
      <CardStats
        v-if="attendeesToday"
        :left-title="cardStatsTitle"
        :quantity="attendeesToday"
        description="was added today"
        class="stats-card"
      />
      <v-row v-else justify="center">
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </v-row>
    </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { isEmpty } from '~src/assets/js/utils'
import CardCircleChart from '~src/components/common/Cards/CardСircleChart'
import CardBarChart from '~src/components/common/Cards/CardBarChart'
import CardBubbles from './CardBubblesSponsor'
import CardStats from '~src/components/common/Cards/CardStats'

export default {
  name: 'Analytics',
  props: {
    attendeesToday: {
      type: String,
      default: ''
    },
    sponsorsByType: {
      type: Object,
      default: () => ({})
    },
    attendeesByType: {
      type: Object,
      default: () => ({})
    },
    attendeesComp: {
      type: Object,
      default: () => ({})
    },
    circleChartDescription: {
      type: Array,
      default: () => []
    },
    cardStatsTitle: {
      type: String,
      default: ''
    }
  },
  components: {
    CardCircleChart,
    CardBarChart,
    CardBubbles,
    CardStats
  },
  data: () => ({}),
  computed: {
    barChartDescription () {
      return Object.keys(this.attendeesByType).map(cur => {
        if (cur === 'basic') return 'All attendees'
        if (cur === 'silver') return 'Silver sponsor attendees'
        if (cur === 'gold') return 'Gold sponsor attendees'
      })
    },
    barChartData () {
      return Object.values(this.attendeesByType)
    },
    circleChartData () {
      return Object.values(this.attendeesComp)
    }
  },
  methods: {
    isEmpty
  }
}
</script>

<style lang="scss">
// @import 'path/_variables';
.stats-card {
  height: 295px;
}
</style>

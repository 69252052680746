<template>
  <CardTemplate title="Send CPE report notification to attendees" class="my-6">
    <CardTemplate title="Attendees of Virtual Event" class="mb-6">
      <p>Attendees of virtual event are attendees, who haven't redeemed their tickets during live event but have accessed virtual platform before or during event</p>
      <div v-show="!reportCPESentVirtual">
        <v-btn @click="sendVirtual" class="mt-3 mb-8">
          Send to attendees of virtual event
        </v-btn>
      </div>
      <Preloader v-if="sendingVirtual" class="my-3"/>
        <div v-if="reportCPESentVirtual">
          <div v-for="report in reportCPESentVirtual" :key="report">
            {{report}}
          </div>
        </div>
        <!-- <div v-if="reportCPESentVirtual.length === 0">
            No attendees
        </div> -->
      <p v-show="errorMsgVirtual" class="red--text">Sorry, an error occurred. {{errorMsgVirtual}}</p>
    </CardTemplate>
    <hr>
    <CardTemplate title="Attendees of Live Event" class="mb-6">
      <div v-show="!reportCPESentLive">
        <v-btn @click="sendLive" class="mt-3 mb-8">
          Send to attendees of live event
        </v-btn>
      </div>
      <Preloader v-if="sendingLive" class="my-3"/>
        <div v-if="reportCPESentLive">
          <div v-for="report in reportCPESentLive" :key="report">
            {{report}}
          </div>
        </div>
        <!-- <div v-if="reportCPESentLive.length === 0">
          No attendees
        </div> -->
      <p v-show="errorMsgLive" class="red--text">Sorry, an error occurred. {{errorMsgLive}}</p>
    </CardTemplate>
  </CardTemplate>
</template>

<script>
import { mapActions } from 'vuex'
import CardTemplate from '~src/components/common/Cards/CardTemplate'
import Preloader from '~src/components/common/preloader/Preloader'

export default {
  name: 'SendCPE',
  props: [],
  components: {
    Preloader,
    CardTemplate
  },
  data: () => ({
    reportCPESentVirtual: null,
    reportCPESentLive: null,
    sendingVirtual: false,
    sendingLive: false,
    errorMsgVirtual: null,
    errorMsgLive: null
  }),
  methods: {
    ...mapActions(['GET']),
    sendVirtual () {
      this.sendingVirtual = true
      this.GET({
        route: `ticketing/event/${this.$router.currentRoute.params.id}/cpe_email_blast_virtual`
      })
        .then((res) => {
          if (res.status === 200) this.reportCPESentVirtual = res.data
          this.sendingVirtual = false
        })
        .catch((err) => {
          this.errorMsgVirtual = err.response.data.msg || ''
          this.sendingVirtual = false
        })
    },
    sendLive () {
      this.sendingLive = true
      this.GET({
        route: `ticketing/event/${this.$router.currentRoute.params.id}/cpe_email_blast_live`
      })
        .then((res) => {
          if (res.status === 200) this.reportCPESentLive = res.data
          this.sendingLive = false
        })
        .catch((err) => {
          this.errorMsgLive = err.response.data.msg || ''
          this.sendingLive = false
        })
    }
  }
}
</script>

<style lang="scss"></style>

<template>
  <Table
    :loading="loading"
    objectName="attendees"
    :data="attendees"
    :tableHeader="headers"
    :refresh="getAttendees"
  />
</template>

<script>
import { mapActions } from 'vuex'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import Table from '~src/components/common/table/Table'

export default {
  name: 'AttendeesTable',
  components: { Table },
  props: [],
  data () {
    return {
      loading: false,
      headers: jsonHeaders.Attendees,
      attendees: undefined,
      attendeesFields: null
    }
  },
  created () {
    this.getAttendees()
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getAttendees: function () {
      this.loading = true
      this.GET({ route: `/ticketing/event/${this.$route.params.id}/attendee` })
        .then(({ data }) => {
          this.attendees = data.data
          this.getAttendeesFields()
        })
        .catch(err => {
          console.log(err)
        })
    },
    getAttendeesFields: function () {
      // this.GET({ route: '/fields/attendee' })
      //   .then(({ data }) => {
      //     this.attendeesFields = data.data
      //     console.log('Attendees', data.data)
      //   })
      //   .catch(err => {
      //     console.log(err)
      //   })
      //   .finally(() => {
      this.loading = false
      // })
    }
  }
}
</script>

<template>
  <v-form
    ref="nestedForm"
    @submit.prevent="submit"
  >
    <v-card class="mt-2 mr-2">
      <v-card-text>
        <div v-for="field in schema" v-bind:key="field.f_name">

          <div v-if="field.type === 'str'">
            <div v-if="field.metadata.image">
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <ImageUpload
                :id="field.f_name"
                v-model="form[field.f_name]"
                :url="form[field.f_name]"
                @imageUrl="(data)=>{form[field.f_name]=data}"
                style="max-width: 150px"
              />
              <!--              :url="editedItem[field.f_name]"-->
            </div>
            <div v-else-if="field.metadata.editor" class="editor-item pt-5">
              <label class="v-label theme--light" :for="field.f_name">{{makeFieldName(field.f_name)}}</label>
              <QuillEitor
                class="pt-2"
                :id="field.f_name"
                v-model="form[field.f_name]"/>
            </div>
            <div v-else-if="field.metadata.email">
              <v-text-field
                v-model="form[field.f_name]"
                :label="makeFieldName(field.f_name)"
                :rules="[rules.required, rules.email]"
              ></v-text-field>
            </div>
            <div v-else>
              <v-text-field
                v-model="form[field.f_name]"
                :label="makeFieldName(field.f_name)"
                :rules="field.required ? [rules.required] : []"
              ></v-text-field>
            </div>

            <!--

                        <div v-else-if="field.metadata.foreign_key" class="form-item mr-5">
                          <v-select
                            v-model="editedItem[field.f_name]"
                            :items="field.choices"
                            item-text="name"
                            item-value="id"
                            :label="makeFieldName(field.f_name)"
                            :rules="field.required ? [rules.required] : []"
                          ></v-select>
                        </div>
                   -->
          </div>
          <div v-else-if="field.type === 'bool'">
            <v-checkbox
              :value=false
              v-model="form[field.f_name]"
              :label="makeFieldName(field.f_name)"
            >
            </v-checkbox>
          </div>
          <div v-else-if="field.type === 'float'">
            <v-text-field
              v-model.number="form[field.f_name]"
              :label="makeFieldName(field.f_name)"
              type="number"
              maxlength="10"
              :rules="field.required ? rules.required : []"
            ></v-text-field>
          </div>
          <div v-else-if="field.type === 'int'">
            <v-text-field
              v-model.number="form[field.f_name]"
              :label="makeFieldName(field.f_name)"
              type="number"
              maxlength="10"
              :rules="field.required ? [rules.required, rules.noFloat] : [rules.noFloat]"
            ></v-text-field>
          </div>
          <div v-else-if="field.type === 'datetime'">
            <div class="my-5 v-input__control">
              <label class="v-label theme--light my-3" :for="field.f_name">
                <v-icon class="mr-3">mdi-calendar-clock</v-icon>
                {{makeFieldName(field.f_name)}}
              </label>
              <datetime :id="field.f_name"
                        v-model="form[field.f_name]"
                        type="datetime"
                        input-style="border: 1px solid #9e9e9e; padding: 10px 5px 10px 8px"
                        :minute-step="15"
                        use12-hour
                        value-zone="local"
                        zone="local"
              >
              </datetime>
            </div>
          </div>
          <div v-else-if="field.type === 'list'" class="pt-5">
            <div v-if="field.f_name === 'social_media'">
              <SocialMedia
                :value="form[field.f_name]"
                :v-model="form[field.f_name]"
                @social="(data)=>{form[field.f_name]=data}"
              />
            </div>
          </div>
          <div v-else class=" mr-5">
            {{field}}
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue darken-1"
          text
          small
          :disabled="loading"
          @click="reset"
        >
          Reset
        </v-btn>
        <!--        for testing-->
        <!--        <v-btn
                  text
                  small
                  type="submit"
                  color="blue darken-1"
                  :loading="loading"
                >
                  {{ isNewBlock ? 'Create' : 'Edit'}}
                </v-btn>-->
      </v-card-actions>
    </v-card>
  </v-form>

</template>

<script>
import { makeFieldName } from '~src/assets/js/utils'
import rules from '~src/assets/js/validationRules'
import ImageUpload from '../files/imageUpload'
import QuillEitor from '~src/components/common/quillEditor/quillEditor'
import SocialMedia from '../socialMedia/social-media'

export default {
  name: 'nested-fields',
  props: ['schema', 'field'],
  components: {
    SocialMedia,
    ImageUpload,
    QuillEitor
  },
  data () {
    return {
      loading: false,
      // isNewBlock: true,
      form: null,
      rules
    }
  },
  created () {
    // console.log('this.field', this.field)
    // console.log('this.schema', this.schema)
    if (this.field) {
      this.form = this.field
      // this.isNewBlock = false
    } else {
      const obj = {}
      this.schema.map(i => {
        obj[i.f_name] = ''
      })
      this.form = obj
    }
  },
  watch: {
    form: {
      handler (form) {
        console.log(form)
        // let arr = []
        // arrayItems.map(i => { arr = [...arr, i.value] })
        this.$emit('nestedForm', form)
      },
      deep: true
    }
  },
  methods: {
    makeFieldName,
    reset: function () {
      this.$refs.nestedForm.reset()
    }
    // for testing
    // submit: function () {
    //   if (this.$refs.nestedForm.validate()) {
    //     console.log(this.form)
    //     // this.$emit('nestedForm', this.form)
    //     this.isNewBlock = false
    //   }
    // }
  }
}
</script>

<style scoped lang="scss">
</style>

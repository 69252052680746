<template>
  <div class="upcoming-event-short">
    <PageTitle :title="eventInfo.name">
      <ApplyForm
        :is-apply-popup-shown="isApplyPopupShown"
        :event="{
          id: eventInfo.id,
          name: eventInfo.name
        }"
      />
    </PageTitle>
    <div class="wrapper">
      <v-row
        v-if="isEventLoading"
        justify="center"
        class="mt-6"
      >
        <v-progress-circular
          color="primary"
          indeterminate
        />
      </v-row>
      <template v-else>
        <v-tabs>
          <v-tab>
            Basic information
          </v-tab>
          <v-tab>
            Analytics
          </v-tab>

          <v-tab-item>
            <EventCard :data="eventInfo" />
          </v-tab-item>
          <v-tab-item>
            <Analytics
              :attendees-today="`+${attendeesTodayCount}`"
              :sponsors-by-type="sponsorsByType"
              :attendees-by-type="attendeesByType"
              :attendees-comp="attendeesComp"
              :circle-chart-description="circleChartDescription"
              :card-stats-title="cardStatsTitle"
            />
          </v-tab-item>
        </v-tabs>
      </template>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import Analytics from '~src/components/sponsor/common/Analytics'
import EventCard from '../../common/Cards/EventCard'
import ApplyForm from '../common/applyForm'

export default {
  name: 'UpcomingEventShort',
  props: {},
  components: {
    PageTitle,
    Analytics,
    EventCard,
    ApplyForm
  },
  data: () => ({
    isApplyPopupShown: false,
    email: '',
    useEmailFromAcc: false,
    isEventLoading: false,
    eventInfo: {},
    attendeesTodayCount: null,
    sponsorsByType: {},
    attendeesByType: {},
    attendeesComp: {},
    circleChartDescription: [
      'Expected attendee',
      'Current registration'
    ],
    cardStatsTitle: 'Attendee today'
  }),
  computed: {
    eventId () {
      return this.$route.params.id
    }
  },
  created () {
    Promise.all([
      this.getEventInfo(this.eventId),
      this.getAttendeesToday(this.eventId),
      this.getAttendeesByType(this.eventId)
    ])
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getEventInfo (id) {
      this.isEventLoading = true

      this.GET({ route: `event/${id}` })
        .then(({ data }) => { this.eventInfo = data.data })
        .finally(() => { this.isEventLoading = false })
    },
    getAttendeesToday (id) {
      this.GET({ route: `admin/event/${id}/attendees_today` })
        .then(({ data }) => { this.attendeesTodayCount = data.data.count })
    },
    getAttendeesByType (id) {
      this.GET({ route: `admin/event/${id}/attendees_sponsors_summary` })
        .then(({ data }) => {
          this.attendeesComp = {
            expected: data.data.event.expected_atendees,
            current: data.data.event.current_attendees
          }
          this.attendeesByType = data.data.attendees_by_type
          this.sponsorsByType = data.data.sponsors_by_type

          // set missing fields to sponsors by type
          if (!this.sponsorsByType.basic) {
            this.$set(this.sponsorsByType, 'basic', this.sponsorsByType.silver + this.sponsorsByType.gold)
          } else if (!this.sponsorsByType.silver) {
            this.$set(this.sponsorsByType, 'silver', 0)
          } else if (!this.sponsorsByType.gold) {
            this.$set(this.sponsorsByType, 'gold', 0)
          }

          // set missing fields to attendees by type
          if (!this.attendeesByType.basic) this.$set(this.attendeesByType, 'basic', 0)
          if (!this.attendeesByType.silver) this.$set(this.attendeesByType, 'silver', 0)
          if (!this.attendeesByType.gold) this.$set(this.attendeesByType, 'gold', 0)
        })
    }
  }
}
</script>

<style lang="scss">
// @import 'path/_variables';
.upcoming-event-short {
  .v-tabs-bar {
    box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.14);
    margin-bottom: 8px;
    padding: 0 12px;
  }
  .v-slide-group__wrapper {
    max-width: 1185px;
    margin: 0 auto;
  }
}

.apply-popup {
  &__heading {
    font-weight: 300;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.05em;
  }

  &__buttons {
    justify-content: space-between;
  }
}
</style>

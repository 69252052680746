<template>
  <v-app class="app-main">
    <router-view/>
  </v-app>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
  .app-main {
    margin-top: 0;
  }

  ::-webkit-scrollbar {
    width: 15px;
    height: 10px;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #1976d2;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  }

  .v-application .headline {
    word-break: break-word;
  }

  .main-grey-header {
    padding: 9px 0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 16px;
    line-height: 1;
    min-height: 8px;
    transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
  }

  .v-dialog {
    width: initial !important;
  }

</style>

<template>
  <div class="archive">
    <PageTitle title="Archive" />
    <v-container>
      <Table
        objectName="past events"
        :data="eventsArchive"
        :tableHeader="tableHeader"
        :loading="isListLoading"
        :rowClick="goToEvent"
      />
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import Table from '~src/components/common/table/Table'

export default {
  name: 'EventsArchive',
  props: {},
  components: {
    PageTitle,
    Table
  },
  created () {
    this.getEventsArchive()
  },
  data: () => ({
    jsonHeaders,
    eventsArchive: [],
    noEvents: false,
    isListLoading: false
  }),
  computed: {
    tableHeader () {
      return this.jsonHeaders.EventsArchive
    }
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    getEventsArchive () {
      this.isListLoading = true

      this.GET({ route: '/public/event/past_events' })
        .then(({ data }) => { this.eventsArchive = data.data })
        .catch(() => { this.noEvents = true })
        .finally(() => { this.isListLoading = false })
    },
    goToEvent (item, objectName) {
      this.$router.push(`/sponsor/past/${item.id}`)
    }
  }
}
</script>

<style lang="scss">
// @import 'path/_variables';
</style>

<template>
  <v-card class="px-5">
    <v-form
      ref="form"
      @submit.prevent="saveUser"
    >
      <v-card-title>
        <span class="headline">{{titleAction}}</span>
      </v-card-title>

      <v-card-text>
        <v-row>
          <v-col sm="12" md="6">
            <ImageUpload
              v-model="form.image"
              :url="item.image"
              @imageUrl="(data)=>{form.image = data}"
              style="width: 300px"
            />
          </v-col>
          <v-col sm="12" md="6">
            <v-text-field
              :loading="loading"
              :readonly="editMode"
              v-model="form.display_name"
              label="Username"
              outlined
              :rules="[rules.required, rules.min]"
            />
            <v-text-field
              :loading="loading"
              v-model="form.email"
              label="Email"
              outlined
              :rules="[rules.required, rules.email]"
            />
            <v-text-field
              v-if="!editMode"
              v-model="form.password"
              label="Password"
              outlined
              :append-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="[rules.required, rules.password]"
              :type="showPassw ? 'text' : 'password'"
              @click:append="showPassw = !showPassw"
            />
<!--            <span class="grey&#45;&#45;text"><sup>*</sup> display name that will be used as login</span>-->
          </v-col>
        </v-row>
        <div class="my-3">Basic info</div>
        <v-row>
          <v-col sm="12" md="6">
            <v-text-field
              :loading="loading"
              v-model="form.first_name"
              label="First name"
              outlined
              :rules="rules.required"
            />
            <v-text-field
              :loading="loading"
              v-model="form.last_name"
              label="Last name"
              outlined
              :rules="rules.required"
            />
          </v-col>
          <v-col sm="12" md="6">
            <v-text-field
              :loading="loading"
              v-model="form.organization"
              label="Company"
              outlined
            />
            <v-text-field
              :loading="loading"
              v-model="form.job_title"
              label="Job title"
              outlined
            />
          </v-col>
        </v-row>
        <!--FOR FUTURE-->
        <!--        <v-checkbox-->
        <!--          v-model="form.askToChange"-->
        <!--          label="Ask to change password after login"-->
        <!--          class="mt-0"-->
        <!--        ></v-checkbox>-->

        <div class="my-3">Contact</div>
        <v-text-field
          :loading="loading"
          v-model="form.phone"
          label="Phone"
          outlined
          :rules="rules.phone"
        />
        <!--FOR FUTURE-->
        <!--        <v-text-field-->
        <!--          v-model="form.email"-->
        <!--          label="Email"-->
        <!--          outlined-->
        <!--          :rules="[rules.required, rules.email]"-->
        <!--        />-->
        <SocialMedia
          :value="item.social_media"
          :v-model="form.social_media"
          @social="(data)=>{form.social_media=data}"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="blue darken-1"
          text
          :disabled="updating"
          @click="close"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          type="submit"
          color="blue darken-1"
          :loading="updating"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { mapActions } from 'vuex'
import rules from '~src/assets/js/validationRules'
import ImageUpload from '~src/components/common/files/imageUpload'
import SocialMedia from '../../common/socialMedia/social-media'

export default {
  name: 'user-modal',
  components: { SocialMedia, ImageUpload },
  props: ['item', 'close'],
  data () {
    return {
      loading: false,
      updating: false,
      editMode: false,
      form: {
        display_name: '',
        first_name: '',
        last_name: '',
        image: '',
        organization: '',
        job_title: '',
        phone: '',
        public_phone: '',
        email: '',
        public_email: '',
        password: '',
        social_media: ''
        // askToChange: true
      },
      showPassw: false,
      rules
    }
  },
  created () {
    console.log('this.item', this.item)
    if (this.item) {
      // this.getUser()
      this.form = {
        display_name: this.item.display_name,
        email: this.item.email,
        first_name: this.item.first_name,
        id: this.item.display_name,
        image: this.item.image,
        job_title: this.item.job_title,
        last_name: this.item.last_name,
        organization: this.item.organization,
        phone: this.item.phone,
        social_media: this.item.social_media,
        public_email: this.item.public_email,
        public_phone: this.item.public_phone
      }
      this.editMode = true
    }
  },
  watch: {
    item () {
      if (this.item) {
        // this.getUser()
        this.form = {
          display_name: this.item.display_name,
          email: this.item.email,
          first_name: this.item.first_name,
          id: this.item.display_name,
          image: this.item.image,
          job_title: this.item.job_title,
          last_name: this.item.last_name,
          organization: this.item.organization,
          phone: this.item.phone,
          social_media: this.item.social_media,
          public_email: this.item.public_email,
          public_phone: this.item.public_phone
        }
        this.editMode = true
      } else {
        console.log('else')
        this.$refs.form.reset()
        this.editMode = false
      }
    }
  },
  computed: {
    titleAction () {
      return this.item ? 'Edit user' : 'Create user'
    }
  },
  methods: {
    ...mapActions([
      'GET', 'PUT', 'POST'
    ]),
    /*    getUser: function () {
      this.loading = true
      this.GET({ route: `admin/e/profile/eid/${this.item.username}` })
        .then(({ data }) => {
          console.log(data)
          this.form = {
            // askToChange: true,
            image: data.data.image,
            display_name: data.data.display_name,
            id: data.data.display_name,
            first_name: data.data.first_name,
            last_name: data.data.last_name,
            organization: data.data.organization,
            job_title: data.data.job_title,
            email: data.data.email,
            social_media: data.data.social_media,
            phone: data.data.phone
          }
          // id: "alina.yeganian"
        })
        .catch(err => {
          console.log(err)

          // this.errorUser = true
        })
        .finally(() => {
          this.loading = false
        })
    }, */
    saveUser: function () {
      if (this.$refs.form.validate()) {
        this.updating = true
        console.log('form', this.form)
        this.item ? this.editUser() : this.createUser()
      }
    },
    editUser: function () {
      this.PUT({
        route: `/user/${this.item.display_name}`,
        data: { ...this.form, visible: [] }
      })
        .then(({ data }) => {
          this.updating = false
          this.$emit('tableUpdated')
          this.$refs.form.reset()
          this.close()
        })
        .catch(err => {
          this.updating = false
          console.log(err)
        })
    },
    createUser: function () {
      console.log('POST', `/admin/p/user/pid/${this.form.display_name}/e/useracl`)
      /*      this.POST({
        route: `/admin/p/user/pid/${this.form.display_name}/e/useracl`,
        data: {
          ...this.form, id: this.form.display_name
        }
      })
        .then(({ data }) => {
          this.updating = false
          this.$emit('tableUpdated')
          this.$refs.form.reset()
          this.close()
        })
        .catch(err => {
          this.updating = false
          console.log(err)
          this.close()
        }) */
    }
  }
}
</script>

<style lang="scss">

</style>

<template>
  <div class="pa-5">
    <h3 class="mb-5">Video archive</h3>
    <v-carousel hide-delimiters cycle light class="carousel mb-8">
      <v-carousel-item>
        <div class="carousel">
          <CarouselVideoCard/>
        </div>
      </v-carousel-item>
      <v-carousel-item>
        <div class="carousel">
          <CarouselVideoCard/>
        </div>
      </v-carousel-item>
    </v-carousel>

    <h3 class="my-5">Subtitle</h3>
    <div class="d-flex">
      <VideoCard/>
      <VideoCard/>
      <VideoCard/>
      <VideoCard/>
    </div>
    <h3 class="my-5">Subtitle</h3>
    <div class="d-flex">
      <VideoCard/>
      <VideoCard/>
      <VideoCard/>
      <VideoCard/>
    </div>
  </div>
</template>

<script>

import VideoCard from './VideoCard'
import CarouselVideoCard from './CarouselVideoCard'

export default {
  name: 'VideoArchive',
  props: {},
  components: {
    CarouselVideoCard,
    VideoCard
  },
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style>
  .v-window__prev, .v-window__next {
    background: none;
  }

  .carousel {
    width: 83%;
    margin: 0 auto;
    max-height: 344px;
  }

</style>

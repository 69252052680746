<template>
  <div>
    <div v-if="isListLoading || (upcomingEventsList && upcomingEventsList.length)">
      <Table
        objectName="Upcoming Events"
        :data="upcomingEventsList"
        :tableHeader="tableHeaders"
        :rowClick="goToEvent"
        :loading="isListLoading"
      />
    </div>
    <NoItems v-if="noEvents">
      No events yet.
      <br/>
      Please wait until admin create one.
    </NoItems>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import jsonHeaders from '~src/components/common/table/TableHeaders'
import Table from '~src/components/common/table/Table'
import NoItems from '../noItems/noItems'

export default {
  name: 'UpcomingEventsTable',
  props: {
    role: {
      type: String,
      default: 'sponsor'
    }
  },
  components: {
    Table,
    NoItems
  },
  data: () => ({
    upcomingEventsList: [],
    jsonHeaders,
    noEvents: false,
    isListLoading: false
  }),
  created () {
    this.getUpcomingEvents()
  },
  computed: {
    tableHeaders () {
      return this.jsonHeaders.EventsUpcoming
    }
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    goToEvent (item, objectName) {
      this.$router.push(`/${this.role}/events/${item.id}`)
    },
    getUpcomingEvents () {
      this.isListLoading = true

      this.GET({ route: '/public/event/upcoming_events' })
        .then(({ data }) => {
          const eventsList = data.data
          if (eventsList && eventsList.length) {
            this.upcomingEventsList = data.data
          } else {
            this.noEvents = true
          }
        })
        .catch(err => {
          console.log(err)
        })
        .finally(() => {
          this.isListLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
</style>

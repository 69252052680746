<template>
  <div>
    <SideBar/>
    <v-main>
    <v-alert
      v-if="$store.getters.getRoleError"
      type="error"
      prominent
      text
    >
      Your current role doesn't allow you to view this page.
    </v-alert>
      <router-view />
    </v-main>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SideBar from '~src/features/sideBar'

export default {
  name: 'Home',
  props: {},
  components: {
    SideBar
  },
  data: () => ({}),
  computed: {
    ...mapGetters({
      username: 'getCognitoUsername',
      isSponsor: 'getSponsor'
    })
  },
  created () {
    if (this.isSponsor) this.getSponsorInfo()
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    ...mapMutations([
      'setSponsorInfo'
    ]),
    getSponsorInfo () {
      this.GET({ route: 'admin/whoami' })
        .then(({ data }) => { this.setSponsorInfo(data.data.sponsor[0]) })
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
</style>

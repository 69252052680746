<template>
  <div>
    <PageTitle title="Talks"/>
    <v-container class="pa-10 pr-15">
      <Preloader v-if="loading"/>
      <NoItems v-show="noTalks">
        You don’t create any talks yet.
        <br/>
        Let’s fix it!
        <br/>
        <v-dialog
          v-model="dialog"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mt-3 pa-6"
              v-bind="attrs"
              v-on="on"
            >
              Create new talk
            </v-btn>
          </template>
          <talk-modal
            :close="closeModal"
            @tableUpdated="getTalks"
          />
        </v-dialog>
      </NoItems>
      <Table
        v-if="talks"
        v-slot="{ editedItem, close }"
        objectName="talks"
        :data="talks"
        :tableHeader="tableHeader('TalksSpeaker')"
        :url="`/admin/e/talk`"
        :refresh="getTalks"
        :allowCreation=true
      >
        <talk-modal
          :editedItem="editedItem"
          :close="close"
          @tableUpdated="getTalks"
        />
      </Table>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageTitle from '../common/pageTitle/pageTitle'
import Preloader from '../common/preloader/Preloader'
import Table from '~src/components/common/table/Table'
import jsonHeaders from '~src/components/common/table/TableHeaders.json'
import NoItems from '../common/noItems/noItems'
import TalkModal from './talks/talk-create-edit-modal'

export default {
  name: 'Talks',
  components: {
    TalkModal,
    NoItems,
    PageTitle,
    Preloader,
    Table
  },
  props: {},

  data () {
    return {
      loading: false,
      dialog: false,
      talks: null,
      noTalks: false,
      talksFields: null,
      tableHeaders: jsonHeaders
    }
  },
  created () {
    this.getTalks()
  },

  methods: {
    ...mapActions([
      'GET'
    ]),
    tableHeader (title) {
      return jsonHeaders[title]
    },
    closeModal () {
      this.dialog = false
    },
    getTalks: function () {
      this.loading = true
      this.noTalks = false
      this.GET({ route: '/admin/talk/my' }) // user/{cognito_username}/talk
        .then(({ data }) => {
          console.log('talks', data.data)
          data.data ? this.talks = data.data : this.noTalks = true
          this.loading = false
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
}
</script>

<style lang="scss">

</style>

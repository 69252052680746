<template>
  <v-container class="schedule">
    <CardTemplate title="schedule" class="schedule__card">
      <v-btn
        color="primary"
        class="schedule__add"
        @click="addTalk"
      >
        add new talks
      </v-btn>
      <v-sheet height="580">
        <v-calendar
            ref="calendar"
            v-model="eventDate"
            color="primary"
            type="category"
            category-show-all
            :categories="categories"
            :events="events"
            :event-color="getEventColor"
            @change="fetchEvents"
          />
      </v-sheet>
    </CardTemplate>
  </v-container>
</template>

<script>
import CardTemplate from '~src/components/common/Cards/CardTemplate'

export default {
  name: 'Shedule',
  components: {
    CardTemplate
  },
  data: () => ({
    eventDate: '2020-12-04',
    events: [],
    colors: ['#5821F3', '#2196F3'],
    names: [
      'Create a security aware cultire in lorem ipsum text text text text text text text',
      'Create a security aware cultire in lorem ipsum text text text text text text text',
      'Create a security aware cultire in lorem ipsum text text text text text text text',
      'Create a security aware cultire in lorem ipsum text text text text text text text',
      'Create a security aware cultire in lorem ipsum text text text text text text text',
      'Create a security aware cultire in lorem ipsum text text text text text text text',
      'Create a security aware cultire in lorem ipsum text text text text text text text'
    ],
    categories: ['Livestream', 'Break it!', 'Build it!', 'Show it!']
  }),
  mounted () {
    this.$refs.calendar.checkChange()
  },
  computed: {
    namesFiltered () {
      return this.names.map(name => name.split('').length <= 35
        ? name
        : `${name.split('').splice(0, 35).join('')}...`)
    }
  },
  methods: {
    addTalk () {
      console.log('add talk')
    },
    getEventColor (event) {
      return event.color
    },
    fetchEvents ({ start, end }) {
      // all events are hardcoded, time is also hardcoded
      // maybe events will come from be with all info needed
      const events = []

      const min = new Date(`${start.date}T00:00:00`)
      const max = new Date(`${end.date}T23:59:59`)
      const days = (max.getTime() - min.getTime()) / 86400000
      const eventCount = this.rnd(days, days + 20)

      for (let i = 0; i < eventCount; i++) {
        const allDay = this.rnd(0, 3) === 0
        const firstTimestamp = this.rnd(min.getTime(), max.getTime())
        const first = new Date(firstTimestamp - (firstTimestamp % 900000))
        const secondTimestamp = this.rnd(2, allDay ? 288 : 8) * 900000
        const second = new Date(first.getTime() + secondTimestamp)
        const category = this.categories[this.rnd(0, this.categories.length - 1)]

        events.push({
          name: this.namesFiltered[this.rnd(0, this.namesFiltered.length - 1)],
          start: first,
          end: second,
          color: this.colors[category === 'Livestream' ? 0 : 1],
          timed: !allDay,
          category
        })
      }

      this.events = events
    },
    rnd (a, b) {
      // for hardcoding time and events
      return Math.floor((b - a + 1) * Math.random()) + a
    }
  }
}
</script>

<style lang="scss">
// @import 'path/_variables';
.schedule {
  &__card {
    position: relative;
  }

  &__add {
    position: absolute;
    z-index: 3;
    top: 8px;
    right: 12px;
  }
}
</style>

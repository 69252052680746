export const isEmpty = value => !(value && (value.length || Object.keys(value).length))

export const makeFormFields = (fields) => {
  const obj = {}
  fields.map(i => {
    if (i.type === 'bool') {
      obj[i.f_name] = false
    } else {
      obj[i.f_name] = ''
    }
  })
  return obj
}

export const makeFieldName = (str) => {
  if (!str) return str
  return str[0].toUpperCase() + str.slice(1).replace('_', ' ')
}

export const shortenText = (text, symbolsQty) =>
  text.length <= symbolsQty
    ? text
    : `${text.split('').splice(0, symbolsQty).join('')}...`

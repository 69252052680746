<template>
  <v-card class="card-text">
    <router-link :to="link" class="card-text__link">
      {{linkTitle}}
    </router-link>
    <p class="card-text__title">
      {{ title }}
    </p>
    <p class="card-text__text">
      {{ textFiltered }}
    </p>
  </v-card>
</template>

<script>
export default {
  name: 'CardText',
  props: ['linkTitle', 'link', 'title', 'text'],
  components: {},
  data: () => ({}),
  computed: {
    textFiltered () {
      return this.text.length <= 250
        ? this.text
        : `${this.text.split('').splice(0, 250).join('')}...`
    }
  },
  methods: {}
}
</script>

<style lang="scss">
// @import 'path/_variables';
.card-text {
  height: 206px;
  padding: 16px;
  padding-bottom: 32px;
  position: relative;
  overflow: hidden;

  &__link {
    position: absolute;
    top: 16px;
    right: 20px;
    margin: 0;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    color: rgba(0, 0, 0, 0.6) !important;
    text-decoration: none;
  }

  &__title {
    width: calc(100% - 80px);
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
    margin-bottom: 16px !important;
  }

  &__text {
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 0 !important;
    font-weight: 300;
  }
}
</style>

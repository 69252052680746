<template>
  <v-card class="bar-chart-card pa-5  d-flex align-content-center justify-space-between">
    <div class="bar-chart-card__body d-flex flex-column justify-space-between ">
      <h3 v-show="title" class="bar-chart-card__title">
        {{ title }}
      </h3>
      <div v-show="description" class="bar-chart-card__description">
        <p
          v-for="(label, index) in description"
          :key="`bar-chart-card-${index}`"
          class="mb-0 bar-chart-card__item"
        >
          <span
            class="bar-chart-card__marker mr-3"
            :style="`background-color: ${chartBackgrounds[index]}`"
          />
          {{ label }}
        </p>
      </div>
      <div>
        <div v-show="extension" class="bar-chart-card__box">
          {{ extension }}
        </div>
      </div>
    </div>
    <div class="">
      <BarChart
        :width="280"
        :height="260"
        :chartdata="{
          labels: description,
          datasets: [
            {
              backgroundColor: chartBackgrounds,
              data,
            }
          ]
        }"
      />
    </div>
  </v-card>
</template>

<script>
import BarChart from '~src/components/common/charts/BarChart'

export default {
  name: 'CardBarChart',
  props: [
    'title',
    'extension',
    'data',
    'description',
    'chartBackgrounds'
  ],
  components: { BarChart },
  data () {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .bar-chart-card {
    height: 295px;
    min-width: 450px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &__body {
      height: 100%;
    }

    &__title {
      color: rgba(0, 0, 0, 0.87);
    }

    &__description {
      font-size: 16px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: rgba(0, 0, 0, 0.6);
    }

    &__item {
      display: flex;
      align-items: center;
    }

    &__box {
      padding: 9px 12px;
      background: rgba(100, 181, 246, 0.3);
      border-radius: 3px;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.05em;
      color: rgba(0, 0, 0, 0.87);
    }

    &__marker {
      width: 8px;
      height: 8px;
      border-radius: 100%;
    }
  }
</style>

<template>
  <v-dialog
    v-model="isShownLocal"
    persistent
    width="450"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        text
        v-bind="attrs"
        v-on="on"
      >
        apply
      </v-btn>
    </template>
    <v-card class="pa-6 pb-3 apply-popup">
      <v-form
        ref="form"
        @submit.prevent="sendInvoice"
      >
        <v-alert
          :value="isSuccess || isError"
          text
          :type="isSuccess ? 'success' : 'error'"
        >
          {{ notification }}
        </v-alert>
        <h2 class="apply-popup__heading mb-6">
          Become a sponsor
        </h2>
        <v-card-text class="pa-0 mb-4">
          You need to pay for participation in the conference as a sponsor.
          Total pay for {{ event.name }} as
          <v-select
            v-model="form.level"
            :items="sponsorhip"
            label="Sponsor level"
            outlined
            class="mt-4 mb-2"
          />
          sponsor is: ${{ sum }}.
          Input email for get invoice:
        </v-card-text>
        <div v-if="noEmailFromAcc" class="mb-3 v-messages theme--light error--text">
          Sorry, we have no info about your email, you can fill it in your profile
        </div>
        <v-text-field
          v-model="form.email"
          label="Email"
          outlined
          class="pa-0 ma-0"
          :rules="[rules.required, rules.email]"
          @click="clearAccountEmail"
        />
        <v-checkbox
          v-model="useEmailFromAcc"
          label="Use email from profile"
          class="pa-0 ma-0"
          @change="addEmailFromAcc"
        />
        <v-card-actions class="apply-popup__buttons">
          <v-btn
            color="primary"
            text
            @click="isShownLocal = false"
          >
            cancel
          </v-btn>
          <v-btn
            type="submit"
            color="primary"
            text
            :loading="loading"
          >
            send invoice
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import rules from '~src/assets/js/validationRules'
import config from '~public/config/config.json'

export default {
  name: 'ApplyForm',
  props: {
    isApplyPopupShown: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      default: () => ({})
    }
  },
  components: {},
  data: () => ({
    form: {
      email: '',
      level: 'silver',
      event: ''
    },
    useEmailFromAcc: false,
    noEmailFromAcc: false,
    isShownLocal: false,
    sponsorTypes: {
      silver: 300,
      gold: 1300
    },
    isSuccess: false,
    isError: false,
    notification: '',
    notificationTimeout: null,
    loading: false,
    config,
    rules
  }),
  computed: {
    ...mapGetters({
      userInfo: 'getUserInfo'
    }),
    sponsorhip () {
      return Object.keys(this.sponsorTypes)
    },
    sum () {
      return this.sponsorTypes[this.form.level]
    }
  },
  created () {
    this.isShownLocal = this.isApplyPopupShown
    this.form.event = this.event.id
  },
  beforeDestroy () {
    clearTimeout(this.notificationTimeout)
  },
  watch: {
    isSuccess (value) {
      this.initNotificationToimeout(value)
    },
    isError (value) {
      this.initNotificationToimeout(value)
    },
    useEmailFromAcc (value) {
      if (!value) this.noEmailFromAcc = false
    }
  },
  methods: {
    ...mapActions([
      'GET',
      'POST'
    ]),
    sendInvoice () {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.POST({
          route: 'admin/e/sponsoremailcollector',
          data: this.form
        }).then(() => {
          this.isSuccess = true
          this.notification = 'Your email saved successfully. Please wait for your invoice.'
        }).catch(() => {
          this.isError = true
          this.notification = 'An error occurred. Please try again later or contact our support at ' + this.config.helpEmail
        }).finally(() => {
          this.loading = false
        })
      }
    },
    clearNotifications () {
      this.isSuccess = false
      this.isError = false
      this.notification = ''
    },
    initNotificationToimeout (value) {
      if (value) {
        this.notificationTimeout = setTimeout(() => {
          this.isShownLocal = false
          this.clearNotifications()
        }, 2000)
      }
    },
    clearAccountEmail () {
      this.noEmailFromAcc = false
      this.useEmailFromAcc = false
    },
    addEmailFromAcc () {
      if (this.userInfo.email) {
        this.form.email = this.userInfo.email
      } else {
        this.noEmailFromAcc = true
      }
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
  .v-select .v-input__control .v-text-field__details {
    display: none !important;
  }
</style>

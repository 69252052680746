<template>
  <v-row align="center" justify="center" class="login">
    <v-alert
      v-if="error || success"
      :type="error? 'error':'success'"
      outlined
      text
      dismissible
      class="reset__alert"
    >
      {{error || 'Code was successfully sent. Please check your email!'}}
    </v-alert>

    <v-col lg="3" md="4" sm="6" xs="12" class="login__wrapper">
      <h2 class="reset__heading">
        Set new password
      </h2>
      <v-form
        ref="form"
        v-model="valid"
        @submit.prevent="submit"
      >
<!--        <v-text-field-->
<!--          v-model="form.username"-->
<!--          label="Username or email"-->
<!--          outlined-->
<!--          :rules="rules.required"-->
<!--          @mousedown="resetAlerts"-->
<!--        />-->
<!--        <v-text-field-->
<!--          v-model="form.code"-->
<!--          label="Code"-->
<!--          outlined-->
<!--          :rules="rules.required"-->
<!--          @mousedown="resetAlerts"-->
<!--        />-->
        <v-text-field
          v-model="form.password"
          label="New password"
          outlined
          :append-icon="showPassw ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.password]"
          :type="showPassw ? 'text' : 'password'"
          @click:append="showPassw = !showPassw"
        ></v-text-field>
        <v-btn
          color="primary"
          :loading="updating"
          :disabled="!valid"
          type="submit"
          x-large
          block
        >
          Submit
        </v-btn>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import rules from '~src/assets/js/validationRules'
import { mapActions } from 'vuex'

export default {
  name: 'ResetPassword',
  props: {},
  components: {},
  data: () => ({
    updating: false,
    showPassw: false,
    form: {
      code: '',
      username: '',
      password: ''
    },
    valid: false,
    error: null,
    success: null,
    rules
  }),
  // TODO parse username and code
  // computed: {
  //   userName () {
  //     return this.$router.currentRoute.params.id
  //   }
  // },
  methods: {
    ...mapActions([
      'POST'
    ]),
    resetAlerts () {
      this.error = null
      this.success = null
    },
    submit () {
      this.updating = true
      console.log(this.form)

      this.POST({
        route: 'admin/confirm_forgotten_password',
        data: this.form
      })
        .then((resp) => {
          console.log('success resp', resp)
          this.success = true
        })
        .catch(err => {
          console.log(err)
          this.error = err
        })
        .finally(() => {
          this.updating = false
        })
    }
  }
}
</script>

<style lang="scss">
</style>

<template>
  <div>
    <PageTitle :title="eventInfo.name">
      <v-dialog
        v-model="applyDialog"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            text
            color="primary"
            v-bind="attrs"
            v-on="on"
          >
            Apply
          </v-btn>
        </template>
        <ApplyModal
          :close="closeModal"
          :event="eventId"
          @apply="getEventInfo"
          style="width:780px"/>
      </v-dialog>
    </PageTitle>

    <v-container class="pl-10 pr-15">
      <Preloader v-if="isEventLoading" class="mt-16"/>
      <template v-else>
        <EventCard :data="eventInfo"/>
<!--        TODO  block with my talks-->
      </template>
    </v-container>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import PageTitle from '~src/components/common/pageTitle/pageTitle'
import Preloader from '~src/components/common/preloader/Preloader'
import EventCard from '~src/components/common/Cards/EventCard'
import ApplyModal from './call-for-papers-modal'

export default {
  name: 'SpeakerEventPage',
  props: {},
  components: {
    PageTitle,
    Preloader,
    EventCard,
    ApplyModal
  },
  data: () => ({
    applyDialog: false,
    isEventLoading: false,
    eventInfo: {}
  }),
  computed: {
    eventId () {
      return this.$route.params.id
    }
  },
  created () {
    Promise.all([
      this.getEventInfo()
    ])
  },
  methods: {
    ...mapActions([
      'GET'
    ]),
    closeModal () {
      this.applyDialog = false
    },
    getEventInfo () {
      this.isEventLoading = true

      this.GET({ route: `admin/e/event/eid/${this.eventId}` })
        .then(({ data }) => {
          this.eventInfo = data.data
          console.log(this.eventInfo)
        })
        .finally(() => {
          this.isEventLoading = false
        })
    }
  }
}
</script>

<style lang="scss">
  // @import 'path/_variables';
</style>

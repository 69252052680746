<template>
  <v-container>
    <h3 class="my-5">Video</h3>
    <v-row>
      <v-col cols="9">
        <div style="position: relative">
          <v-img
            src="https://picsum.photos/840/480?random"
            aspect-ratio="1"
            class="grey lighten-2 video__img"
            alt="Image"
          >
          </v-img>
          <div class="video__duration">00:00:00</div>
        </div>
        <div class="video__title mt-3 mb-1">Hacking for fun and profit</div>
        <div class="video__subtitle mb-3">
          <span class="mr-5"><v-icon small class="mr-1">mdi-eye-outline</v-icon>300</span>
          <span>05/05/2021</span>
        </div>
        <div class="d-flex align-center mb-4">

          <v-avatar
            class="mr-3"
            color="grey"
          >
            <!--      <img-->
            <!--        v-if="userInfo.image"-->
            <!--        :src="avatarSrc"-->
            <!--        :alt="userName">-->
            <!--      <span v-else-if="userInfo.first_name" class="white&#45;&#45;text headline">{{userInfo.first_name.charAt(0)}}{{userInfo.last_name.charAt(0)}}</span>-->
            <!--      <v-icon v-else dark>-->
            <v-icon dark>
              mdi-account-circle
            </v-icon>
          </v-avatar>

          <div class="video__user">
            <div class="">
              Addeline Michell
            </div>
            <div class="">
              Devops Engineer Threat Informant
            </div>
          </div>

        </div>
        <div class="video__description pb-3">
          Lacus sed viverra tellus in hac habitasse. Faucibus pulvinar elementum integer enim. Lorem mollis aliquam ut
          porttitor leo a diam. Duis ultricies lacus sed turpis. Turpis in eu mi bibendum neque. Nibh tortor id aliquet
          lectus proin nibh nisl. Felis eget velit aliquet sagittis id consectetur purus. Nibh nisl condimentum id
          venenatis a. Turpis egestas integer eget aliquet nibh praesent tristique magna. Nec ultrices dui sapien eget
          mi
          proin sed. Aliquam vestibulum morbi blandit cursus.
        </div>
        <div class="my-2">

          <v-chip
            label
            small
            color="#e9f5fd"
            class="mr-2 mb-1"
          >
            one_tag
          </v-chip>
          <v-chip
            label
            small
            color="#e9f5fd"
            class="mr-2 mb-1"
          >
            one_tag
          </v-chip>
          <v-chip
            label
            small
            color="#e9f5fd"
            class="mr-2 mb-1"
          >
            one_tag
          </v-chip>

        </div>

        <CommentBlock/>
      </v-col>

      <v-col cols="3">
        <h3 class="mb-5">You might be interested </h3>
        <VideoCard/>
        <VideoCard/>
        <VideoCard/>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import VideoCard from './VideoCard'
import CommentBlock from '../comments/CommentBlock'

export default {
  name: 'Video',
  props: {},
  components: {
    CommentBlock,
    VideoCard
  },
  data: () => ({}),
  computed: {},
  methods: {}
}
</script>

<style scoped lang="scss">
  .video {
    &__img {
      height: 480px;
    }

    &__duration {
      position: absolute;
      right: 5%;
      bottom: 7%;
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.25px;
      color: #FFFFFF;
      padding: 4px 8px;
      background: rgba(0, 0, 0, 0.5);
    }

    &__title {
      font-size: 36px;
      line-height: 42px;
      letter-spacing: 0.25px
    }

    &__subtitle {
      font-size: 12px;
      line-height: 14px;
      letter-spacing: 0.25px;
    }

    &__user {
      font-size: 14px;
      line-height: 16px;
      color: #4A5560;
    }

    &__description {
      width: 80%;
    }
  }

</style>

<template>
  <div>
    <v-row>
      <v-col sm="12" md="8">
        <div class="image-wrapper" v-if="spots.length">
          <img :src="backgroundImg" alt="background" class="hotspot__img">
          <div class="control-panel v-sheet v-card">
            <v-btn text @click="addSpot">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>

          <vue-resizable
            v-for="(spot, counter) in spots" v-bind:key="spot.id"
            class="resizable"
            ref="resizableComponent"
            :fit-parent="true"
            dragSelector="#spot"
            :max-width="maxW | checkEmpty"
            :max-height="maxH | checkEmpty"
            :min-width="minW | checkEmpty"
            :min-height="minH | checkEmpty"
            :width="spot.styles.width"
            :height="spot.styles.height"
            :left="spot.styles.left | checkNegative"
            :top="spot.styles.top | checkNegative"
            @mount="(e)=>mountHandler(e,counter)"
            @resize:move="(e)=>eHandler(e,counter)"
            @resize:start="(e)=>eHandler(e,counter)"
            @resize:end="(e)=>eHandler(e,counter)"
            @drag:move="(e)=>eHandler(e,counter)"
            @drag:start="(e)=>eHandler(e,counter)"
            @drag:end="(e)=>eHandler(e,counter)"
          >
<!--           :right="spot.styles.right | checkNegative" -->
<!--            :bottom="spot.styles.bottom | checkNegative"-->
            <div id="spot" class="hotspot__item">
              <v-icon color="#ED1C24" class="float-right" @click="deleteSpot(counter)">
                mdi-close
              </v-icon>
              <button class="hotspot__item-btn">
                <v-icon color="white">mdi-play</v-icon>
              </button>
            </div>
          </vue-resizable>
        </div>

      </v-col>
      <v-col sm="12" md="4">
        <div v-for="(spot) in spots" v-bind:key="spot.id">
          <div class="hotspot__info v-sheet v-card mb-2">
            <label>width:
              <input type="number" v-model.number="spot.styles.width">
            </label>
            <label>height:
              <input type="number" v-model.number="spot.styles.height">
            </label>
            <label>left:
              <input type="number" v-model.number="spot.styles.left">
            </label>
            <label>top:
              <input type="number" v-model.number="spot.styles.top">
            </label>
<!--            <label>right:-->
<!--              <input type="number" v-model.number="spot.styles.right">-->
<!--            </label>-->
<!--            <label>bottom:-->
<!--              <input type="number" v-model.number="spot.styles.bottom">-->
<!--            </label>-->
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import VueResizable from 'vue-resizable'

export default {
  name: 'DraggableHotspot',
  props: ['image', 'items'],
  components: { VueResizable },
  data () {
    return {
      backgroundImg: this.image,
      maxW: 350,
      maxH: 300,
      minW: 100,
      minH: 70,
      spots: [],
      widthAndHeightValues: []
    }
  },
  created () {
    if (this.items && this.items.length) {
      // this.spots = this.items
      this.spots = this.items.map((i) => {
        if (typeof i.styles.width === 'number') return i
        return {
          ...i,
          styles: {
            ...i.styles,
            width: Math.round(i.styles.width.slice(0, -2) / 2),
            height: Math.round(i.styles.height.slice(0, -2) / 2),
            left: Math.round(i.styles.left.slice(0, -2) / 2),
            top: Math.round(i.styles.top.slice(0, -2) / 2)
          }
        }
      })
    } else {
      this.addSpot()
    }
  },
  watch: {
    items () {
      this.spots = this.items.map((i) => {
        if (typeof i.styles.width === 'number') return i
        return {
          ...i,
          styles: {
            ...i.styles,
            width: Math.round(i.styles.width.slice(0, -2)),
            height: Math.round(i.styles.height.slice(0, -2)),
            left: Math.round(i.styles.left.slice(0, -2)),
            top: Math.round(i.styles.top.slice(0, -2))
          }
        }
      })
    }
  },
  methods: {
    mountHandler (data, counter) {
      this.spots[counter].styles.width = data.width * 2
      this.spots[counter].styles.height = data.height * 2
      this.spots[counter].styles.left = Math.round(data.left) * 2
      this.spots[counter].styles.top = Math.round(data.top) * 2
    },
    eHandler (data, counter) {
      this.spots[counter].styles.width = data.width
      this.spots[counter].styles.height = data.height
      this.spots[counter].styles.left = Math.round(data.left)
      this.spots[counter].styles.top = Math.round(data.top)
      // this.spots[counter].styles.bottom = data.bottom
      // this.spots[counter].styles.right = data.right

      const spotsWithPixels = this.spots.map((i) => {
        if (typeof i.styles.width === 'string') return i
        return {
          ...i,
          styles: {
            ...i.styles,
            width: i.styles.width + 'px',
            height: i.styles.height + 'px',
            left: i.styles.left + 'px',
            top: i.styles.top + 'px'
          }
        }
      })
      this.$emit('change', spotsWithPixels)
    },
    addSpot () {
      if (this.spots.length < 6) {
        const generatedId = Date.now() + ((Math.random() * 100000).toFixed())
        this.widthAndHeightValues.push({
          id: generatedId,
          tH: 120,
          tW: 150
        })
        const tW = this.widthAndHeightValues.find(el => el.id === generatedId).tW
        const tH = this.widthAndHeightValues.find(el => el.id === generatedId).tH
        this.spots.push({
          id: generatedId,
          styles: {
            position: 'absolute',
            width: tW,
            height: tH,
            left: `calc( 50% - ${tW / 2}px)`,
            top: `calc( 50% - ${tH / 2}px)`
          }
        })
      }
    },
    deleteSpot (counter) {
      this.spots.splice(counter, 1)
    }
  },
  filters: {
    checkEmpty (value) {
      return typeof value !== 'number' ? 0 : value
    },
    checkNegative (value) {
      return typeof value !== 'number' ? 0 : value < 0 ? 0 : value
    }
  }
}
</script>

<style scoped lang="scss">

  .image-wrapper {
    position: relative;
    width: 100%;
  }

  .resizable {
    position: absolute;
  }

  .control-panel {
    background: #FFFFFF;
    position: absolute;
    top: 2%;
    right: 2%;
    display: flex;
  }

  .hotspot {

    &__img {
      width: 100%;
    }

    &__item {
      position: relative;
      height: 100%;
      width: 100%;
      cursor: pointer;
      border: 1px solid #ED1C24;
      background: rgba(9, 11, 12, 0.5);
    }

    &__item-btn {
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      border: 1px solid #FFFFFF;
      border-radius: 50%;
      position: absolute;
      top: 38%;
      left: 37%;
    }

    &__info {
      padding: 16px;
      display: flex;
      flex-wrap: wrap;

      label {
        width: 50%;
      }

      input {
        width: 50%;
      }
    }
  }

</style>

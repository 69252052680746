<template>
  <div class="social__wrapper">
    <div class="d-flex justify-space-between mb-4">
      <span class="main-grey-header">Social media</span>
      <v-btn text @click="addSocial">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <!--    <div class="nested__item"-->
    <!--         v-for="(item, counter) in socialForm"-->
    <!--         v-bind:key="item+counter">-->
    <!--      {{item}}-->

    <!--      <v-icon>{{getIcon(item.provider)}}</v-icon>-->
    <!--      <v-text-field v-model="item.provider" class="d-none"/>-->
    <!--      <v-text-field-->
    <!--        v-model="item.id"-->
    <!--        v-on:input="parseSocialLink(item, counter)"-->
    <!--        placeholder="Enter your link"-->
    <!--        :id="'edit_id_' + counter"-->
    <!--      >-->
    <!--      </v-text-field>-->

    <!--      <a :href="makeSocialLink(item)" target="_blank">-->
    <!--        <v-icon class="mr-2">mdi-open-in-new</v-icon>-->
    <!--      </a>-->
    <!--      <v-btn text @click="deleteSocial(counter)" color="grey">-->
    <!--        <v-icon>-->
    <!--          mdi-delete-outline-->
    <!--        </v-icon>-->
    <!--      </v-btn>-->
    <!--    </div>-->

    <div v-for="(item, counter) in socialForm" v-bind:key="counter">

      <!--          <v-text-field v-model="item.provider" class="d-none"/>-->
      <!--        :id="'edit_id_' + counter"-->
      <v-text-field
        outlined
        placeholder="Enter your link"
        :prepend-inner-icon=getIcon(item.provider)
        :append-outer-icon="'mdi-delete-outline'"
        v-model="item.provider_id"
        v-on:input="parseSocialLink(item, counter)"
        @click:append-outer="deleteSocial(counter)"
      >
        <template v-slot:append>
          <a :href="makeSocialLink(item)" target="_blank" rel="noopener noreferrer" class="open-link-icon">
            <v-icon class="mx-2">mdi-open-in-new</v-icon>
          </a>
        </template>
      </v-text-field>

    </div>

  </div>

</template>

<script>
import getSocialIcon from '~src/assets/js/getSocialIcon'

export default {
  name: 'social-media',
  props: {
    value: { type: Array }
  },
  data () {
    return {
      socialForm: [{
        provider_id: '',
        provider: '' // options: ["linkedin",'linkedin_company', "twitter", "instagram", "reddit","github", "facebook", "stackoverflow" ],
      }]
    }
  },
  created () {
    if (this.value && this.value.length) {
      this.socialForm = this.value
    }
  },
  watch: {
    socialForm: {
      handler (socialForm) {
        this.$emit('social', socialForm)
      },
      deep: true
    },
    value: {
      handler (val) {
        if (val && val.length) this.socialForm = val
      }
    }
  },
  beforeDestroy () {
    this.socialForm = this.value
  },
  methods: {
    addSocial () {
      this.socialForm.length < 10 && this.socialForm.push({ provider_id: '', provider: '' })
    },
    deleteSocial (counter) {
      this.socialForm.splice(counter, 1)
    },
    parseSocialLink: function (item, counter) {
      const linkObj = this.socialForm[counter]
      const id = linkObj.provider_id
      linkObj.provider_id = id.split('com/')[1]
      if (id.includes('htt')) {
        linkObj.provider = id.split('//')[1].split('.com')[0]
      } else {
        linkObj.provider = id.split('.com')[0]
      }
      if (id.includes('www')) {
        linkObj.provider_id = id
      }
      if (id.includes('linkedin')) {
        if (id.includes('company')) {
          linkObj.provider_id = id.split('company/')[1].split('/')[0]
          linkObj.provider = 'linkedin_company'
        } else {
          linkObj.provider_id = id.split('in/')[1].split('/')[0]
          linkObj.provider = 'linkedin'
        }
      } else if (id.includes('instagram')) {
        linkObj.provider_id = id.split('instagram.com/')[1].split('/')[0]
        linkObj.provider = 'instagram'
      } else if (id.includes('twitter')) {
        linkObj.provider_id = id.split('twitter.com/')[1].split('/')[0]
        linkObj.provider = 'twitter'
      } else if (id.includes('spotify')) {
        linkObj.provider_id = id.split('spotify.com/')[1]
        linkObj.provider = 'spotify'
      } else if (id.includes('facebook')) {
        linkObj.provider_id = id.split('facebook.com/')[1].split('/')[0]
        linkObj.provider = 'facebook'
      } else if (id.includes('github')) {
        linkObj.provider_id = id.split('github.com/')[1].split('/')[0]
        linkObj.provider = 'github'
      } else if (id.includes('reddit')) {
        linkObj.provider_id = id.split('reddit.com/r/')[1].split('/')[0]
        linkObj.provider = 'reddit'
      } else if (id.includes('stackoverflow')) {
        linkObj.provider_id = id.split('stackoverflow.com/')[1]
        linkObj.provider = 'stackoverflow'
      }
    },
    makeSocialLink: function (social) {
      let link = ''
      if (social.provider.includes('linkedin')) {
        if (social.provider === 'linkedin_company') {
          link = 'https://linkedin.com/company/' + social.provider_id
        }
        if (social.provider === 'linkedin') {
          link = 'https://linkedin.com/in/' + social.provider_id
        }
      } else if (social.provider === 'reddit') {
        link = 'https://reddit.com/r/' + social.provider_id
      } else if (social.provider === 'spotify') {
        link = 'https://open.spotify.com/' + social.provider_id
      } else if (social.provider.includes('www') || social.provider.includes('web')) {
        link = social.provider_id
      } else {
        link = 'https://' + social.provider + '.com/' + social.provider_id
      }
      return link
    },
    getIcon: function (site) {
      return getSocialIcon(site)
    }
  }
}
</script>

<style scoped lang="scss">

  .social {
    &__wrapper {
      min-width: 400px;
    }
  }

  .open-link-icon {
    text-decoration: none;
  }

</style>
